import React from "react";
import { IconsType, ILeftIcons } from "./types";

export const LeftIcons: React.FC<ILeftIcons> = ({ type }) => {
  const getIcon = (type: IconsType) => {
    switch (type) {
      case "skip": 
        return (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 8H12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 4L12 8L8 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )
      case "startTour":
        return (
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.94739 2.71394C1.94739 2.15595 2.39973 1.70361 2.95771 1.70361H15.0403C15.5983 1.70361 16.0506 2.15595 16.0506 2.71394V10.2656C16.0506 10.8235 15.5983 11.2759 15.0403 11.2759H2.95771C2.39973 11.2759 1.94739 10.8235 1.94739 10.2656V2.71394ZM2.95771 0.703613C1.84744 0.703613 0.947388 1.60367 0.947388 2.71394V10.2656C0.947388 11.3758 1.84744 12.2759 2.95771 12.2759H8.49975V14.2964H5.97845C5.70231 14.2964 5.47845 14.5202 5.47845 14.7964C5.47845 15.0725 5.70231 15.2964 5.97845 15.2964H8.98441L8.99975 15.2966L9.01509 15.2964H12.0198C12.2959 15.2964 12.5198 15.0725 12.5198 14.7964C12.5198 14.5202 12.2959 14.2964 12.0198 14.2964H9.49975V12.2759H15.0403C16.1506 12.2759 17.0506 11.3758 17.0506 10.2656V2.71394C17.0506 1.60367 16.1506 0.703613 15.0403 0.703613H2.95771ZM8.14271 4.1538C7.98928 4.05151 7.79201 4.04198 7.62943 4.12899C7.46685 4.216 7.36536 4.38543 7.36536 4.56982V8.59736C7.36536 8.78175 7.46685 8.95118 7.62943 9.03819C7.79201 9.1252 7.98928 9.11567 8.14271 9.01338L11.1634 6.99962C11.3025 6.90688 11.386 6.75077 11.386 6.58359C11.386 6.41641 11.3025 6.2603 11.1634 6.16756L8.14271 4.1538ZM9.98462 6.58359L8.36536 7.6631V5.50408L9.98462 6.58359Z"
              fill="#1A1A1A"
            />
          </svg>
        );
      case "next":
        return (
          <svg
            width="6"
            height="11"
            viewBox="0 0 6 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.79526 6.41466L1.8456 10.774C1.5726 11.0753 1.13117 11.0753 0.861084 10.774L0.204743 10.0496C-0.0682479 9.74829 -0.0682479 9.26107 0.204743 8.96296L3.00436 5.87295L0.204743 2.78294C-0.0682482 2.48163 -0.0682482 1.99441 0.204743 1.6963L0.861083 0.971884C1.13407 0.670575 1.57551 0.670575 1.8456 0.971884L5.79526 5.33124C6.06825 5.62613 6.06825 6.11336 5.79526 6.41466Z"
              fill="white"
            />
          </svg>
        );
      case "exit":
        return (
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.09822 8.68784L5.65229 9.13378C5.46347 9.32259 5.15815 9.32259 4.97134 9.13378L1.06642 5.23086C0.8776 5.04205 0.8776 4.73672 1.06642 4.54991L4.96933 0.647001C5.15815 0.458183 5.46347 0.458183 5.65028 0.647001L6.09621 1.09293C6.28704 1.28376 6.28302 1.59511 6.08818 1.78192L3.6697 4.0859L9.44272 4.0859C9.70987 4.0859 9.9248 4.30083 9.9248 4.56799L9.9248 5.21078C9.9248 5.47793 9.70987 5.69287 9.44272 5.69287L3.6697 5.69287L6.09019 7.99886C6.28704 8.18567 6.29106 8.49702 6.09822 8.68784Z"
              fill="white"
            />
          </svg>
        );
      case "brightCheck":
        return (
          <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 1L6 12L1 7"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "PlayButtonCircleLight":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99951 12.0005C4.99951 8.13421 8.13372 5 12 5C15.8662 5 19.0004 8.13421 19.0004 12.0005C19.0004 15.8667 15.8662 19.0009 12 19.0009C8.13372 19.0009 4.99951 15.8667 4.99951 12.0005ZM12 4C7.58144 4 3.99951 7.58193 3.99951 12.0005C3.99951 16.419 7.58144 20.0009 12 20.0009C16.4185 20.0009 20.0004 16.419 20.0004 12.0005C20.0004 7.58193 16.4185 4 12 4ZM10.7772 8.58428C10.6238 8.48199 10.4265 8.47245 10.2639 8.55946C10.1014 8.64647 9.99988 8.8159 9.99988 9.0003V15.0007C9.99988 15.1851 10.1014 15.3545 10.2639 15.4415C10.4265 15.5285 10.6238 15.519 10.7772 15.4167L15.2775 12.4165C15.4166 12.3238 15.5002 12.1677 15.5002 12.0005C15.5002 11.8333 15.4166 11.6772 15.2775 11.5845L10.7772 8.58428ZM14.0988 12.0005L10.9999 14.0664V9.93456L14.0988 12.0005Z"
              fill="white"
            />
          </svg>
        );
      case "check":
        return (
          <>
            <svg
              width="11"
              height="8"
              viewBox="0 0 11 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.6437 7.84288L0.157101 4.35628C-0.0523671 4.14681 -0.0523671 3.80718 0.157101 3.59769L0.915668 2.8391C1.12514 2.62961 1.46479 2.62961 1.67426 2.8391L4.023 5.18782L9.05374 0.157101C9.26321 -0.0523671 9.60286 -0.0523671 9.81233 0.157101L10.5709 0.915689C10.7804 1.12516 10.7804 1.46479 10.5709 1.67428L4.40229 7.8429C4.1928 8.05237 3.85317 8.05237 3.6437 7.84288Z"
                fill="#1A1A1A"
              />
            </svg>
            &nbsp;&nbsp;
          </>
        );
      case "brightClose":
        return (
          <>
            <svg
              // @ts-ignore
              style={{ order: "1" }}
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8538 0.853309C12.049 0.658047 12.049 0.341465 11.8538 0.146202C11.6585 -0.0490597 11.3419 -0.0490597 11.1467 0.146202L5.99996 5.29291L0.853248 0.146202C0.657986 -0.0490597 0.341404 -0.0490597 0.146141 0.146202C-0.0491207 0.341465 -0.0491207 0.658047 0.146141 0.853309L5.29285 6.00002L0.146141 11.1467C-0.0491207 11.342 -0.0491207 11.6586 0.146141 11.8538C0.341404 12.0491 0.657986 12.0491 0.853248 11.8538L5.99996 6.70713L11.1467 11.8538C11.3419 12.0491 11.6585 12.0491 11.8538 11.8538C12.049 11.6586 12.049 11.342 11.8538 11.1467L6.70707 6.00002L11.8538 0.853309Z"
                fill="white"
              />
            </svg>
            &nbsp;
          </>
        );
      case "view":
        return (
          <>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9275 3.69584C10.7977 1.49146 8.56105 0 6 0C3.43895 0 1.20166 1.4925 0.0724887 3.69605C0.0248307 3.79032 0 3.89448 0 4.00011C0 4.10575 0.0248307 4.2099 0.0724887 4.30417C1.20228 6.50855 3.43895 8.00002 6 8.00002C8.56105 8.00002 10.7983 6.50751 11.9275 4.30397C11.9752 4.20969 12 4.10554 12 3.9999C12 3.89427 11.9752 3.79011 11.9275 3.69584ZM6 7.00001C5.40665 7.00001 4.82663 6.82407 4.33329 6.49442C3.83994 6.16478 3.45542 5.69624 3.22836 5.14806C3.00129 4.59988 2.94188 3.99668 3.05764 3.41474C3.17339 2.83279 3.45912 2.29824 3.87868 1.87868C4.29823 1.45912 4.83278 1.1734 5.41473 1.05765C5.99667 0.94189 6.59987 1.0013 7.14805 1.22836C7.69623 1.45543 8.16477 1.83995 8.49441 2.33329C8.82406 2.82664 9.00001 3.40666 9.00001 4.00001C9.0002 4.39403 8.92273 4.78422 8.77203 5.14829C8.62134 5.51235 8.40037 5.84315 8.12175 6.12176C7.84314 6.40037 7.51234 6.62135 7.14828 6.77204C6.78422 6.92274 6.39402 7.00021 6 7.00001ZM6 2C5.82149 2.0025 5.64412 2.02906 5.47271 2.07896C5.614 2.27098 5.68181 2.50727 5.66382 2.74499C5.64584 2.98271 5.54326 3.20612 5.37469 3.37469C5.20611 3.54327 4.98271 3.64585 4.74499 3.66383C4.50727 3.68182 4.27097 3.61401 4.07895 3.47271C3.96961 3.87555 3.98935 4.30252 4.13539 4.69355C4.28143 5.08458 4.54641 5.41996 4.89304 5.6525C5.23967 5.88504 5.6505 6.00302 6.0677 5.98984C6.4849 5.97667 6.88746 5.83299 7.21873 5.57903C7.54999 5.32508 7.79328 4.97364 7.91434 4.57417C8.03541 4.17471 8.02816 3.74734 7.89361 3.35221C7.75906 2.95708 7.50399 2.6141 7.1643 2.37153C6.82461 2.12896 6.41741 1.99902 6 2Z"
                fill="white"
              />
            </svg>
            &nbsp;
          </>
        );
      case "add":
        return (
          <>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.07283 4.92777V1.7114C7.07283 1.3167 6.75287 0.996737 6.35801 0.996577L5.64319 0.996577C5.2485 0.996577 4.92853 1.31654 4.92837 1.7114L4.92837 4.92777L1.712 4.92777C1.31731 4.92777 0.997342 5.24773 0.997181 5.64259L0.997181 6.35741C0.997181 6.7521 1.31715 7.07207 1.712 7.07223L4.92837 7.07223L4.92837 10.2886C4.92837 10.6833 5.24834 11.0033 5.64319 11.0034L6.35801 11.0034C6.75271 11.0034 7.07284 10.6833 7.07284 10.2886L7.07283 7.07223L10.2892 7.07223C10.6839 7.07223 11.004 6.7521 11.004 6.35741V5.64259C11.004 5.2479 10.6841 4.92793 10.2892 4.92777L7.07283 4.92777Z"
                fill="white"
              />
            </svg>
            &nbsp;
          </>
        );
      case "close":
        return (
          <svg
            className="close"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.27455 5.96743L11.686 2.57447C12.1047 2.15811 12.1047 1.48304 11.686 1.06634L10.9278 0.312273C10.5092 -0.104091 9.83045 -0.104091 9.41148 0.312273L6 3.70523L2.58852 0.312273C2.16989 -0.104091 1.49114 -0.104091 1.07216 0.312273L0.313977 1.06634C-0.104659 1.4827 -0.104659 2.15777 0.313977 2.57447L3.72545 5.96743L0.313977 9.36039C-0.104659 9.77675 -0.104659 10.4518 0.313977 10.8685L1.07216 11.6226C1.4908 12.039 2.16989 12.039 2.58852 11.6226L6 8.22963L9.41148 11.6226C9.83011 12.039 10.5092 12.039 10.9278 11.6226L11.686 10.8685C12.1047 10.4522 12.1047 9.77709 11.686 9.36039L8.27455 5.96743Z"
              fill="#1A1A1A"
            />
          </svg>
        );
      case "play":
        return (
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="#36A153"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 0C2.2379 0 0 2.2379 0 5C0 7.7621 2.2379 10 5 10C7.7621 10 10 7.7621 10 5C10 2.2379 7.7621 0 5 0ZM7.33266 5.48387L3.78427 7.52016C3.46573 7.69758 3.06452 7.46976 3.06452 7.09677V2.90323C3.06452 2.53226 3.46371 2.30242 3.78427 2.47984L7.33266 4.6371C7.66331 4.82258 7.66331 5.3004 7.33266 5.48387Z"
              fill="#36A153"
            />
          </svg>
        );
      case "play-black":
        return (
          <svg
            className="invert-svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.49939 10.9996C1.49939 5.75259 5.75296 1.49902 11 1.49902C16.247 1.49902 20.5006 5.75259 20.5006 10.9996C20.5006 16.2467 16.247 20.5002 11 20.5002C5.75296 20.5002 1.49939 16.2467 1.49939 10.9996ZM11 0.499023C5.20067 0.499023 0.49939 5.20031 0.49939 10.9996C0.49939 16.799 5.20067 21.5002 11 21.5002C16.7993 21.5002 21.5006 16.799 21.5006 10.9996C21.5006 5.20031 16.7993 0.499023 11 0.499023ZM9.27723 6.5834C9.1238 6.48111 8.92653 6.47158 8.76395 6.55859C8.60137 6.6456 8.49988 6.81503 8.49988 6.99942V14.9999C8.49988 15.1843 8.60137 15.3537 8.76395 15.4407C8.92653 15.5278 9.1238 15.5182 9.27723 15.4159L15.2776 11.4157C15.4167 11.323 15.5002 11.1668 15.5002 10.9997C15.5002 10.8325 15.4167 10.6764 15.2776 10.5836L9.27723 6.5834ZM14.0989 10.9997L9.49988 14.0657V7.93368L14.0989 10.9997Z"
              fill="black"
            />
          </svg>
        );
      case "pause":
        return (
          <svg
            width="8"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="2" height="10" fill="#ffffff" />
            <rect x="6" width="2" height="10" fill="#ffffff" />
          </svg>
        );
      case "union":
        return (
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.2501 0.565969C10.0954 0.476708 9.90487 0.476708 9.7502 0.565969L1.08316 5.56794C0.951451 5.64396 0.867408 5.77085 0.84153 5.90947C0.83533 5.94269 0.832469 5.97658 0.833091 6.0106V16.002C0.833091 16.1807 0.928472 16.3458 1.08328 16.4351L9.73551 21.4255C9.85537 21.5 10.0038 21.5216 10.1424 21.4803C10.1796 21.4692 10.2158 21.4538 10.2502 21.4339L10.2616 21.4271L18.9166 16.4351C19.0714 16.3458 19.1668 16.1807 19.1668 16.002V6.02027C19.167 6.01388 19.1672 6.00748 19.1672 6.00106C19.1672 5.82238 19.0719 5.65726 18.9171 5.56794L10.2501 0.565969ZM18.1668 6.86681L10.5001 11.2888V20.1351L18.1668 15.7131V6.86681ZM10.0001 10.4228L17.6666 6.00087L10.0001 1.57632L2.3336 6.00087L10.0001 10.4228ZM1.83309 6.86661V15.7131L9.50013 20.1353V11.2888L1.83309 6.86661Z"
              fill="white"
            />
          </svg>
        );
      case "stop":
        return <div className="stopIcon"></div>;
      case "cross":
        return (
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.51636 4L7.79068 1.72568C8.06977 1.44659 8.06977 0.994091 7.79068 0.714773L7.28523 0.209318C7.00614 -0.0697727 6.55364 -0.0697727 6.27432 0.209318L4 2.48364L1.72568 0.209318C1.44659 -0.0697727 0.994091 -0.0697727 0.714773 0.209318L0.209318 0.714773C-0.0697727 0.993864 -0.0697727 1.44636 0.209318 1.72568L2.48364 4L0.209318 6.27432C-0.0697727 6.55341 -0.0697727 7.00591 0.209318 7.28523L0.714773 7.79068C0.993864 8.06977 1.44659 8.06977 1.72568 7.79068L4 5.51636L6.27432 7.79068C6.55341 8.06977 7.00614 8.06977 7.28523 7.79068L7.79068 7.28523C8.06977 7.00614 8.06977 6.55364 7.79068 6.27432L5.51636 4Z"
              fill="white"
            />
          </svg>
        );
      case "delete":
        return (
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.00065 10.6667C1.00065 11.4 1.60065 12 2.33398 12H7.66732C8.40065 12 9.00065 11.4 9.00065 10.6667V2.66667H1.00065V10.6667ZM9.66732 0.666667H7.33398L6.66732 0H3.33398L2.66732 0.666667H0.333984V2H9.66732V0.666667Z"
              fill="white"
            />
          </svg>
        );
      case "record":
        return (
          <svg
            width="11"
            height="12"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5.5" cy="6" r="5.5" fill="white" />
          </svg>
        );
      case "sync":
        return (
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.3129 2.52581C6.68262 1.93565 5.86468 1.61214 4.99694 1.6129C3.43528 1.61427 2.0871 2.68504 1.71486 4.17036C1.68776 4.27849 1.59143 4.35484 1.47996 4.35484H0.324657C0.173488 4.35484 0.0586492 4.2176 0.0866129 4.06903C0.522843 1.7525 2.55671 0 5 0C6.33968 0 7.55627 0.526935 8.45393 1.38478L9.17399 0.664718C9.47881 0.359899 10 0.575786 10 1.00688V3.70968C10 3.97692 9.78337 4.19355 9.51613 4.19355H6.81333C6.38224 4.19355 6.16635 3.67236 6.47117 3.36752L7.3129 2.52581ZM0.483871 5.80645H3.18667C3.61776 5.80645 3.83365 6.32764 3.52883 6.63248L2.6871 7.47421C3.31738 8.06439 4.13538 8.3879 5.00315 8.38712C6.56399 8.38571 7.91272 7.31567 8.28514 5.82968C8.31224 5.72155 8.40857 5.6452 8.52004 5.6452H9.67536C9.82653 5.6452 9.94137 5.78244 9.91341 5.93101C9.47716 8.2475 7.44329 10 5 10C3.66032 10 2.44373 9.47306 1.54607 8.61522L0.826008 9.33528C0.52119 9.6401 0 9.42421 0 8.99312V6.29032C0 6.02308 0.216633 5.80645 0.483871 5.80645Z"
              fill="black"
            />
          </svg>
        );
      case "send":
        return (
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4345 0.075705L0.352521 6.34163C-0.15834 6.58534 -0.0934242 7.17584 0.414614 7.35393L3.41487 8.39903L11.5237 2.46585C11.679 2.35103 11.8991 2.52678 11.7665 2.66034L4.96721 9.53787V11.4242C4.96721 11.9772 5.7716 12.1951 6.16674 11.7944L7.95899 9.98309L11.4757 11.2063C11.8765 11.3469 12.3338 11.1383 12.4071 10.7798L14.4393 0.656838C14.5353 0.183496 13.9228 -0.158623 13.4345 0.075705Z"
              fill="white"
            />
          </svg>
        );
      case "brightTick":
        return (
          <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5352 0.852539L5.53516 11.8525L0.535156 6.85254"
              stroke="#1A1A1A"
              fill="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "info-bright":
        return (
          <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.00006 0.999634C3.51461 0.999634 1.49976 3.01449 1.49976 5.49994C1.49976 7.98539 3.51461 10.0002 6.00006 10.0002C8.48551 10.0002 10.5004 7.98539 10.5004 5.49994C10.5004 3.01449 8.48551 0.999634 6.00006 0.999634ZM0.499756 5.49994C0.499756 2.4622 2.96233 -0.000366211 6.00006 -0.000366211C9.0378 -0.000366211 11.5004 2.4622 11.5004 5.49994C11.5004 8.53767 9.0378 11.0002 6.00006 11.0002C2.96233 11.0002 0.499756 8.53767 0.499756 5.49994ZM6.00006 4.99993C6.2762 4.99993 6.50006 5.22379 6.50006 5.49993V7.50006C6.50006 7.7762 6.2762 8.00006 6.00006 8.00006C5.72391 8.00006 5.50006 7.7762 5.50006 7.50006V5.49993C5.50006 5.22379 5.72391 4.99993 6.00006 4.99993ZM6.00006 2.99983C5.72391 2.99983 5.50006 3.22369 5.50006 3.49983C5.50006 3.77598 5.72391 3.99983 6.00006 3.99983H6.00514C6.28128 3.99983 6.50514 3.77598 6.50514 3.49983C6.50514 3.22369 6.28128 2.99983 6.00514 2.99983H6.00006Z"
              fill="#FFFFFF"
            />
          </svg>
        );
      default:
        return <></>;
    }
  };

  // @ts-ignore
  return getIcon(type);
};

export const RightIcons: React.FC<ILeftIcons> = ({ type }) => {
  const getIcon = (type: IconsType) => {
    switch (type) {
      case "tick":
        return (
          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
	          <path d="M26.6666 8.85074L11.9999 23.5174L5.33325 16.8507" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        );
      case "arrow-right":
        return (
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 16H24" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16 8L24 16L16 24" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      case "help-round":
        return (
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.8853 8.00018C12.8853 10.4856 10.8704 12.5005 8.38495 12.5005C5.8995 12.5005 3.88464 10.4856 3.88464 8.00018C3.88464 5.51473 5.8995 3.49988 8.38495 3.49988C10.8704 3.49988 12.8853 5.51473 12.8853 8.00018ZM8.38495 13.5005C11.4227 13.5005 13.8853 11.0379 13.8853 8.00018C13.8853 4.96245 11.4227 2.49988 8.38495 2.49988C5.34721 2.49988 2.88464 4.96245 2.88464 8.00018C2.88464 11.0379 5.34721 13.5005 8.38495 13.5005ZM8.38495 10.5003C8.6611 10.5003 8.88495 10.2764 8.88495 10.0003C8.88495 9.72415 8.6611 9.50029 8.38495 9.50029L8.37987 9.50029C8.10373 9.50029 7.87987 9.72415 7.87987 10.0003C7.87987 10.2764 8.10373 10.5003 8.37987 10.5003L8.38495 10.5003Z"
              fill="#1A1A1A"
            />
            <path
              d="M7.05127 6.22371C7.15881 5.9269 7.37106 5.67661 7.65045 5.51719C7.92983 5.35776 8.25831 5.29948 8.57771 5.35268C8.8971 5.40587 9.1868 5.5671 9.3955 5.80781C9.6042 6.04852 9.71842 6.35318 9.71793 6.66782C9.71793 7.55605 8.34572 8.00016 8.34572 8.00016"
              stroke="#1A1A1A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "info-circle":
        return (
          <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.00006 0.999634C3.51461 0.999634 1.49976 3.01449 1.49976 5.49994C1.49976 7.98539 3.51461 10.0002 6.00006 10.0002C8.48551 10.0002 10.5004 7.98539 10.5004 5.49994C10.5004 3.01449 8.48551 0.999634 6.00006 0.999634ZM0.499756 5.49994C0.499756 2.4622 2.96233 -0.000366211 6.00006 -0.000366211C9.0378 -0.000366211 11.5004 2.4622 11.5004 5.49994C11.5004 8.53767 9.0378 11.0002 6.00006 11.0002C2.96233 11.0002 0.499756 8.53767 0.499756 5.49994ZM6.00006 4.99993C6.2762 4.99993 6.50006 5.22379 6.50006 5.49993V7.50006C6.50006 7.7762 6.2762 8.00006 6.00006 8.00006C5.72391 8.00006 5.50006 7.7762 5.50006 7.50006V5.49993C5.50006 5.22379 5.72391 4.99993 6.00006 4.99993ZM6.00006 2.99983C5.72391 2.99983 5.50006 3.22369 5.50006 3.49983C5.50006 3.77598 5.72391 3.99983 6.00006 3.99983H6.00514C6.28128 3.99983 6.50514 3.77598 6.50514 3.49983C6.50514 3.22369 6.28128 2.99983 6.00514 2.99983H6.00006Z"
              fill="#1A1A1A"
            ></path>
          </svg>
        );
      case "info-circle-bold":
        return (
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 4C5.51459 4 3.49973 6.01486 3.49973 8.50031C3.49973 10.9858 5.51459 13.0006 8.00004 13.0006C10.4855 13.0006 12.5003 10.9858 12.5003 8.50031C12.5003 6.01486 10.4855 4 8.00004 4ZM2.49973 8.50031C2.49973 5.46257 4.9623 3 8.00004 3C11.0378 3 13.5003 5.46257 13.5003 8.50031C13.5003 11.538 11.0378 14.0006 8.00004 14.0006C4.9623 14.0006 2.49973 11.538 2.49973 8.50031ZM8.00003 8.0003C8.27618 8.0003 8.50003 8.22416 8.50003 8.5003V10.5004C8.50003 10.7766 8.27618 11.0004 8.00003 11.0004C7.72389 11.0004 7.50003 10.7766 7.50003 10.5004V8.5003C7.50003 8.22416 7.72389 8.0003 8.00003 8.0003ZM8.00003 6.0002C7.72389 6.0002 7.50003 6.22406 7.50003 6.5002C7.50003 6.77634 7.72389 7.0002 8.00003 7.0002H8.00511C8.28126 7.0002 8.50511 6.77634 8.50511 6.5002C8.50511 6.22406 8.28126 6.0002 8.00511 6.0002H8.00003Z" fill="#1A1A1A"/>
          </svg>
        );
      case "closeDarkBold":
        return (
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0204 5.18558C12.2156 4.99032 12.2156 4.67374 12.0204 4.47848C11.8251 4.28322 11.5085 4.28322 11.3133 4.47848L7.99997 7.79177L4.68668 4.47848C4.49142 4.28322 4.17484 4.28322 3.97958 4.47848C3.78431 4.67374 3.78431 4.99032 3.97958 5.18558L7.29287 8.49887L3.97958 11.8122C3.78431 12.0074 3.78431 12.324 3.97958 12.5193C4.17484 12.7145 4.49142 12.7145 4.68668 12.5193L7.99997 9.20598L11.3133 12.5193C11.5085 12.7145 11.8251 12.7145 12.0204 12.5193C12.2156 12.324 12.2156 12.0074 12.0204 11.8122L8.70708 8.49887L12.0204 5.18558Z" fill="#1A1A1A"/>
          </svg>
        );
      case "cross-right":
        return (
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.51636 4L7.79068 1.72568C8.06977 1.44659 8.06977 0.994091 7.79068 0.714773L7.28523 0.209318C7.00614 -0.0697727 6.55364 -0.0697727 6.27432 0.209318L4 2.48364L1.72568 0.209318C1.44659 -0.0697727 0.994091 -0.0697727 0.714773 0.209318L0.209318 0.714773C-0.0697727 0.993864 -0.0697727 1.44636 0.209318 1.72568L2.48364 4L0.209318 6.27432C-0.0697727 6.55341 -0.0697727 7.00591 0.209318 7.28523L0.714773 7.79068C0.993864 8.06977 1.44659 8.06977 1.72568 7.79068L4 5.51636L6.27432 7.79068C6.55341 8.06977 7.00614 8.06977 7.28523 7.79068L7.79068 7.28523C8.06977 7.00614 8.06977 6.55364 7.79068 6.27432L5.51636 4Z"
              fill="white"
            />
          </svg>
        );
      case "share":
        return (
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.22348 0.806823C8.02524 0.614587 7.70869 0.619455 7.51646 0.817697C7.32422 1.01594 7.32909 1.33248 7.52733 1.52472L9.76731 3.69684H3.49939C2.70846 3.69684 1.94694 4.00137 1.38312 4.54811C0.818772 5.09536 0.498779 5.84093 0.498779 6.6217V7.83413C0.498779 8.11027 0.722637 8.33413 0.998779 8.33413C1.27492 8.33413 1.49878 8.11027 1.49878 7.83413V6.6217C1.49878 6.11624 1.7057 5.62826 2.07927 5.26601C2.45336 4.90325 2.96392 4.69684 3.49939 4.69684H9.76732L7.52733 6.86897C7.32909 7.06121 7.32422 7.37775 7.51646 7.57599C7.70869 7.77423 8.02524 7.7791 8.22348 7.58687L11.3452 4.55975C11.4413 4.46863 11.5012 4.33973 11.5012 4.19684C11.5012 4.05367 11.441 3.92455 11.3446 3.8334L8.22348 0.806823Z"
              fill="#1A1A1A"
            />
          </svg>
        );
      case "share-transparent":
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7066 7.29125L8.70875 0.293438C8.3175 -0.0978125 7.68281 -0.0978125 7.29125 0.293438L0.293438 7.29125C-0.0978125 7.68281 -0.0978125 8.31719 0.293438 8.70875L7.29125 15.7066C7.6825 16.0981 8.31719 16.0981 8.70875 15.7066L15.7066 8.70875C16.0978 8.31719 16.0978 7.68281 15.7066 7.29125ZM12.5509 7.68375L9.91938 10.1128C9.75938 10.2606 9.49969 10.1472 9.49969 9.92906V8.25H6.49969V10.25C6.49969 10.3881 6.38781 10.5 6.24969 10.5H5.24969C5.11156 10.5 4.99969 10.3881 4.99969 10.25V7.75C4.99969 7.19781 5.4475 6.75 5.99969 6.75H9.49969V5.07094C9.49969 4.85313 9.75906 4.73938 9.91938 4.88719L12.5509 7.31625C12.6581 7.41531 12.6581 7.58469 12.5509 7.68375Z"
              fill="white"
            />
          </svg>
        );
      case "submit":
        return (
          <svg
            width="12"
            height="10"
            viewBox="0 0 10 8"
            style={{ marginLeft: "5px" }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95634 0.05047L0.235014 4.22776C-0.10556 4.39022 -0.0622828 4.78389 0.27641 4.90262L2.27658 5.59936L7.68248 1.6439C7.78597 1.56735 7.93274 1.68452 7.8443 1.77356L3.31147 6.35858V7.61614C3.31147 7.98481 3.84773 8.1301 4.11116 7.86296L5.30599 6.65539L7.6505 7.47085C7.91769 7.56459 8.22251 7.42555 8.27143 7.18654L9.6262 0.437892C9.69018 0.122331 9.28187 -0.105749 8.95634 0.05047Z"
              fill="white"
            />
          </svg>
        );
      case "favourite":
        return (
          <svg
            width="10"
            style={{ marginLeft: "20px" }}
            height="11"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.66683 10.5L5.00016 8.27778L1.3335 10.5V1.61111C1.3335 1.31643 1.44387 1.03381 1.64034 0.825437C1.8368 0.617063 2.10327 0.5 2.38112 0.5H7.61921C7.89706 0.5 8.16352 0.617063 8.35999 0.825437C8.55645 1.03381 8.66683 1.31643 8.66683 1.61111V10.5Z"
              stroke="#1A1A1A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "mark":
        return (
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.07573 9.43979L0.175729 5.53979C-0.0585762 5.30548 -0.0585762 4.92558 0.175729 4.69126L1.02424 3.84272C1.25854 3.60839 1.63846 3.60839 1.87277 3.84272L4.5 6.46993L10.1272 0.842721C10.3615 0.608416 10.7415 0.608416 10.9758 0.842721L11.8243 1.69125C12.0586 1.92556 12.0586 2.30546 11.8243 2.53978L4.92426 9.43981C4.68994 9.67412 4.31004 9.67412 4.07573 9.43979Z"
              fill="#1A1A1A"
            />
          </svg>
        );
      case "favouriteOn":
        return (
          <svg
            width="13"
            height="16"
            style={{ marginLeft: "20px" }}
            viewBox="0 0 13 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16V1.5C0 0.671562 0.716333 0 1.6 0H11.2C12.0837 0 12.8 0.671562 12.8 1.5V16L6.4 12.5L0 16Z"
              fill="#FFC076"
            />
          </svg>
        );
      case "got-it":
        return (
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            style={{ marginLeft: "10px" }}
            fill="#FFF"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.07573 9.43979L0.175729 5.53979C-0.0585762 5.30548 -0.0585762 4.92558 0.175729 4.69126L1.02424 3.84272C1.25854 3.60839 1.63846 3.60839 1.87277 3.84272L4.5 6.46993L10.1272 0.842721C10.3615 0.608416 10.7415 0.608416 10.9758 0.842721L11.8243 1.69125C12.0586 1.92556 12.0586 2.30546 11.8243 2.53978L4.92426 9.43981C4.68994 9.67412 4.31004 9.67412 4.07573 9.43979Z"
              fill="#FFF"
            />
          </svg>
        );
      case "logout":
        return (
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            style={{ marginLeft: "10px" }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.62472 11.9994H2.99985C1.34368 11.9994 0 10.6557 0 8.99956V2.99985C0 1.34368 1.34368 0 2.99985 0H5.62472C5.83097 0 5.99971 0.168742 5.99971 0.374982V1.62492C5.99971 1.83116 5.83097 1.9999 5.62472 1.9999H2.99985C2.44676 1.9999 1.9999 2.44676 1.9999 2.99985V8.99956C1.9999 9.55266 2.44676 9.99951 2.99985 9.99951H5.62472C5.83097 9.99951 5.99971 10.1683 5.99971 10.3745V11.6244C5.99971 11.8307 5.83097 11.9994 5.62472 11.9994ZM9.30892 2.528L11.7338 4.74977H5.74972C5.33411 4.74977 4.99976 5.08413 4.99976 5.49973V6.49968C4.99976 6.91529 5.33411 7.24965 5.74972 7.24965H11.7338L9.30892 9.47141C8.99331 9.7589 8.98394 10.2526 9.28392 10.5557L9.96826 11.2401C10.2589 11.5307 10.7339 11.5338 11.0276 11.2432L15.7774 6.53093C16.0742 6.2372 16.0742 5.75909 15.7774 5.46536L11.0307 0.759338C10.737 0.468727 10.2651 0.468727 9.97139 0.762463L9.28705 1.4468C8.98394 1.74679 8.99644 2.24052 9.30892 2.528Z"
              fill="white"
            />
          </svg>
        );
      case "lock":
        return (
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            style={{ marginLeft: "10px" }}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7143 6.85717H4.07143V4.09556C4.07143 3.03485 4.92054 2.1536 5.98125 2.14288C7.05268 2.13217 7.92857 3.00271 7.92857 4.07146V4.50003C7.92857 4.85628 8.21518 5.14288 8.57143 5.14288H9.42857C9.78482 5.14288 10.0714 4.85628 10.0714 4.50003V4.07146C10.0714 1.82146 8.23661 -0.00800934 5.98661 2.6371e-05C3.73661 0.00806209 1.92857 1.86163 1.92857 4.11163V6.85717H1.28571C0.575893 6.85717 0 7.43306 0 8.14288V12.4286C0 13.1384 0.575893 13.7143 1.28571 13.7143H10.7143C11.4241 13.7143 12 13.1384 12 12.4286V8.14288C12 7.43306 11.4241 6.85717 10.7143 6.85717Z"
              fill="white"
            />
          </svg>
        );
      case "info":
        return (
          <svg
            width="12"
            height="11"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.00006 0.999634C3.51461 0.999634 1.49976 3.01449 1.49976 5.49994C1.49976 7.98539 3.51461 10.0002 6.00006 10.0002C8.48551 10.0002 10.5004 7.98539 10.5004 5.49994C10.5004 3.01449 8.48551 0.999634 6.00006 0.999634ZM0.499756 5.49994C0.499756 2.4622 2.96233 -0.000366211 6.00006 -0.000366211C9.0378 -0.000366211 11.5004 2.4622 11.5004 5.49994C11.5004 8.53767 9.0378 11.0002 6.00006 11.0002C2.96233 11.0002 0.499756 8.53767 0.499756 5.49994ZM6.00006 4.99993C6.2762 4.99993 6.50006 5.22379 6.50006 5.49993V7.50006C6.50006 7.7762 6.2762 8.00006 6.00006 8.00006C5.72391 8.00006 5.50006 7.7762 5.50006 7.50006V5.49993C5.50006 5.22379 5.72391 4.99993 6.00006 4.99993ZM6.00006 2.99983C5.72391 2.99983 5.50006 3.22369 5.50006 3.49983C5.50006 3.77598 5.72391 3.99983 6.00006 3.99983H6.00514C6.28128 3.99983 6.50514 3.77598 6.50514 3.49983C6.50514 3.22369 6.28128 2.99983 6.00514 2.99983H6.00006Z"
              fill="#1A1A1A"
            />
          </svg>
        );
      case "x":
        return (
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.16579 5.50016L9.37089 2.29506L10.0319 1.6341C10.1294 1.53659 10.1294 1.37815 10.0319 1.28065L9.32464 0.57344C9.22714 0.475937 9.0687 0.475937 8.97119 0.57344L5.10514 4.4395L1.23908 0.573127C1.14158 0.475624 0.983133 0.475624 0.88563 0.573127L0.178108 1.28034C0.0806047 1.37784 0.0806047 1.53628 0.178108 1.63379L4.04448 5.50016L0.178108 9.36621C0.0806047 9.46372 0.0806047 9.62216 0.178108 9.71966L0.885317 10.4269C0.98282 10.5244 1.14126 10.5244 1.23877 10.4269L5.10514 6.56081L8.31024 9.76591L8.97119 10.4269C9.0687 10.5244 9.22714 10.5244 9.32464 10.4269L10.0319 9.71966C10.1294 9.62216 10.1294 9.46372 10.0319 9.36621L6.16579 5.50016Z"
              fill="black"
            />
          </svg>
        );
      case "x-bright":
        return (
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.16579 5.50016L9.37089 2.29506L10.0319 1.6341C10.1294 1.53659 10.1294 1.37815 10.0319 1.28065L9.32464 0.57344C9.22714 0.475937 9.0687 0.475937 8.97119 0.57344L5.10514 4.4395L1.23908 0.573127C1.14158 0.475624 0.983133 0.475624 0.88563 0.573127L0.178108 1.28034C0.0806047 1.37784 0.0806047 1.53628 0.178108 1.63379L4.04448 5.50016L0.178108 9.36621C0.0806047 9.46372 0.0806047 9.62216 0.178108 9.71966L0.885317 10.4269C0.98282 10.5244 1.14126 10.5244 1.23877 10.4269L5.10514 6.56081L8.31024 9.76591L8.97119 10.4269C9.0687 10.5244 9.22714 10.5244 9.32464 10.4269L10.0319 9.71966C10.1294 9.62216 10.1294 9.46372 10.0319 9.36621L6.16579 5.50016Z"
              fill="white"
            />
          </svg>
        );
      case "carret-off":
        return (
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.364 2.93904L1.414 7.88904L-3.69108e-06 6.47504L6.364 0.111038L12.728 6.47504L11.314 7.88904L6.364 2.93904Z"
              fill="black"
            />
          </svg>
        );
      case "carret-on":
        return (
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.864 5.56108L11.814 0.611084L13.228 2.02508L6.864 8.38908L0.5 2.02508L1.914 0.611084L6.864 5.56108Z"
              fill="black"
            />
          </svg>
        );
      case "plus":
        return (
          <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 7.16675V25.8334"
              stroke="#1A1A1A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.66675 16.5H25.3334"
              stroke="#1A1A1A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "minus":
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66699 16H25.3337"
              stroke="#1A1A1A"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case "next-step":
        return (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7.00635H13"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 1.00635L13 7.00635L7 13.0063"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      // case "redirect":
      //   return (
      //     <svg
      //       width="32"
      //       height="32"
      //       viewBox="0 0 32 32"
      //       fill="none"
      //       xmlns="http://www.w3.org/2000/svg"
      //     >
      //       <path
      //         d="M9.3335 22.6668L22.6668 9.3335"
      //         stroke="white"
      //         strokeLinecap="round"
      //         strokeLinejoin="round"
      //       />
      //       <path
      //         d="M9.3335 9.3335H22.6668V22.6668"
      //         stroke="white"
      //         strokeLinecap="round"
      //         strokeLinejoin="round"
      //       />
      //     </svg>
      //   );
        case "redirectDarkThin":
          return (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 17L17.5 7" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.5 7H17.5V17" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        );
      default:
        return <></>;
    }
  };

  // @ts-ignore
  return getIcon(type);
};
