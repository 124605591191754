import React, {useEffect, useState, memo} from 'react';
import {ImageLoaderProps} from './types';
import Loader from '../Spinner';
import {isEqual} from 'lodash';
import logger from '../../utils/logger';

const ImageLoader: React.FC<ImageLoaderProps> = memo(({imgUrls, onClick, style, loadingCallback}) => {
    const [imgSrc, setImgSrc] = useState(!!imgUrls ? imgUrls[0] : '');
    const [localImgUrl, setLocalImageUrls] = useState([] || '');
    const [isLoading, setLoading] = useState(true);
    const localImgUrlRef = React.useRef(localImgUrl);

    const handleImageOnLoad = (e) => {
        setLoading(false);
        if(loadingCallback) {
            loadingCallback();
        }
    }

    useEffect(() => {
        localImgUrlRef.current.length <= 0 && loadImage();
        return () => logger.debug("Unmounting---------")
    }, []);

    useEffect(() => {
        localImgUrlRef.current = localImgUrl;
    },[localImgUrl])

    useEffect(() => {
        if(!!imgUrls){
            setImgSrc(imgUrls[0]);
        }
    }, [imgUrls])

    useEffect(() => {
        if(!isEqual(localImgUrl, imgUrls) && (!!localImgUrl && localImgUrl.length <= 0))
            // @ts-ignore
            setLocalImageUrls(imgUrls);
    },[localImgUrl,setLocalImageUrls,imgSrc])

    const loadImage = async () => {
        if(imgSrc)
            // @ts-ignore
            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
                // @ts-ignore
            })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

        // Check if browser use the 2nd image in array that is not a webp image.
        // @ts-ignore
        if (isSafari && imgUrls[1]) {
            setImgSrc(imgUrls[1]);
            return;
        }
        imgUrls && imgUrls[0] && setImgSrc(imgUrls[0]);
    };

    return (
        <div className={isLoading ? "image-loader-container image-loading" : "image-loader"} style={style ? style : {}}>
            <img onClick={onClick && onClick} onLoad={handleImageOnLoad} src={imgSrc} className={`${isLoading ? " actual-image anime-loader" : " actual-image"} `}/>
            {/* @ts-ignore */}
            {isLoading && <Loader />}
        </div>
    )
})

export default React.memo(ImageLoader);