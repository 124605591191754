function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'p.m.' : 'a.m.';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const secondsToHms = (d) => {
  let sec_num = parseInt(d, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  let seconds = sec_num - (hours * 3600) - (minutes * 60);

  // if (hours < 10) {
  //     // @ts-ignore
  //     hours = "0" + hours
  // }
  // if (minutes < 10) {
  //     // @ts-ignore
  //     minutes = "0" + minutes;
  // }
  if (seconds < 10) {
      // @ts-ignore
      seconds = "0" + seconds;
  }
  // @ts-ignore
  const smHours = hours ;
  //!== "00" ? hours + ':' : '';
  return smHours + minutes + ':' + seconds;
}

export const formatDate = (date, isEndDate = false, isFullMonth = false) => {
  const newDate = new Date(date);
  var month = [];
  month[0] = !isFullMonth ? "Jan" : "January";
  month[1] = !isFullMonth ? "Feb" : "February";
  month[2] = !isFullMonth ? "Mar" : "March";
  month[3] = !isFullMonth ? "Apr" : "April";
  month[4] = !isFullMonth ? "May" : "May";
  month[5] = !isFullMonth ? "Jun" : "June";
  month[6] = !isFullMonth ? "Jul" : "July";
  month[7] = !isFullMonth ? "Aug" : "August";
  month[8] = !isFullMonth ? "Sep" : "September";
  month[9] = !isFullMonth ? "Oct" : "October";
  month[10] = !isFullMonth ? "Nov" : "November";
  month[11] = !isFullMonth ? "Dec" : "December";
  const monthName = month[newDate.getMonth()];
  const newdate = newDate.getDate();
  const endYear = newDate.getFullYear();
  return isEndDate ? `${newdate} ${monthName} ${endYear}` : `${newdate} ${monthName}`;
};


export const isMobileDevice = () => {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    return true
  }else{
    return false
  }
}
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export function isIos() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const isAndroidDevice = () => {
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  // if(isAndroid) {
  //     // Do something!
  //     // Redirect to Android-site?
  //     // window.location = 'http://android.davidwalsh.name';
  // }
  return isAndroid;
}

export const toInches = (value) => {
  return (parseFloat(value) * 0.393701).toFixed(1);
};


export const getShareURL = (type, id) => {
  const SHARE_APP = 'https://app.vortic.io/'
  return `${SHARE_APP}${type}/${id}`
};


export const IsSafari = () => {
  // @ts-ignore
  return /constructor/i.test(window.HTMLElement) || (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
      // @ts-ignore
  })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

}
export const getIdFromSlug = (slug) => {
  if (!slug) {
      return;
  }
  try {
      let splitted = slug && slug?.split("-");
      if (splitted && splitted.length > 1) {
          return splitted[splitted.length - 1]
      } else {
          return slug;
      }
  }
  catch(err) {
      return slug;
  }
  
}

export const constructImageUrl = (image, size="480", isWebp = true) => {
  if(image && image.data) {
      return image.data.CFURL + size + "_" + image.data.name + (isWebp ? '.webp' : '') + image.data.signature;
  }
  else return '';
}

export const constructVideoUrl = (video) => {
  const isMobile = isMobileDevice() || window.innerWidth <= 768; // if mobile variant
  const size = !isMobile ? 'DESKTOP_1080_' : 'MOBILE_720_';
  if(video && video.data) {
      const videoName = video.data.name;
      return video.data.CFURL + size + (!isMobile ? videoName.replace('.mp4', '.webm') : videoName) + video.data.signature;
  }
  else return '';
}

export const makeFullScreen = (divObj) => {
  if(!isIos()){
    if (divObj.requestFullscreen) {
      divObj.requestFullscreen();
    } else if (divObj.msRequestFullscreen) {
      divObj.msRequestFullscreen();
    } else if (divObj.mozRequestFullScreen) {
      divObj.mozRequestFullScreen();
    } else if (divObj.webkitRequestFullscreen) {
      divObj.webkitRequestFullscreen();
    }
  }
}

export const resetFullScreen = () => {
  if(!isIos()){
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}

export const isHTML = (str) => {
  var a = document.createElement('div');
  a.innerHTML = str;

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true; 
  }

  return false;
}

export { useWindowSize } from './useWindowSize';

export const getArtworkStateStatusClass = (status) => {
  let resultState;
  switch(status) {
      case "available":
      case "price on application":
          resultState = "available";
          break;
      default:
          resultState = status;
          break;
  }
  return resultState;
}

export const isTypeMuseum = (galleryType) => {
  return galleryType !== "museum";
}

export const getArtworkStateStatus = (status, isForSale) => {
  return (status === "for sale" || isForSale) && (status === "available" || status === "price on application");
}

const getCurrencyString = (currency) => {
  switch(true){
      case currency.includes('$'):
          return 'USD';
      case currency.includes('€'):
          return 'EUR'
      case currency.includes('£'):
          return 'GBP';
      default:
          return 'USD';
  }
}

export const formatePrice = (price, currency) => {
  if(price !== "" && !!price){
      return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: getCurrencyString(currency),
          maximumFractionDigits: 0
      }).format(price);
  }
  else return "";
}