// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import AudioWrapper from "../../components/AudioWrapper";
import CurateArtworkDetails from "../../components/CurateArtworkDetails";
import rudderStackEvents from "../../services/rudderstack-events";
import { getShareURL, isMobileDevice } from "../../utils";
import { IArtworkDetails } from "./types";

const ArtworksAudioDetails: React.FC<IArtworkDetails> = ({
  // location,
  showEnquireSideBar,
  isForSale,
  isNotMuseum,
  yearCreated,
  price,
  artworkName,
  artistName,
  artworkId,
  exhibitiondId,
  dimensionsInCM,
  dimensionsInIN,
  description,
  onClose,
  handleArtworkDescription,
  resizeCurateCanvas,
  setCurateAudio,
  isSidebar,
  artworkCatalogueNumber,
  audioDetailsPopup,
  setArtworkModal,
  isArtworkModal,
  isActive,
  setActive,
  getCurateHeight,
  currency,
  viewportWidth,
  excludedTax,
  priceUnavailable,
  isMuseumType,
  listview,
  artworkMedium,
  isPlaying,
  togglePlaying,
  artworkPhotos,
  audioUrl,
  totalDuration,
  currentTime,
  setCurrentTime,
  isArt,
  chainLink
}) => {
  const [isEnquired, setEnquired] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const artworkDescriptionRef = useRef(null);

  useEffect(() => {
    resizeCurateCanvas();
  }, [showDetails]);

  useEffect(() => {
    const descriptionBoxHeight =
      artworkDescriptionRef &&
      artworkDescriptionRef.current &&
      artworkDescriptionRef.current.clientHeight;
    handleArtworkDescription(showDescription, descriptionBoxHeight);
  }, [showDescription]);

  useEffect(() => {
    resetEverything();
  }, [artworkId]);

  const resetEverything = () => {
    setEnquired(false);
  };

  const handleEnquire = async () => {
    rudderStackEvents.onArtworkEnquiry(artworkId)
    showEnquireSideBar();
  };

  const serialize = (obj) => {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  };
  
  return (
    <div className="artworks-audio-details">
      <CurateArtworkDetails
        artistName={artistName}
        artworkName={artworkName}
        yearCreated={yearCreated}
        isArtworkListedForSale={isForSale && isNotMuseum}
        priceUnavailable={priceUnavailable}
        isMuseumType={isMuseumType}
        setArtworkModal={setArtworkModal}
        isArtworkModal={isArtworkModal}
        artworkPhotos={artworkPhotos}
        dimensionsInIN={dimensionsInIN}
        dimensionsInCM={dimensionsInCM}
        onClose={onClose}
        isEnquired={isEnquired}
        isSidebar={isSidebar}
        isActive={isActive}
        handleEnquire={() => {}}
        audioDetailsPopup={audioDetailsPopup}
        description={description}
        playing={isPlaying}
        chainLink={chainLink}
        shareUrl={getShareURL("artwork", artworkId)}
        getCurateHeight={getCurateHeight}
        price={price}
        currency={currency}
        artworkId={artworkId}
        artworkMedium={artworkMedium}
        artworkCatalogueNumber={artworkCatalogueNumber}
        viewportWidth={viewportWidth}
        excludedTax={excludedTax}
        listview={listview}
        // Audio stuff
        isPlaying={isPlaying}
        audioUrl={audioUrl}
        currentTime={currentTime}
        totalDuration={totalDuration}
        setCurrentTime={setCurrentTime}
        setIsPlaying={togglePlaying}
        isArt={isArt}
      />
    </div>
  );
};

export default ArtworksAudioDetails;
