import React, {useEffect, useState} from 'react';
import {P1, P3, SubTitle} from '../Typography';
import {Title2, Timeline, Paragraph, Label} from '../Typography2';
import {ICardProps} from './types';
import './style.scss';
import IconBtn from "../IconBtn";
import Button from "../Button";
import ImageLoader from "../ImageLoader";
import ScrollAnimation from "../ScrollAnimation/ScrollAnimation";
import logger from "../../utils/logger";
import { isMobileDevice } from '../../utils';

const Card: React.FC<ICardProps> = (props) => {

    return (
        <>
            {
                props.defaultEvent || props.noFade ? (
                    <>
                        <RenderContent {...props}/>
                    </>
                ) : (
                    <ScrollAnimation>
                        <RenderContent {...props}/>
                    </ScrollAnimation>
                )
            }
        </>
    )
};
const RenderContent: React.FC<ICardProps> = ({
         style, curateSidebar, shouldPlayback, galleryName, subTitle,
         exhibition, isLive, event, onImageClick, imgSrc, title,
         location, description, onClick, horizontal, address, lenghts,
         moderatedBy, timeLine, handleRegister, handleUnRegister, noBorder,
         registered, className, defaultEvent, noFade, isFirstCard, index,
         exhibitionEvents, discoverEvent, curator, curatorReadMore, listviewGallery
        }) => {

    const [isdisable, handleDisale] = useState(false);
    const [isInfo, handleInfo] = useState(false);
    const cropperMaxLength = isMobileDevice() ? 100 : 200;
    const [croppedDesc, setCropperDesc] = useState(description?.substring(0, cropperMaxLength) + '...');
    const [isCropped, setIsCropped] = useState(false);

    useEffect(() => {
        // @ts-ignore
        if(description?.split('').length > cropperMaxLength) {
            setIsCropped(true);
        } else {
            setIsCropped(false);
        }
    }, [])

    useEffect(() => {
        if(isCropped) {
            setCropperDesc(description?.substring(0, cropperMaxLength) + '...');
        } else {
            setCropperDesc(description || '');
        }
    }, [isCropped])

    return (
        <>
            <div
                className={`card  ${horizontal ? '' : 'card--horizontal'} ${curator ? 'card--curator' : ''} ${event ? 'card--event' : ''} ${noBorder ? 'no-border' : ''} ${className || ''} ${exhibition ? 'card--exhibition' : ''} ${defaultEvent ? 'card--default-event' : ''} ${isFirstCard ? 'first-of-type' : ''} ${curateSidebar ? 'card--curate-sidebar' : ''} ${exhibitionEvents && index === 0 ? 'first-type' : ''} ${exhibitionEvents ? 'exhibition-events' : ''} ${discoverEvent ? 'discover-Event' : ''} ${listviewGallery ? 'card--listview-gallery' : ''}`}
                onClick={!isdisable ? ((isLive || shouldPlayback) ? onClick : event ? () => {
                } : onClick) : () => {
                    logger.debug('click disabled')
                }} style={style}
            >

                <div className={`img ${address ? 'address' : ''}`} onClick={onImageClick}>
                    {/* @ts-ignore */}
                    <ImageLoader imgUrls={!!Array.isArray(imgSrc) ? [...imgSrc] : [imgSrc]}/>
                </div>

                <div className={`${event || exhibition ? 'right' : ''}`}>
                    {!defaultEvent && exhibition && <P1 className="exhibitions-type" value={'Exhibition'}/>}
                    {!defaultEvent && defaultEvent && <P1 className="exhibitions-type" value={'Weekly Event'}/>}
                    <Title2 value={title} className='gallery-title'/>
                    {!discoverEvent && event &&
                    <SubTitle className="moderate"
                              value={`Moderator: ${moderatedBy === undefined ? ' ' : moderatedBy}`}/>}

                    {exhibition && <P1 className="exhibitions-subtitle bold" value={subTitle}/>}
                    {!defaultEvent && exhibition &&
                    <p className="p1 exhibitions-time-line bold">{galleryName} <span>{timeLine}</span></p>}
                    {defaultEvent && <p className="p1 exhibitions-time-line bold">{galleryName}</p>}

                    {!curator && !exhibition && !event && location &&
                    <Timeline value={location} className="location"/>
                    }
                    {!exhibition && !event && horizontal && address && (
                        <P1 className="address gray" value={address}/>
                    )
                    }
                    {!curateSidebar && <Paragraph value={description} className="description"/>}

                    {/* {curator && <Paragraph value={croppedDesc} className="description"/>} */}

                    {
                        (discoverEvent || event || defaultEvent) &&
                        <>
                          {/* @ts-ignore */}
                          <Timeline value={timeLine} className="mt-0 data_time"/>
                            {discoverEvent && <div className={'btn_register_box'}>
                                {/* @ts-ignore */}
                              <Button
                                value={!registered ? 'Register' : 'Unregister'}
                                size="medium"
                                className={`${!registered ? 'button__bright' : 'button__dark'} btn-event`}
                                // @ts-ignore
                                onClick={!registered ? handleRegister : handleUnRegister}
                              />
                                {registered && <P1 value="You are registered for this event."/>}
                            </div>}
                        </>
                    }

                    {!exhibition && !event && lenghts ? <P3 value={lenghts}/>
                        : (
                            !exhibition && !event && horizontal ?
                                (
                                    // @ts-ignore
                                    <Button
                                        value='Explore'
                                        className='button__bright btn-collective'
                                    />
                                )
                                :
                                (
                                    <>
                                        {
                                            !curator && !exhibition && !event && onClick && (
                                                <div className="read-more">
                                                    <Label value={'Learn More'}/>
                                                </div>
                                            )
                                        }
                                        {
                                            curator && curatorReadMore && (
                                                <div className="read-more">
                                                    <Label value={'Learn More'}/>
                                                </div>
                                            )
                                        }
                                        {/* {
                                            curator && curatorReadMore && description.split('').length > cropperMaxLength && (
                                                <div onClick={(e) => {setIsCropped(!isCropped); e.stopPropagation();}} className="read-more">
                                                    <Label value={(isCropped ? 'Learn More' : 'Learn Less')}/>
                                                </div>
                                            )
                                        } */}
                                    </>
                        
                                )
                        )
                    }
                    {
                        !discoverEvent && !exhibition && event &&
                        (
                            <div className="btn-event-wrapper">
                                {
                                    (isLive || shouldPlayback) ?
                                        // @ts-ignore
                                        <Button
                                            value={shouldPlayback ? "Watch Session" : "Enter"}
                                            size="medium"
                                            className={` btn-event ${shouldPlayback ? '' : 'red-button'}`}
                                            // @ts-ignore
                                            onClick={onClick}
                                        />
                                        :
                                        <>
                                            {/* @ts-ignore */}
                                            <Button
                                                value={!registered ? 'Register' : 'You are registered'}
                                                size="medium"
                                                className={`${!registered ? 'button__bright' : 'button__dark'} btn-event`}
                                                // @ts-ignore
                                                onClick={!registered ? handleRegister : handleUnRegister}
                                            />
                                            {registered && <P1 value="You are registered for this event."/>}
                                        </>
                                }
                                {curateSidebar && <div onMouseEnter={(e) => {
                                    e.stopPropagation();
                                    handleDisale(true);
                                }} onMouseLeave={(e) => {
                                    e.stopPropagation();
                                    handleDisale(false);
                                }}>
                                    {(event && description && description && (description.trim() !== '')) &&
                                    <IconBtn type="info" onClick={(e) => {
                                        e.stopPropagation();
                                        handleInfo(!isInfo);
                                    }}/>}
                                </div>
                                }

                            </div>
                        )
                    }
                    {discoverEvent && event && isInfo && description && description && (description.trim() !== '') && <>
                      <br/><Paragraph className="events-card-description" value={description}/></>}
                </div>
            </div>
            {!discoverEvent && event && isInfo && description && description && (description.trim() !== '') &&
            <Paragraph className="events-card-description" value={description}/>}
            {!event && <div className="responsive-border-title" />}
        </>
    );
}

export default Card;
