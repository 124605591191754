import React from "react";
import "./style.scss";
import Button from "../Button";
import { ICurateVideoInstrctions } from "./types";
import { isMobileDevice } from "../../utils";

const videos = {
  artwork: {
    desktop:
      "https://vortic-static-content.s3.eu-west-2.amazonaws.com/vortic-art-intro-animations/DETAILS-H-01_1080_text.webp",
    mobile:
      "https://vortic-static-content.s3.eu-west-2.amazonaws.com/vortic-art-intro-animations/DETAILS-H-01_720_mob_text.webp",
  },
  curate: {
    desktop:
      "https://vortic-static-content.s3.eu-west-2.amazonaws.com/vortic-art-intro-animations/STANDARD-A-03_1080_text.webp",
    mobile:
      "https://vortic-static-content.s3.eu-west-2.amazonaws.com/vortic-art-intro-animations/STANDARD-A-03_720_mob_text.webp",
  },
  help: {
    desktop:
      "https://vortic-static-content.s3.eu-west-2.amazonaws.com/vortic-art-intro-animations/A-03_and_H-01_1080_text.webp",
    mobile:
      "https://vortic-static-content.s3.eu-west-2.amazonaws.com/vortic-art-intro-animations/A-03_and_H-01_720_mob_text.webp",
  },
};

const validVariants = {
  curate: true,
  artwork: true,
  help: true,
};

const CurateVideoInstructions: React.FC<ICurateVideoInstrctions> = ({
  variant,
  onClick,
  className,
  isSidePanelOpen
}) => {
  const isMobileView = isMobileDevice() || window.innerWidth <= 640;

  const getButtonLabel = () => {
    if (validVariants[variant]) {
      return "Got it";
    } else if (isMobileView) {
      return "Enter 3D Viewing Room";
    } else {
      return "Enter Exhibition";
    }
  };

  const getVideoUrl = () => {
    let video;

    const { artwork, curate, help } = videos;
    if (variant === "artwork") {
      video = artwork;
    } else if (variant === "help") {
      video = help;
    } else {
      video = curate;
    }

    return isMobileView ? video.mobile : video.desktop;
  };

  return (
    <div className={`curate-video-instructions ${isSidePanelOpen ? 'with-sidebar-open' : ''} ${className || ''} `}>
      {validVariants[variant] && (
        <div className="curate-video-instructions__overlay" />
      )}
      <div className="curate-video-instructions__content">
        <div className="curate-video-instructions__video-wrapper">
          <img
            className="curate-video-instructions__video"
            src={getVideoUrl()}
            alt="instructions"
          />
        </div>
        <Button
          iconType={validVariants[variant] ? "tick" : "arrow-right"}
          value={getButtonLabel()}
          size="small"
          className="button__bright curate-video-instructions__button"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default CurateVideoInstructions;
