import React, { useEffect, useState } from 'react';
import ExpandScreen from '../../assets/icons/expand-screen.svg';
import Collapse from '../../assets/icons/collapse.svg';
import Expand from '../../assets/icons/expand.svg';
import PoweredByVortic from '../../assets/images/powered-by-vortic-2.svg';

import ExpandScreenLight from '../../assets/icons/expand-screen-bright.svg';
import CollapseLight from '../../assets/icons/collapse-light.svg';
import ExpandLight from '../../assets/icons/expand-light.svg';
import PoweredByVorticLight from '../../assets/images/powered-by-vortic-bright.svg';
import { Paragraph } from '../Typography2';
import { makeFullScreen, resetFullScreen } from '../../utils';

const CurateFooter = ({ loadingProgress, isFullScreen, setFullScreen, isCollapsed, timeOutOnFullScreen, isMobileDevice, isRoomLoading }) => {

  const [showFullScreenButton, setFullScreenButton] = useState(false);

  const exitHandler = () => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      setFullScreen(false);
    }
  }

  const handleFullScreenView = () => {
    if (!isFullScreen) {
      const elm = document.querySelector('#root');
      makeFullScreen(elm)
      setTimeout(() => {
        timeOutOnFullScreen()
      }, 2000)
    } else {
      resetFullScreen();
    }
    setFullScreen(!isFullScreen);
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
    setTimeout(() =>{
      setFullScreenButton(true);
    }, 3000)
  }, [])

  return (
    // <footer className={(`curate-footer ${(!isRoomLoading && loadingProgress < 100) ? 'hide' : ''}`) + (isMobileDevice ? ' curate-footer--mobile' : '') + (isCollapsed ? ' curate-footer--active' : '')}>
    <footer className={(`curate-footer`) + (isMobileDevice ? ' curate-footer--mobile' : '') + (isCollapsed ? ' curate-footer--active' : '')}>
      <a href="https://www.vortic.art" target="_blank">
        <img className='curate-footer__powered-by' src={document.innerWidth < 770?PoweredByVortic:PoweredByVorticLight} alt='logo' />
      </a>
    </footer>
  )
};

export default CurateFooter;
