/* eslint-disable default-case */
import React, { useState, useEffect } from 'react'
import './spinner.scss';
import { Heading } from '../Typography2';

export default function Spinner({ prog, secondary=false, scrolled=false, isMobile = false }) {
  const [step, setStep] = useState(0);
  const duration = 1000;

  const getDashOffset = (val) => {
    // switch (val) {
    //   case 0:
    //     return 2000
    //   case 1:
    //     return 1258
    //   case 2:
    //     return 450
    // }
    return 2000
  }

  useEffect(() => {
      if (prog > 0 && prog < 80) {
        setStep(1);
      } else if (prog > 80) {
        setStep(2);
      }
  }, [prog]);

  return (
    <>
      {
        !scrolled ?
          <div className={'loader__spinner' + (secondary ? ' loader__spinner--secondary' : '') + (prog === 100 ? ' loader__spinner--loaded' : '')}>

            <svg xmlns="http://www.w3.org/2000/svg"
                className='ring-loader-wrapper'
                viewBox="-1 -1 34 34">
              
              <circle cx="16" cy="16" r="15.9155"
                      className="progress-bar__background" />
              
              <circle cx="16" cy="16" r="15.9155"
                      style={{ strokeDashoffset: 100 - prog  }}
                      className="progress-bar__progress 
                      
                            js-progress-bar" />
            </svg>

            <TextView step={step}
              isLoaded={prog === 100}
              // @ts-ignore
              data={['Generating 3D exhibition...', 'Loading high definition artworks...', 'Preparing interactive tools']}
            />
          </div>
          :
          <div className={`scrolled ${prog === 100 ? 'loaded' : ''}`}>
            <div className='small-circle'>
              <svg height="480" width="480" style={{ transform: `rotateZ(-90deg) rotateX(${prog === 100 ? '180deg' : '0'})` }}>
                <circle cx="240" cy="240" r="238" stroke="#78D4A2" strokeWidth="8px" fill="rgba(0,0,0,0)"
                  style={{
                    strokeDashoffset: `${prog === 100 ? 1258 : (getDashOffset(Math.round(prog / 50)))}px`,
                    strokeDasharray: "2000px",
                    transition: `all ${duration}ms ease-in-out`,
                  }}
                />
              </svg>
            </div>

            <TextView step={step}
              scrolled={scrolled}
              isLoaded={prog === 100}
              // @ts-ignore
              data={['Generating 3D exhibition...', 'Loading high definition artworks...', 'Preparing interactive tools']}
            />
          </div>
      }
    </>
  )
}

const TextView = ({ step, isLoaded, scrolled }) => {
  return (
    <div className={`loader__textview ${isLoaded ? 'loader__textview--loaded' : ''}`}>
      <div className='textview' style={step <= 2 ? { transform: `translateY(-${(((step * (!scrolled ? 72 : 42))))}px)` } : { transform: `translateY(${!scrolled ? '-145px' : '-87px'})` }}>
        <Heading className={'bright' + (step === 0 ? ' ' : ' fade-out')} value="Generating 3D exhibition..." />
        <Heading className={'bright' + (step === 1 ? ' ' : ' fade-out')} value="Loading high definition artworks..." />
        <Heading className={'bright' + (step === 2 ? ' ' : ' fade-out')} value="Preparing interactive tools" />
      </div>
    </div>
  )
}