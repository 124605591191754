import React from 'react';
import { Label, Heading } from '../Typography2';
import SpinnerLoading from "../Loader/Spinner";
import "./style.scss";

const RoomLoader = ({ title, roomName, progress, border, isArtwork2dModal = false }) => {
    return (
        <div className={'room-loader-wrapper' + (border ? ' border' : '') + (isArtwork2dModal ? ' room-loader-wrapper--artwork-2d-carousel-open' : '')}>
            <div className="room-middle-content">
                <SpinnerLoading prog={progress} secondary={false} scrolled={false} />
            </div>
            <div className='room-bottom-content'>
                <Heading className='bright custom' value={`Moving to ${roomName}`} />
                <Label className='bright' value={title} />
            </div>
        </div>
    )
}

export default RoomLoader;
