import React, { useEffect } from "react";
import PlayBtn from "../../assets/icons/listviewVideoPlay.svg";
import Button from "../Button";
import "./style.scss";

const AboutVideo =({
  videoRef,
  src,
  onClick,
  poster,
  classNames = "",
  listview = false,
  secondary,
  isPlaying
}) => {
    
  useEffect(() => {
    const prefixes = ["", "moz", "webkit", "ms"];

    prefixes.forEach(function (prefix) {
      document.addEventListener(prefix + "fullscreenchange", function (e) {
        const fullscreenelement =
          document.fullscreenElement /* Standard syntax */ ||
          document[
            "webkitFullscreenElement"
          ] /* Chrome, Safari and Opera syntax */ ||
          document["mozFullScreenElement"] /* Firefox syntax */ ||
          document["msFullscreenElement"]; /* IE/Edge syntax */
        if (!fullscreenelement) {
          // eslint-disable-next-line no-unused-expressions
          videoRef?.current.pause();
        }
      });
    });

    return () => {};
  }, []);

  return (
    <div className={`about-video ${listview ? 'about-video--listview' : ''} ${classNames}`} onClick={onClick}>
      <video
        controls={false}
        autoPlay={false}
        ref={videoRef}
        poster={poster}
        src={src}
        style={secondary ? { height: 0, width: 0 } : {}}
      />
      <div className="custom-video-poster">
          <img src={poster} alt="poster" />
      </div>
      <>
        {
          !isPlaying && 
          (
            !secondary ? <div className="play-btn-wrapper">
              <div className="play-button">
                <img src={PlayBtn} alt="Play" />
              </div>
            </div>
            : 
            <Button
              className="button__hollow full-width"
              value="Watch Video"
              iconType="PlayButtonCircleLight"
              onClick={onClick}
            />
          )
        }
      </>
    </div>
  );
}

export default AboutVideo;