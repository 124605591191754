
const GTAG_EVENTS = {
    COLLAPSE_BUTTON_CLICKED: 'CollapseButtonClicked',
    EXPAND_BUTTON_CLICKED: 'ExpandButtonClicked',
    FULLSCREEN_BUTTON_CLICKED: 'FullScreenButtonClicked',
    ARTWORK_CLICKED: 'ArtworkClicked',
    GUIDED_MODE_CLICKED: 'GuidedModeClicked',
    FREE_MODE_CLICKED: 'FreeExplorationClicked',
    CHANGE_ROOM_CLICKED: 'ChangeRoomClicked',
    HELP_CLICKED: 'HelpClicked',
    DETAIL_MODE_SELECTED: 'DetailModeSelected',
    ARTWORK_AUDIO_CLICKED: 'ArtworkAudioClicked',
    NEXT_BUTTON_CLICKED: 'NextButtonClicked',
    PREV_BUTTON_CLICKED: 'PrevButtonClicked',
    CLOSE_INSTRUCTIONS: 'Close_Free_Exploration_Instructions',
    LOAD_NEXT_ROOM: 'Load_Next_Room',
    RESTART_GUIDED_TOUR: 'Restart_Guided_Tour',
    CLOSE_ARTWORK_DETAIL_MODE: 'Close_Artwork_Detail_Mode',
}

export default GTAG_EVENTS;
