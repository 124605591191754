import React  from 'react';
import down from '../../assets/icons/down.svg';
import info from '../../assets/icons/info.svg';
import user from '../../assets/icons/user.svg';
import menu from '../../assets/icons/menu.svg';
import chat from '../../assets/icons/messages.svg';
import events from '../../assets/icons/events.svg';
import share2 from '../../assets/icons/share2.svg';
import zoomIn from '../../assets/icons/zoom-in.svg';
import next from '../../assets/icons/bold-next.svg';
import back from '../../assets/icons/bold-back.svg';
import longBrightArrowRight from '../../assets/icons/longBrightArrowRight.svg';
import longDarkArrowRight from '../../assets/icons/longDarkArrowRight.svg';
import cross from '../../assets/icons/bold-cross.svg';
import question from '../../assets/icons/question.svg';
import comments from '../../assets/icons/comments.svg';
import backArrow from '../../assets/icons/backArrow.svg';
import favourite2 from '../../assets/icons/favourite2.svg';
import favouriteOn2 from '../../assets/icons/favouriteOn2.svg';
import chatForwordIcon from "../../assets/icons/chatForwordIcon.svg"
import crossSecondary from '../../assets/icons/bold-secondary-cross.svg';
import send from '../../assets/icons/send.svg';
import map from '../../assets/icons/map.svg';
import edit from '../../assets/icons/profile-edit.svg';
import placeholder from '../../assets/icons/placeholder.svg';
import prevIcon from '../../assets/icons/prevIcon.svg';
import mobilePrevIcon from '../../assets/icons/mobilePrevIcon.svg';
import brightClose from '../../assets/icons/brightClose.svg';
import brightCloseSecondary from '../../assets/icons/brightCloseSecondary.svg';
import about from '../../assets/icons/about.svg';
import artworks from '../../assets/icons/artworks.svg';
import comment from '../../assets/icons/comment.svg';
import sessions from '../../assets/icons/sessions.svg';
import more from '../../assets/icons/more.svg';
import darkClose from '../../assets/icons/darkClose.svg'
import favouriteFilled from '../../assets/icons/favourite-filled.svg'
import shareFilled from '../../assets/icons/share-filled.svg'
import playOutlined from '../../assets/icons/play-outlined.svg'
import pausedOutlined from '../../assets/icons/paused-outlined.svg'
import chevronLeft from '../../assets/icons/chevron-left.svg'
import chevronRight from '../../assets/icons/chevron-right.svg'
import menuLight from '../../assets/icons/menu-light.svg'
import headPhone from '../../assets/icons/headphone.svg'
import brightCloseSmall from '../../assets/icons/bright-close-sm.svg';
import brightCloseSmallSecondary from '../../assets/icons/bright-close-sm-secondary.svg';
import { IIconBtnProps } from './types';
import { Link } from 'react-router-dom';


const Icon = {
    cross,
    crossSecondary,
    back,
    backSecondary: back,
    next,
    nextSecondary: next,
    info,
    user,
    chat,
    longBrightArrowRight,
    longDarkArrowRight,
    question,
    share2,
    share2Secondary: share2,
    favourite2,
    favourite2Secondary: favourite2,
    favouriteOn2,
    down,
    menu,
    map,
    events,
    zoomIn,
    backArrow,
    send,
    edit,
    comments,
    placeholder,
    prevIcon,
    mobilePrevIcon,
    brightClose,
    about,
    artworks,
    comment,
    sessions,
    more,
    darkClose,
    favouriteFilled,
    shareFilled,
    playOutlined,
    pausedOutlined,
    brightCloseSecondary,
    chevronLeft,
    chevronRight,
    menuLight,
    brightCloseSmall,
    brightCloseSmallSecondary,
    headPhone
};

const IconBtn:React.FC<IIconBtnProps> = ({type, onClick, to, className, secondary, onMouseEnter, onMouseLeave, noHover, onMouseDown, onMouseUp, fromExhLoading, style}) => {
    const button = () => (  
        <div className={'icon-btn icon-btn--bright '+(fromExhLoading && 'position-abs') + (className ? (' ' + className) : '') + (secondary ? ' icon-btn--secondary' : '') + (type === "favouriteOn2" ? ' icon-btn--favourite' : '') + (noHover ? ' icon-btn--no-hover' : '')} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseDown={onMouseDown} onMouseUp={onMouseUp} style={style}>
            <img alt='icon'
                 className={    
                     `${type === 'cross' ? secondary ?  'sec-cross-btn ' : 'cross' : ''} 
                     ${type === 'info' ? 'info' : ''} 
                     ${type === 'menu' ? 'menu' : ''} 
                     ${(type == 'back' || type == 'next') ? className?.includes("remove-arrow") ? '' : 'arrow' : ''}
                     `}
                     src={type=== "next-chat" ? chatForwordIcon : !secondary ? Icon[type] : Icon[type + "Secondary"]}
                     />
        </div>
    );
    return (
        <>{
                to ? (
                    <Link to={to} className='menu-link-btn'>
                        {button()}
                    </Link>
                ) : (
                    <>
                        {button()}
                    </>
                )
            }
        </>
    )
};

export default IconBtn
