import rudderStack from "./rudder-stack";

const EVENTS = {
  EXHIBITION_ENTER: 'public_exhibition_enter',
  EXHIBITION_SHARE: 'public_exhibition_share',
  EXHIBITION_ACTION: 'public_exhibition_action',
  ARTWORK_CLICK: 'public_artwork_click',
  ARTWORK_SHARE: 'public_artwork_share',
  ARTWORK_DETAIL: 'public_artwork_detail',
  START_TOUR: 'public_exhibition_start_tour',
  EXHIBITION_IMPRESSION: 'public_exhibition_impression'
}

class RudderStackEvents {
  constructor() {
    this.exhibition = '';
    this.gallery = '';
  }

  mount = ({exhibitionId, galleryId}) => {
    this.exhibition = exhibitionId;
    this.gallery = galleryId;
  }

  onExhibitionEnter = () => {
    if(rudderStack){
        rudderStack.track(EVENTS.EXHIBITION_ENTER, {
          exhibition_id: this.exhibition,
          gallery_id: this.gallery,
        }) 
    }
  }

  onExhibitionImpression = (pageURL) => {
    if(rudderStack){
        rudderStack.track(EVENTS.EXHIBITION_IMPRESSION, {
          exhibition_id: this.exhibition,
          gallery_id: this.gallery,
          page_url: pageURL,
        })
    }
  }

  onExhibitionShare = () => {
    if(rudderStack) {
        rudderStack.track(EVENTS.EXHIBITION_SHARE, {
          exhibition_id: this.exhibition,
          gallery_id: this.gallery,
        })
    }
  }

  onExhibitionAction = () => {

  }

  onArtworkClick = (artworkId) => {
    if(rudderStack) {
      rudderStack.track(EVENTS.ARTWORK_CLICK, {
        artwork_id: artworkId,
        exhibition_id: this.exhibition,
        gallery_id: this.gallery,
      })
    }
  }

  onArtworkShare = (artworkId) => {
    if(rudderStack) {
        rudderStack.track(EVENTS.ARTWORK_SHARE, {
          artwork_id: artworkId,
          exhibition_id: this.exhibition,
          gallery_id: this.gallery,
        })
    }
  }

  onArtworkDetail = (artworkId) => {
    if(rudderStack) {
      rudderStack.track(EVENTS.ARTWORK_DETAIL, {
        artwork_id: artworkId,
        exhibition_id: this.exhibition,
        gallery_id: this.gallery,
      })
    }
  }

  onStartTour = () => {
    if(rudderStack) {
      rudderStack.track(EVENTS.START_TOUR, {
        exhibition_id: this.exhibition
      })
    }
  }

}


const rudderStackEvents = new RudderStackEvents();
export default rudderStackEvents;
