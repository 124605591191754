import React from "react";
import classNames from "classnames";
import BackBright from "../../assets/icons/back-bright.svg";
import Back from "../../assets/icons/back.svg";
import Close from "../../assets/icons/close-bright.svg";
import MoreBrightIcon from "../../assets/icons/more-horizontal.svg";
import { isMobileDevice } from "../../utils";
import FadeDown from "../FadeDown";
import "./style.scss";
import { ICurateHeaderProps } from "./types";
import CurateModeSwitch from "../CurateModeSwitch";
import AboutInfo from '../../assets/icons/about-info.svg'

const CurateHeader: React.FC<ICurateHeaderProps> = ({
  isRoomLoading,
  rooms,
  showRoomsBox,
  isListView,
  handleExit,
  inProgress,
  onMenuClick,
  closeArtworkDetails,
  isDetailedMode,
  sidePanel,
  isLockedRoom,
  isMobileArtworkExpanded,
  isMenuVisible,
  isIntroVideoEnded,
  onRoomsClick,
  isRoomSwitchModal,
  openDetails,
  onAudioButtonClick,
  isIntoAudioPlaying,
  isIntoAudioFound
}) => {

  const headerClasses = classNames("curate-header", {
    "curate-header--listview": isListView,
    "curate-header--open-sidepanel": sidePanel,
    "curate-header--room-loading": isRoomLoading,
    "curate-header--mobile-artwork-open":
      isMobileArtworkExpanded && isMobileDevice(),
    loaded: !inProgress,
    "curate-header--slide-up": isRoomSwitchModal
  });

  const isMobileView = isMobileDevice() || window.innerWidth <= 768;

  return (
    <header className={headerClasses}>
      <FadeDown variant="fade-in" delay={!isMobileView ? "1s" : "300ms"} duration="600ms">
        {/* @ts-ignore */}
        <div className={`header-left-controls ${rooms?.length < 2 ? 'header-left-controls--no-rooms' : ''}`}>
          <button className="rounded-button back" onClick={handleExit}>
            <img src={Back} className="curate-header-dark-arrow" alt="back" />
            <img
              src={BackBright}
              className="curate-header-white-arrow"
              alt="back"
            />
          </button>
          <button className="header-rooms-button" onClick={() => onRoomsClick(true)}>
              All Rooms
          </button>
          <span className="vertical-separator" />
        </div>
      </FadeDown>

      <div className={`header-right-controls ${sidePanel ? 'header-right-controls--side-panel-open' : ''}  ${(isIntroVideoEnded && !inProgress && sidePanel) ? 'header-right-controls--active' : ''}`}>

      <div className="curate-header__mode-switch-button">
         {isIntroVideoEnded &&

          (
            (!inProgress && !isLockedRoom) && <FadeDown variant="fade-in" delay="5s" duration="600ms">
              <CurateModeSwitch isFreeMode={!isDetailedMode} closeDetails={closeArtworkDetails} openDetails={openDetails} />
            </FadeDown>
          )
        }

        {!isIntroVideoEnded && isIntoAudioFound && <FadeDown variant="fade-in" delay={!isMobileView ? "1s" : "300ms"} duration="600ms">
          <button className={`curate-header__video-audio-control ${isIntoAudioPlaying ? 'intro-audio-active' : ''}`} onClick={onAudioButtonClick}>
            <div className={`curate-header-audio-wrapper ${isIntoAudioPlaying ? 'audio-wrapper-active' : ''}`}>
              <div className="boxContainer">
                <div className="box box1"></div>
                <div className="box box4"></div>
                <div className="box box5"></div>
              </div>
            </div>
          </button>
        </FadeDown>}
      </div>

      {isMenuVisible && <FadeDown
            variant="fade-in"
            delay={!isMobileView ? "1s" : "300ms"}
            duration="600ms"
            className="menu-fade-wrapper"
          >
            <button className={`curate-header__about ${isIntroVideoEnded && !inProgress ? 'curate-header__about--active' : ''}`} onClick={onMenuClick}>
              <span>About</span>
              <img src={AboutInfo} alt="About Info" />
            </button>
        </FadeDown>}
      </div>
      {/* {!isLockedRoom && ( */}
        <button
          className="rounded-button close-btn active"
          onClick={closeArtworkDetails}
        >
          <img src={Close} alt="close" />
        </button>
      {/* )} */}
    </header>
  );
};

export default CurateHeader;
