/* eslint-disable no-unused-expressions */
import axios from 'axios';
import React, { Component, Fragment } from 'react';
import ConfirmationPopup from "../../components/ConfirmationPopup";
import CurateComponent from '../../components/Curate';
import CurateFooter from '../../components/CurateFooter';
import { fetchCurateDetails, fetchExhibitionsDetails, fetchV2Scenes, fetchCurateLinkedArtworks } from '../../services/api';
import Audio from '../../components/Audio';
import { NURBS_API_URL } from "../../components/Constants";
import CurateView from '../../components/CurateView';
import Loader from '../../components/Loader';
import {
  constructImageUrl,
  formatDate,
  getShareURL,
  isMobileDevice,
  resetFullScreen,
  toInches,
  constructVideoUrl,
  makeFullScreen
} from '../../utils/index';
import ArtworkCarousel from '../../components/ArtworkCarousel';
import Button from '../../components/Button';
import RoomSwitchModal from '../../components/RoomSwitchModal';
import { IS_DEVELOPMENT } from '../../components/Constants';
import GTAG_EVENTS from '../../services/gtag-events';
import rudderStackEvents from '../../services/rudderstack-events';

const PARENT_HOST = "*"

const CURATE_LOAD_STATE = {
  ROOM_LOADED: 'ROOM_LOADED',
  ARTWORKS_FETCHED: 'ARTWORKS_FETCHED'
};

class Curate extends Component {
  constructor(props) {
    super(props);
    this.timeInterval = null;
    this.artworkDetailsRef = React.createRef();
    this.exhibitionLoadingScreenRef = React.createRef();
  }

  state = {
    scene: null,
    isAboutExhibition: false,
    exhibitionId: null,
    exhibitionDetails: null,
    loadingPageDetails: null,
    detailedArtworks: [],
    isArtworkList: false,
    isFullScreen: false,
    curate3DHeight: window.innerHeight,
    selectedRoom: null,
    isRoomLoading: false,
    isLoader: true,
    recentVideoArtwork: {
      id: null,
      state: false
    },
    isGuidedModeOnMobile: false,
    showFooter: true,
    isSidebar: true,
    audioPlaying: false,
    audioUrl: '',
    audioCurrentTime: 0,
    defaultArtworkId:null,
    audioTotalTime: 0,
    isAnime: false,
    pageHeight: null,
    isActive: false,
    viewportWidth: window.innerWidth,
    selectedArtwork: null,
    showRoomsBox: false,
    artworkPhotos: [],
    audioDetailsPopup: false,
    isCurateAudio: false,
    artworkDescHeight: 0,
    isArtworkDescription: false,
    isFavourite: false,
    selectedRoomId: null,
    shouldShowGuidedMode: false,
    isCollapsed: false,
    isLoadedModal: true,
    callBackOnRoomLoad: null,
    activeTooltipTitle: 0,
    activeHeader: false,
    isExhibitionExit: false,
    softArtworkId: null,
    isLockedInDetailsMode: false,
    isMobileArtwork: false,
    artworkLabel: false,
    currentMobileArtworkState: "down",
    instructed: false,
    confirmationPopupVariant: '',
    isNotDefaultRoom: false,
    isSkipButton: true,
    introVideoUrl: '',
    introVideoPoster: '',
    isLoadingScreen: true,
    isArtworkDefaultStateActive: false,
    artworkMinimizedActive: false,
    isIntroVideoEnded: true,
    curateLoadingState: [],
    isRoomSwitchModal: false,
    roomSwitchModalVariant: 'room-switch',
    isLockedRoom: false,
    isMobileArtworkExpanded: false,
    loadingProgress: 0,
    artworkAdditionalPhotos: [],
    artworkModalSelectedIndex: null,
    deviceOrientation: 'portrait',
    isIntoAudioPlaying: false,
    isIntoAudioFound: false,
  }
  setCurrentMobileArtworkState = (val) => {
    this.setState({ currentMobileArtworkState: val })
  }

  getExhibitionData = async () => {
    const exhibitionDetails = await fetchExhibitionsDetails(this.state.exhibitionId);
    if (exhibitionDetails.data) {
      let { startedAt, endedAt } = exhibitionDetails.data;
      exhibitionDetails.data.startedAt = formatDate(startedAt);
      exhibitionDetails.data.endedAt = formatDate(endedAt, true);

      rudderStackEvents.mount({exhibitionId: exhibitionDetails.data.id, galleryId: exhibitionDetails.data.organisation.data.id})

      rudderStackEvents.onExhibitionEnter();

      this.setState({
        exhibitionDetails: exhibitionDetails,
        loadingPageDetails: exhibitionDetails.data
      });
    }
  };

  // << ==== Curate functionality started ==== >>

  fetchModel = async (modelId) => {
    const modelResp = await axios({
      method: 'get',
      url: `${NURBS_API_URL}/assets/${modelId}`
    });
    return modelResp.data;
  };

  //TODO : Igal cleaning up live stream stuff for now
  onVideoStateChange = (artworkId, state) => {}

  getExhibitionSceneId = (search) => {
    search = search.slice(1).split('=')
    if (search[0] === 'sceneId') {
      return search[1]
    } else return ''
  }

  prevArrowClick = () => {
    this.state.scene.guidedNavPrev();
  }

  nextArrowClick = () => {
      this.state.scene.guidedNavNext();
  }

  getV2Scenes = async () => {
    let rooms = await fetchV2Scenes(this.state.exhibitionId).then((resp) =>
      resp.data.map((room) => ({
        label: room.data.name,
        value: room.data.id,
        isArtistsVisible: room.data.isArtistsVisible,
        isLockedRoom: room.data.isLockedInDetailsMode,
      }))
    );

    this.setState({
      rooms,
      selectedRoom: rooms[0],
      selectedRoomId: rooms[0] && rooms[0].value,
      isLockedRoom: rooms[0] && rooms[0].isLockedRoom === 1,
    });
  };

  onSoftArtworkChange = (artworkId) => {
    if (artworkId) {
      const artwork = this.state.detailedArtworks.filter(
        ({ data }) => data.id === artworkId
      );
      this.setState(
        {
          softArtworkId: artworkId,
          selectedSoftArtwork: artwork[0].data,
        }
      );
    } else {
      this.setState({
        softArtworkId: null,
      });
    }
  };

  onHoverArtworkChanged = (artworkId) => {
    if (!isMobileDevice() && window.innerWidth > 990) {
      this.onSoftArtworkChange(artworkId);
    }
  };

  onSeenArtworkChanged = (artworkId) => {};

  generateSignedUrl = (data) => {
    const { CFURL, name, signature } = data;
    return CFURL + name + signature;
  };

  generateSignedUrls = (data) => {
    const newData = { ...data };
    const { generateSignedUrl } = this;

    newData.data.model.data.signedUrl = generateSignedUrl(newData.data.model.data);
    newData.data.HDRLightMap.data.signedUrl = generateSignedUrl(newData.data.HDRLightMap.data);
    newData.data.HDRProbe.data.signedUrl = generateSignedUrl(newData.data.HDRProbe.data);
    newData.data.bundle.data.signedUrl = generateSignedUrl(newData.data.bundle.data);

    return newData;
  };

  onDoneCurateLoading = async () => {
    const {curateLoadingState} = this.state;
    if(curateLoadingState.length < 1) {
      this.setState({
        curateLoadingState: [...curateLoadingState, CURATE_LOAD_STATE.ROOM_LOADED]
      })
    }
    else {
      const assets = new Map();
      let artworks;
      const {selectedRoomId, exhibitionId} = this.state;
      const sceneId = selectedRoomId;
      console.log('sceneId = ', sceneId, this.state.scene)

      const linkedArtworks = await fetchCurateLinkedArtworks(
        exhibitionId,
          (!this.state.listview && selectedRoomId) ?? selectedRoomId,
      );
      console.log('linkedArtworks = ', linkedArtworks)

      if(linkedArtworks) {
        const artworksInCurrentRoom = linkedArtworks?.dimensions.filter(
            ({ data }) => data.exhibitionSceneId == selectedRoomId
        ).length;
        artworks = await Promise.all(
            linkedArtworks?.dimensions
                .filter(({ data }) => data.exhibitionSceneId == selectedRoomId)
                .map(async ({ data }) => {
                  // if()
                  const artworkType = data.artwork.data.type;
                  const ambientAudio = data.ambientAudio
                      ? data.ambientAudio.data?.audio?.data?.signedDesktopMedia
                      : "";
                  let fileUrl;
                  const resource =
                      artworkType === "2d"
                          ? data.artwork.data.image
                          : artworkType === "video"
                              ? data.artwork.data.videoThumbnail
                              : data.artwork.data.thumbnail;

                  const thumbnailSignedUrl = data.artwork.data.thumbnail
                      ? data.artwork.data.thumbnail.data.signedUrl
                      : null;
                  if (artworkType === "3d") {
                    if (isMobileDevice()) {
                      if (data.artwork.data.bundleId) {
                        let resp = await this.fetchModel(data.artwork.data.bundleId);
                        fileUrl = resp.data.signedUrl;
                      }
                    } else {
                      if (data.artwork.data.highBundleId) {
                        let resp = await this.fetchModel(
                            data.artwork.data.highBundleId
                        );
                        fileUrl = resp.data.signedUrl;
                      } else if (data.artwork.data.bundleId) {
                        let resp = await this.fetchModel(data.artwork.data.bundleId);
                        fileUrl = resp.data.signedUrl;
                      }
                    }
                  } else if (artworkType === "2d") {
                    fileUrl = resource.data.signedUrlLP_1024;
                  } else if (artworkType === "video") {
                    // TODO, handle in exhib details
                    fileUrl = data.artwork.data.videoThumbnail.data.signedUrl;
                  }

                  const newImage = {};
                  if (artworkType === "2d") {
                    // TODO, handle in exhib details
                    newImage["urlPNG"] = data.artwork.data.thumbnail
                        ? data.artwork.data.thumbnail.data.signedUrl
                        : null;
                  }
                  if (!!ambientAudio) {
                    // const audioElement = document.createElement("audio");
                    // audioElement.src = ambientAudio;
                    // audioElement.id = data.artworkId;
                    // audioElement.preload = "auto";
                    // audioElement.loop = true;
                    // document.getElementById('ambientAudio').appendChild(audioElement);
                  }

                  newImage["price"] =
                      data &&
                      data.artwork &&
                      data.artwork.data &&
                      data.artwork.data.price;
                  newImage['id'] = data.id;
                  // newImage["id"] = this.props.match.params.id;
                  newImage["url"] = fileUrl;
                  newImage["thumbnail"] = thumbnailSignedUrl;
                  newImage["ambientAudio"] = ambientAudio;
                  newImage["title"] = data.artwork.data.title;
                  newImage["artistName"] = data.artwork.data.artist.data.name;
                  newImage["isFavourite"] = data.artwork.data.isFavourite;
                  newImage["type"] = artworkType !== "3d" ? "2d" : "3d";
                  newImage["squareURL"] = isMobileDevice()
                      ? resource && resource.data && resource.data.signedUrl
                      : resource && resource.data && resource.data.signedUrl;
                  newImage["canvasColor"] = data.canvasColor || "#000000";
                  newImage["isCanvas"] = data.isCanvas;
                  newImage["isCollateral"] = data.isCollateral;
                  newImage["skipLightmap"] = data.skipLightmap;

                  var size = "1024sq_";

                  if (!isMobileDevice()) {
                    size = "2048sq_";
                  } else if (isMobileDevice() && artworksInCurrentRoom <= 10) {
                    size = "2048sq_";
                  } else if (
                      isMobileDevice() &&
                      artworksInCurrentRoom > 10 &&
                      artworksInCurrentRoom <= 20
                  ) {
                    size = "1024sq_";
                  } else if (
                      isMobileDevice() &&
                      artworksInCurrentRoom > 20 &&
                      artworksInCurrentRoom <= 35
                  ) {
                    size = "512sq_";
                  } else if (isMobileDevice() && artworksInCurrentRoom > 35) {
                    size = "256sq_";
                  }

                  newImage["basisURL"] = data?.artwork?.data?.basisURL
                      ? data.artwork.data.basisURL
                      : resource?.data?.CFURL +
                      size +
                      resource?.data?.name +
                      ".basis" +
                      resource?.data?.signature;
                  newImage["ktx2URL"] = resource?.data?.CFURL +
                      "mipmap_q250_" +
                      size +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;

                  if (isMobileDevice()) {
                    newImage["ktx2URL_LOW_MIPMAP"] = resource?.data?.CFURL +
                        "mipmap_q250_" +
                        size +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                    newImage["ktx2URL_HIGH_MIPMAP"] = resource?.data?.CFURL +
                        "mipmap_q250_" +
                        "2048sq_" +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                    newImage["ktx2URL_LOW"] = resource?.data?.CFURL +
                        size +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                    newImage["ktx2URL_HIGH"] = resource?.data?.CFURL +
                        "2048sq_" +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                  } else {
                    newImage["ktx2URL_LOW_MIPMAP"] =  resource?.data?.CFURL +
                        "mipmap_q250_" +
                        size +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                    newImage["ktx2URL_HIGH_MIPMAP"] = resource?.data?.CFURL +
                        "mipmap_q250_" +
                        "2048sq_" +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                    newImage["ktx2URL_LOW"] = resource?.data?.CFURL +
                        size +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                    newImage["ktx2URL_HIGH"] = resource?.data?.CFURL +
                        "2048sq_" +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;
                  }

                  newImage["previewImage"] =
                      artworkType === "2d"
                          ? resource && resource.data && constructImageUrl(resource)
                          : artworkType === "video"
                              ? constructImageUrl(data.artwork.data.videoThumbnail)
                              : constructImageUrl(data.artwork.data.thumbnail);
                  newImage["artworkId"] = data.artworkId;
                  newImage["frame"] = data.frame === "1" || data.frame === true;
                  newImage["frameBack"] =
                      data.frameBack === "1" || data.frameBack === true;
                  newImage["frameGlass"] =
                      data.frameGlass === "1" || data.frameGlass === true;
                  newImage["trackingLight"] =
                      data.trackingLight === "1" || data.trackingLight === true;
                  if (assets.has(data.asset.data.id)) {
                    newImage["asset"] = assets.get(data.asset.data.id);
                  } else {
                    assets.set(data.asset.data.id, data.asset);
                    newImage["asset"] = data.asset;
                  }
                  newImage["onFloor"] = data.onFloor;
                  newImage["lightmapURL"] = data.HDRArtworkMap
                      ? data.HDRArtworkMap.data.signedUrl
                      : null;
                  newImage["pedestalLightMap"] = data.HDRAssetMap
                      ? data.HDRAssetMap.data.signedUrl
                      : null;
                  newImage["frameSettings"] = {
                    frameGap: data.frameGap,
                    frameWidth: data.frameWidth,
                    frameDepth: data.frameDepth,
                    spotLight: data.spotLight,
                    spotLightDistance: data.spotLightDistance,
                    spotLightHeight: data.spotLightHeight,
                    spotLightSoft: data.spotLightSoft,
                    spotLightTemp: data.spotLightTemp,
                    spotLightAngle: data.spotLightAngle,
                  };
                  newImage["dimensions"] = {
                    height: parseFloat(data.artwork.data.height),
                    width: parseFloat(data.artwork.data.width),
                    depth: parseFloat(data.artwork.data.depth),
                  };
                  if (data.size) {
                    newImage["size"] = data.size;
                  }
                  if (artworkType === "video") {
                    newImage["video"] =
                        data.artwork.data.video.data.signedMobileMedia;
                  }
                  if (data.x !== null && data.y !== null && data.z !== null) {
                    newImage["visible"] = true;
                    newImage["position"] = {
                      x: data.x,
                      y: data.y,
                      z: data.z,
                    };
                    newImage["rotation"] = data.rotation;
                    newImage["axisRotation"] = data.axisRotation;
                    newImage["size"] = data.size;
                  }
                  return newImage;
                })
        );
      }

      this.state.scene && this.state.scene.loadPlacementConfig(artworks);
      this.setState({
        artworksList: artworks,
        defaultArtworkId: this.state.defaultArtworkId || artworks[0]?.artworkId,
        detailedArtworks: linkedArtworks.dimensions.map((detail) => {
          return {
            data: {
              ...detail.data.artwork.data,
              isCollateral: detail.data.isCollateral,
              exhibitionSceneId: detail.data.exhibitionSceneId,
            },
          };
        }),
      })
    }
    console.log('onDoneCurateLoading')
    //
  }

  getData = async (roomId = null, enableDemoMode = false, isRoomSwitch = false) => {
    // @ts-ignore
    const self = this;
    this.state.scene && this.state.scene.cleanup();
    let details = null;
    let data = null;
    details = await fetchCurateDetails(this.state.exhibitionId, roomId ?? this.state.selectedRoom)

    this.setState({
      defaultArtworkId: details?.dimensions && details?.dimensions[0]?.data?.id
    })
    data = details && details.models && details.models[0];
    data = this.generateSignedUrls(data);

    if (
      this.state.exhibitionDetails?.data?.version > 0 &&
      data?.hasOwnProperty("renderVersion")
    ) {
      data = {
        data: {
          ...data,
          room: {
            data: {
              renderVersion: data.renderVersion,
            },
          },
        },
      };
    } else {
      if (data?.hasOwnProperty("renderVersion")) {
        data = {
          data: {
            ...data,
            room: {
              data: {
                renderVersion: data.renderVersion,
              },
            },
          },
        };
      } else {
        data = {
          data: {
            ...data?.data,
            room: {
              data: {
                renderVersion: 0,
              },
            },
          },
        };
      }
    }
    // }
    const currentSceneId = roomId || data?.data?.data?.id || data?.data?.id;
// console.log("defArtwork",details.dimensions[0].id)
    this.setState({
      // defaultArtworkId: details?.dimensions && details?.dimensions[0]?.data?.artworkId,
      // TODO: Review this we replaced the new code but commented the old one ;) 
      // defaultArtworkId: !isRoomSwitch ? artworkId : null,
      defaultArtworkId: !isRoomSwitch ? (details?.dimensions && details?.dimensions[0]?.data?.artworkId) : null,
      selectedRoomId: currentSceneId,
    })

    data['animation_props'] = {
      intro_anim: data?.data?.data?.introAnim || data?.data?.introAnim,
      intro_anim_data: data?.data?.data?.introAnimData || data?.data?.introAnimData,
    }

    data['isLockedRoom'] = data?.data?.data?.isLockedInDetailsMode ?? data?.data?.isLockedInDetailsMode,
    this.setState({
        isLockedRoom: data['isLockedRoom'] === 1
    })

    const targetElm = document.getElementById('curate-3d-wrapper');
    if(!targetElm) {
      return;
    }
      
    var scene = new CurateView(
      "curate-3d-wrapper",
      data,
      this.loadingProgressHandler,
      this.handleClickArtwork,
      null,
      this.onVideoStateChange,
      this.closeDetails,
      this.onGuidedModeArtworkChange,
      this.onHoverArtworkChanged,
      this.onSeenArtworkChanged,
      this.onDoneCurateLoading
    ); 

    if ('parentIFrame' in window) {
      // console.log('page info = ', window.parentIFrame)
      window.parentIFrame.sendMessage('getParentHeight', PARENT_HOST)
    }
    const toneMappingSettings = {
      toneMappingExposure: data.data.exposure,
      toneMappingWhitePoint: data.data.whitePoint,
      toneMappingTemperature: data.data.temperature,
      toneMappingWhitePointLower: data.data.contrast,
      toneMappingSaturation: data.data.saturation,
    }
    scene.setTonemappingSettings(toneMappingSettings)
    // demoScene.setTonemappingSettings(toneMappingSettings)

    if (details) {

      console.log('details => ', details);
      this.setState({ introVideoUrl:  constructVideoUrl(details?.models[0]?.data?.introVideo), introVideoPoster: details?.models[0]?.data?.introVideo?.data?.signedVideoThumbnail })

      const { curateLoadingState } = this.state;
        console.log('curateLoadingState = ', curateLoadingState)
      if(curateLoadingState.length < 1) {
        this.setState({
          curateLoadingState: [...curateLoadingState, CURATE_LOAD_STATE.ARTWORKS_FETCHED]
        })
      }
      else {
        const assets = new Map();
        let artworks;
        const linkedArtworks = await fetchCurateLinkedArtworks(
            this.state.exhibitionId,
            (!this.state.listview && roomId) ?? currentSceneId,
        );
        if(linkedArtworks) {
          const artworksInCurrentRoom = linkedArtworks?.dimensions.filter(
              ({ data }) => data.exhibitionSceneId == currentSceneId
          ).length;
          artworks = await Promise.all(
              linkedArtworks?.dimensions
                  .filter(({ data }) => data.exhibitionSceneId == currentSceneId)
                  .map(async ({ data }) => {
                    // if()
                    const artworkType = data.artwork.data.type;
                    const ambientAudio = data.ambientAudio
                        ? data.ambientAudio.data?.audio?.data?.signedDesktopMedia
                        : "";
                    let fileUrl;
                    const resource =
                        artworkType === "2d"
                            ? data.artwork.data.image
                            : artworkType === "video"
                                ? data.artwork.data.videoThumbnail
                                : data.artwork.data.thumbnail;
                    // TODO, handle in exhib details
                    // thumbnail, videoThumbnail, HDRArtworkMap, HDRAssetMap

                    // New key: basisURL
                    // var size = "1024sq_";
                    //
                    // if (parseInt(data.artwork.data.width) + parseInt(data.artwork.data.height) > 100) {
                    //     size = "2048sq_";
                    // }
                    // newImage['basisURL'] = resource.data.CFURL + size + resource.data.name + ".basis" + resource.data.signature;

                    // New key: modelUrl -> artwork.data.modelUrl
                    // let resp = await this.fetchModel(data.artwork.data.bundleId);
                    // fileUrl = resp.data.signedUrl;

                    const thumbnailSignedUrl = data.artwork.data.thumbnail
                        ? data.artwork.data.thumbnail.data.signedUrl
                        : null;
                    if (artworkType === "3d") {
                      if (isMobileDevice()) {
                        if (data.artwork.data.bundleId) {
                          let resp = await this.fetchModel(data.artwork.data.bundleId);
                          fileUrl = resp.data.signedUrl;
                        }
                      } else {
                        if (data.artwork.data.highBundleId) {
                          let resp = await this.fetchModel(
                              data.artwork.data.highBundleId
                          );
                          fileUrl = resp.data.signedUrl;
                        } else if (data.artwork.data.bundleId) {
                          let resp = await this.fetchModel(data.artwork.data.bundleId);
                          fileUrl = resp.data.signedUrl;
                        }
                      }
                    } else if (artworkType === "2d") {
                      fileUrl = resource.data.signedUrlLP_1024;
                    } else if (artworkType === "video") {
                      // TODO, handle in exhib details
                      fileUrl = data.artwork.data.videoThumbnail.data.signedUrl;
                    }

                    const newImage = {};
                    if (artworkType === "2d") {
                      // TODO, handle in exhib details
                      newImage["urlPNG"] = data.artwork.data.thumbnail
                          ? data.artwork.data.thumbnail.data.signedUrl
                          : null;
                    }
                    if (!!ambientAudio) {
                      // const audioElement = document.createElement("audio");
                      // audioElement.src = ambientAudio;
                      // audioElement.id = data.artworkId;
                      // audioElement.preload = "auto";
                      // audioElement.loop = true;
                      // document.getElementById('ambientAudio').appendChild(audioElement);
                    }

                    newImage["price"] =
                        data &&
                        data.artwork &&
                        data.artwork.data &&
                        data.artwork.data.price;
                    newImage['id'] = data.id;
                    // newImage["id"] = this.props.match.params.id;
                    newImage["url"] = fileUrl;
                    newImage["thumbnail"] = thumbnailSignedUrl;
                    newImage["ambientAudio"] = ambientAudio;
                    newImage["title"] = data.artwork.data.title;
                    newImage["artistName"] = data.artwork.data.artist.data.name;
                    newImage["isFavourite"] = data.artwork.data.isFavourite;
                    newImage["type"] = artworkType !== "3d" ? "2d" : "3d";
                    newImage["squareURL"] = isMobileDevice()
                        ? resource && resource.data && resource.data.signedUrl
                        : resource && resource.data && resource.data.signedUrl;
                    newImage["canvasColor"] = data.canvasColor || "#000000";
                    newImage["isCanvas"] = data.isCanvas;
                    newImage["isCollateral"] = data.isCollateral;
                    newImage["skipLightmap"] = data.skipLightmap;

                    var size = "1024sq_";

                    if (!isMobileDevice()) {
                      size = "2048sq_";
                    } else if (isMobileDevice() && artworksInCurrentRoom <= 10) {
                      size = "2048sq_";
                    } else if (
                        isMobileDevice() &&
                        artworksInCurrentRoom > 10 &&
                        artworksInCurrentRoom <= 20
                    ) {
                      size = "1024sq_";
                    } else if (
                        isMobileDevice() &&
                        artworksInCurrentRoom > 20 &&
                        artworksInCurrentRoom <= 35
                    ) {
                      size = "512sq_";
                    } else if (isMobileDevice() && artworksInCurrentRoom > 35) {
                      size = "256sq_";
                    }

                    newImage["basisURL"] = data?.artwork?.data?.basisURL
                        ? data.artwork.data.basisURL
                        : resource?.data?.CFURL +
                        size +
                        resource?.data?.name +
                        ".basis" +
                        resource?.data?.signature;
                    newImage["ktx2URL"] = resource?.data?.CFURL +
                        "mipmap_q250_" +
                        size +
                        resource?.data?.name +
                        ".ktx2" +
                        resource?.data?.signature;

                    if (isMobileDevice()) {
                      newImage["ktx2URL_LOW_MIPMAP"] = resource?.data?.CFURL +
                          "mipmap_q250_" +
                          size +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                      newImage["ktx2URL_HIGH_MIPMAP"] = resource?.data?.CFURL +
                          "mipmap_q250_" +
                          "2048sq_" +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                      newImage["ktx2URL_LOW"] = resource?.data?.CFURL +
                          size +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                      newImage["ktx2URL_HIGH"] = resource?.data?.CFURL +
                          "2048sq_" +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                    } else {
                      newImage["ktx2URL_LOW_MIPMAP"] =  resource?.data?.CFURL +
                          "mipmap_q250_" +
                          size +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                      newImage["ktx2URL_HIGH_MIPMAP"] = resource?.data?.CFURL +
                          "mipmap_q250_" +
                          "2048sq_" +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                      newImage["ktx2URL_LOW"] = resource?.data?.CFURL +
                          size +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                      newImage["ktx2URL_HIGH"] = resource?.data?.CFURL +
                          "2048sq_" +
                          resource?.data?.name +
                          ".ktx2" +
                          resource?.data?.signature;
                    }

                    newImage["previewImage"] =
                        artworkType === "2d"
                            ? resource && resource.data && constructImageUrl(resource)
                            : artworkType === "video"
                                ? constructImageUrl(data.artwork.data.videoThumbnail)
                                : constructImageUrl(data.artwork.data.thumbnail);
                    newImage["artworkId"] = data.artworkId;
                    newImage["frame"] = data.frame === "1" || data.frame === true;
                    newImage["frameBack"] =
                        data.frameBack === "1" || data.frameBack === true;
                    newImage["frameGlass"] =
                        data.frameGlass === "1" || data.frameGlass === true;
                    newImage["trackingLight"] =
                        data.trackingLight === "1" || data.trackingLight === true;
                    if (assets.has(data.asset.data.id)) {
                      newImage["asset"] = assets.get(data.asset.data.id);
                    } else {
                      assets.set(data.asset.data.id, data.asset);
                      newImage["asset"] = data.asset;
                    }
                    newImage["onFloor"] = data.onFloor;
                    newImage["lightmapURL"] = data.HDRArtworkMap
                        ? data.HDRArtworkMap.data.signedUrl
                        : null;
                    newImage["pedestalLightMap"] = data.HDRAssetMap
                        ? data.HDRAssetMap.data.signedUrl
                        : null;
                    newImage["frameSettings"] = {
                      frameGap: data.frameGap,
                      frameWidth: data.frameWidth,
                      frameDepth: data.frameDepth,
                      spotLight: data.spotLight,
                      spotLightDistance: data.spotLightDistance,
                      spotLightHeight: data.spotLightHeight,
                      spotLightSoft: data.spotLightSoft,
                      spotLightTemp: data.spotLightTemp,
                      spotLightAngle: data.spotLightAngle,
                    };
                    newImage["dimensions"] = {
                      height: parseFloat(data.artwork.data.height),
                      width: parseFloat(data.artwork.data.width),
                      depth: parseFloat(data.artwork.data.depth),
                    };
                    if (data.size) {
                      newImage["size"] = data.size;
                    }
                    if (artworkType === "video") {
                      newImage["video"] =
                          data.artwork.data.video.data.signedMobileMedia;
                    }
                    if (data.x !== null && data.y !== null && data.z !== null) {
                      newImage["visible"] = true;
                      newImage["position"] = {
                        x: data.x,
                        y: data.y,
                        z: data.z,
                      };
                      newImage["rotation"] = data.rotation;
                      newImage["axisRotation"] = data.axisRotation;
                      newImage["size"] = data.size;
                    }
                    return newImage;
                  })
          );
        }
        scene && scene.loadPlacementConfig(artworks);
        this.setState({
          artworksList: artworks,
          defaultArtworkId: this.state.defaultArtworkId || artworks[0]?.artworkId,
          detailedArtworks: linkedArtworks.dimensions.map((detail) => {
            return {
              data: {
                ...detail.data.artwork.data,
                isCollateral: detail.data.isCollateral,
                exhibitionSceneId: detail.data.exhibitionSceneId,
              },
            };
          }),
        })
      }
      // console.log('linkedArtworks = ', linkedArtworks)
      
      this.setState(
          {
            selectedRoom: {
              label: data?.data?.data?.name
                  ? data?.data?.data?.name
                  : data?.data["name"],
              value: data?.data?.data?.id
              ? data?.data?.data?.id
              : data?.data["id"],
            },
            scene,
          },
          () => {}
      );
    }

    window.addEventListener('resize', () => this.sceneResizeListener());
  };

  handleArtworkClick = (id) => {
    // rudderstackEvents.onArtworkClick(id)
    if(this.state.listview){
        this.setArtwork2dModal(id);
        return;
    }
    this.handleArtworkCardClick(id, null);
    if(isMobileDevice() || window.innerWidth < 990) {
        this.setState({ sidePanel: false });
    }
  };

  handleClickArtwork = (artworkId, isSoftClick = false) => {
    this.setState({ isDetailedMode: !!artworkId });
    this.toggleAudioDetailsPopup(true, artworkId, false, isSoftClick)
    this.sceneResizeListener();
  }

  sendDataWithStream = (data) => {
    // this.state.liveStreamInstance.dataChannel.sendDataString(DATA_KEY + JSON.stringify(data));
  }

  handleExpand = () => {
    alert('expand')
  }

  toggleCollapse = (isCollapsed) => {
    this.setState({ isCollapsed }, () => {
      if(isCollapsed) {
        this.triggerGTagEvent(GTAG_EVENTS.EXPAND_BUTTON_CLICKED);
      }else{
        this.triggerGTagEvent(GTAG_EVENTS.COLLAPSE_BUTTON_CLICKED);
      }
    })
    if(!isCollapsed) {
      this.handleExpand();
    }
    else {
     this.handleCollapse()
    }
  }

  handleCollapse = () => {}

  loadingProgressHandler = (loadingProgress) => {
    if (loadingProgress === 100) {
      this.state.callBackOnRoomLoad &&
        this.handleArtworkCardClick(this.state.callBackOnRoomLoad, null);
      this.setState({
        callBackOnRoomLoad: null,
      });
      this.setState({
        loadingProgress: 100
      });
    } else {
      this.setState({
        loadingProgress
      });
    }
  };

  resizeCurateCanvas = () => {
    this.sceneResizeListener();
  };

  handleCurateClick = () => {
    this.resizeCurateCanvas();
  }

  sceneResizeListener = () => {
    if (this.state.scene) {
        try {
            this.state.scene.resize();
        } catch (error) {
            console.error(error);
        }
    }
  };

  openMobileArtwork = (artworkId) => {
    this.setIsMobileArtworkExpanded(true);
  }

  toggleAudioDetailsPopup = (
    audioDetailsPopup,
    artworkId,
  ) => {
    const {
      state,
    } = this;
    const { detailedArtworks } = state;

    const selectedArtwork = detailedArtworks.filter(
      (artwork) => artwork.data.id === artworkId
    );
    if (selectedArtwork[0]) {
      this.setState(
        {
          isArtworkDefaultStateActive: audioDetailsPopup,
          selectedArtwork: selectedArtwork[0],
          // isMobileArtwork: isMobileDevice(),
        }
      );
    } else {
        if (this.artworkDetailsRef?.current) {
          this.artworkDetailsRef.current.reset();
          this.stopArtworkViewMode();
        }
    }
  }

  handleArtworkCardClick = (
    artworkId,
    isUserIdUpdated,
    isGuidedModeOnMobile = false
  ) => {
    this.setState({ isDetailedMode: !!artworkId });
    this.setState({
      isGuidedModeOnMobile: isGuidedModeOnMobile,
    });
    if (window.innerWidth <= 990) {
      this.setState({ viewMap: false, isSidebar: false });
    }
    this.getCurateHeight();
    this.handleClickArtwork(artworkId, false);
    this.state.scene && this.state.scene.focusOnArtwork(artworkId);
  };

  //TODO: Embed

  handleBackToSource = () => {
    window.history.back()
  }

  // << ==== Curate functionality ended ==== >>

  getExhibitionIdFromHash = (hash) => {
    var array = hash.split("&");

    var values, form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    return form_data['e'];
  }
  // << ==== Curate functionality ended ==== >>

  getCollapsedStateFromHash = (hash) => {
    var array = hash.split("&");

    var values, form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    return form_data['collapsed'] === 'false';
  }

  getDmoModeFromHash = (hash) => {
    var array = hash.split("&");

    var values, form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    return form_data['demoMode'] === "true"
  }


  getHeightFromHash = (hash) => {
    var array = hash.split("&");

    var values, form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    return form_data['h'];
  }

  capitalizeFirstLetter = (string) => {
    return `${' | ' + string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()}`;
  };

  setFullScreen = (val) => {
    const { audioDetailsPopup } = this.state;
    const poweredByNone = this.props.hideVorticBranding;
    const headerHeight = 0;
    const footerHeight = document.querySelector('.curate-footer')?.clientHeight || 0;
    const artworkDetails = document.querySelector('.bottom-details')?.clientHeight || 0;
    const total = headerHeight + ( poweredByNone ? 0 : footerHeight ) + (audioDetailsPopup ? artworkDetails : 0);


    this.setState({ isFullScreen: val }, () => {
      if (this.state.isFullScreen) {
        this.setState({ curate3DHeight: window.innerHeight - (total) }, () => {
          this.resizeCurateComponents();
          this.resizeCurateCanvas();
        })
      }
      else {
        this.setState({ curate3DHeight: window.innerHeight - (total) }, () => {
          this.resizeCurateCanvas();
          this.resizeCurateComponents();
        })
      }
    })
    this.triggerGTagEvent(GTAG_EVENTS.FULLSCREEN_BUTTON_CLICKED);
  }

  resizeCurateComponents = () => {
    this.resizeCurateCanvas();
  }

  handleRoomSelection = (value) => {
    this.handleHeaderBtnClick(false, false, false, false, false, false, false);
    this.closeDetails(false);
    this.setState({ sidePanel: false, curateLoadingState: [] });
    const newRooms = [...this.state.rooms];
    const selected = newRooms.filter((room) => room.value == value);
    if (!!this.state.scene && this.state.recentVideoArtwork.id) {
      this.state.scene.setVideoArtworkStateByID &&
        this.state.scene.setVideoArtworkStateByID(
          this.state.recentVideoArtwork.id,
          false
        );
    }
    this.setState(
      {
        introVideoUrl: '',
        introVideoPoster: '',
        audioDetailsPopup: false,
        isArtworkDefaultStateActive: false,
        artworkMinimizedActive: false,
        isIntroVideoEnded: true,
        isRoomLoading: true,
        loadingProgress: 0,
        selectedRoom: selected[0],
        exitScreen: false,
        isRoomSwitchModal: false,
      },
      () => {
        this.getData(value, true, true);
      }
    );
  };

  onGuidedModeArtworkChange = (artwork3DId, next, fromSwipe) => {
    const { isLockedRoom } = this.state;
    const curateArtworkId =
      !this.state.instructed && !isLockedRoom
        ? this.state.defaultArtworkId
        : artwork3DId;

    if (isLockedRoom) {
      this.state.scene && this.state.scene.focusOnArtwork(curateArtworkId);
    }
    if (this.state.audioDetailsPopup || this.state.isArtworkDefaultStateActive) {
      this.toggleAudioDetailsPopup(true, curateArtworkId);
    }
    if (!this.state.instructed) {
      this.setState(
        {
          instructed: true,
          isDetailedMode: isLockedRoom,
          artworkLabel: true
        },
        () => {
          if (isLockedRoom) {
            this.setArtworkScene(
              curateArtworkId,
              null
            );
            this.toggleAudioDetailsPopup(true, curateArtworkId);
          }
        }
      );
    }
  };

  closeDetails = (received) => {
    // This prevents the close event to be fired if the 3d view is open from artwork carousel
    if (this.state.instructed) {
      // Stops the Guided Detailed Mode when detailed Mode gets false
      this.state.scene.setGuidedNavMode(false);
      this.setState({ isDetailedMode: received, isArtworkDefaultStateActive: received, audioDetailsPopup: received }, () => {
        this.getCurateHeight();

        rudderStackEvents &&
        rudderStackEvents.onArtworkDetail(
          this.state.selectedArtwork?.id,
          "close_artwork_view"
        );
        this.toggleAudioDetailsPopup(false, null);
        this.setState({
          isGuidedModeOnMobile: false,
        });
        this.resizeCurateCanvas();

        if (this.state.scene) {
          this.state.scene.stopViewMode();
        }
      });
      this.focusCanvas();
    }
  };

  closeDetailsOnMobile = (received) => {
    this.toggleAudioDetailsPopup(false, null);
    this.setState({
      isGuidedModeOnMobile: false,
    });
    this.sceneResizeListener();
    this.focusCanvas();
  };

  loadExhibitionDetails = async () => {
    this.resizeCurateComponents();
  };

  handleAudioChange = (val) => {
    this.setState({isCurateAudio: val});
    this.resizeCurateCanvas();
  }

  stopAlreadyPlayingVideo = () => {
    const videoElement = document.getElementById("hiddenVideoElement");
    if (videoElement) {
      videoElement.pause();
    }
  };

  handleExit = () => {
    // TODO: Need to review :) 
    this.props.handleExit && this.props.handleExit()
    this.stopAlreadyPlayingVideo();
    this.props.history.push("/exhibitions")
  }

  focusCanvas = () => {
    const curateWrapper = document.getElementById("curate-3d-wrapper");
    curateWrapper.querySelector('canvas')?.focus();
  }

  getCurateHeight = () => {
    const { audioDetailsPopup } = this.state;
    const poweredByNone = this.props.hideVorticBranding;
    const viewHeight = window.innerHeight;
    const curateHeader = 0;
    const curateFooter = document.querySelector('.curate-footer')?.clientHeight || 0;

    if (audioDetailsPopup) {
      const bottomGap = document.querySelector('.bottom-details')?.clientHeight || 0;

      if (isMobileDevice() || window.innerWidth <= 990) {
        const artworkDetailsPillHeight = document.getElementById('mobile-artwork-details-pill')?.clientHeight || 0; // here - 16 is to cancel the negative margin top on pill
        const artworkDetailsTitleHeight = document.getElementById('curate-mobile-artwork-title')?.clientHeight || 0;
        const artworkTitleTooltipHeight = (document.querySelector('.curate-mobile-artwork')?.clientHeight || 0) + 13; // here + 13 is to add the gap between bottom of the screen and the controls tooltip
        const totalHeight = viewHeight - (artworkTitleTooltipHeight + artworkDetailsTitleHeight + artworkDetailsPillHeight + (poweredByNone ? 0 : 49)); // where 49 is the footers height 
        this.setState({curate3DHeight: totalHeight}, () => {
            this.resizeCurateCanvas();
        })
      }
      else {
        this.setState({ curate3DHeight: viewHeight - (bottomGap + curateFooter) }, () => {
          this.resizeLoop()
        })
      }
    }
    else {
      if(isMobileDevice() || window.innerWidth <= 990) {
        this.setState({ curate3DHeight: (viewHeight - curateFooter) }, () => {
          this.resizeCurateCanvas();
        })
      } else {
        this.setState({ curate3DHeight: (viewHeight - (curateHeader + curateFooter)) }, () => {
          this.resizeCurateCanvas();
        })
      }
    }
  }

  handleDescription = () => {
    if (this.state.artworkDescHeight !== 0 && this.state.isArtworkDescription === true) {
      this.setState({ curateHeight: 1 });
    }
  };

  toggleDraggable = (val) => {
    if (val === true) {
      this.setState({ audioDetailsPopup: true, isAudioPopupFull: true });
    } else {
      this.setState({ audioDetailsPopup: false, isAudioPopupFull: false });
      if (this.state.scene) {
        this.state.scene.stopViewMode();
      }
    }
  };

  handleAudioDetailsPopup = (val) => {
    this.setState({ audioDetailsPopup: val })
  }

  handleHeaderBtnClick = (
    viewDesc,
    viewMap,
    viewEvent,
    viewAuth,
    viewComment,
    enquire = false,
    isSidebar = true
  ) => {
    this.setState(
      {
        viewDesc: viewDesc,
        viewMap: viewMap,
        viewEvent: viewEvent,
        isSidebar,
        enquire,
      },
      () => {
        this.resizeCurateCanvas();
      }
    );
  };

  onArtworkClick = (isSidebar) => {
    this.setState(
      {
        isSidebar: isSidebar,
        viewMap: false,
        viewDesc: false,
        viewEvent: false,
      },
      () => {
        this.resizeCurateCanvas();
      }
    );
    if (this.state.scene) {
      this.state.scene.stopViewMode();
    }
  };

  handleFullScreenView = () => {
      if (!this.state.isFullScreen) {
          const elm = document.querySelector('#root');
          makeFullScreen(elm)
      } else {
          resetFullScreen();
      }
      this.setFullScreen(!this.state.isFullScreen);

  }
  exitFullScreenIframe = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage('exitFullScreen', PARENT_HOST)
    }
  }
  enterFullScreenIframe = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage('enterFullScreen', PARENT_HOST)
    }
  }

  handleCollapedClass = () => {
      if ('parentIFrame' in window) {
        window.parentIFrame.size(this.state.pageHeight)
      }
      document?.querySelector('html')?.classList?.remove('isCollapsed');
      document?.querySelector('body')?.classList?.remove('isCollapsed');
      document?.querySelector('#root')?.classList?.remove('isCollapsed');
      document?.querySelector('.curate-view-wrapper')?.classList?.remove('isCollapsed');
      document?.querySelector('#root .h-full')?.classList?.remove('isCollapsed');
      document?.querySelector('.exhibition-loading .exhibition-loading-container')?.classList?.remove('isCollapsed');
      this.triggerGTagEvent(GTAG_EVENTS.COLLAPSE_BUTTON_CLICKED);
      setTimeout(() => {
        this.resizeCurateComponents();
        this.exitFullScreenIframe();
      }, 0)
  }

  getArtworkDetails = (artworkId) => {
    const artwork = this.state.detailedArtworks.filter(({data}) => data.id === artworkId);
    if(artwork && artwork.length > 0) {
      return {
        artworkTitle: artwork[0].data.title,
        artistName: artwork[0].data.artist.data.name,
        artworkId: artwork[0].data.id
      }
    }
    else {
      return null;
    }
  }
  triggerGTagEvent = (event, data={}) => {
    // console.log('triggerGTagEvent = ', data)
    if(window.dataLayer) {
      window.dataLayer.push({
        event: event,
        eventProps: {
          ...data,
          exhibitionId: this.state.exhibitionId
        }
      });
    }
  }

  componentWillUnmount() {
    console.log("curate component unmount");

    this.state.scene && this.state.scene.cleanup();
    this.state.scene = null;
    this.curateRef = null;
    this.child = null;

    try {
      window.removeEventListener('resize', this.sceneResizeListener);
     
    } catch (error) {
        console.error(error);
    }
  }

  async componentDidMount() {
    this.handleCollapedClass();
    let enableDemoMode = false;
    const hash = window.location.hash;
    const tempHash = hash.replace('#', '');
    const exhibitionId = this.getExhibitionIdFromHash(tempHash);
    // const isCollapsed = this.getCollapsedStateFromHash(tempHash);
    // TODO: Collapsed false by default
    const isCollapsed = false;
    if(isCollapsed) {
      this.setState({isCollapsed: false});
    }
    const pageHeight = this.getHeightFromHash(tempHash) | null;
    enableDemoMode = !!this.getDmoModeFromHash(tempHash);
    this.setState({ exhibitionId, pageHeight, enableDemoMode }, async () => {
      await this.getExhibitionData();
      await this.getV2Scenes();
      this.getData(this.state.selectedRoomId, enableDemoMode);
      
      this.getCurateHeight();
      window.addEventListener('resize', () => {
        this.resizeLoop();
        this.sceneResizeListener();
        this.setState(({ viewportWidth: window.innerWidth }), () => {
          this.resizeCurateComponents();
        })
        this.getCurateHeight();
      });
    }, () => {
      this.handleCollapedClass();
    });
  }



  resetGuidedMode = () => {
    this.setState((prevState) => {
        return {
            restartTour: prevState.restartTour + 1
        }
    });
    this.triggerGTagEvent(GTAG_EVENTS.RESTART_GUIDED_TOUR)
  }

  setArtworkModal = (val) => {
    this.setState({isArtworkModal: val})
  }

  componentDidUpdate(prevProps, prevStates) {
    
    if (prevStates.selectedRoom !== this.state.selectedRoom) {
      this.setState({isLoadedModal: true, isLoader: true});
      this.getCurateHeight();
    }
    if (prevStates.audioDetailsPopup !== this.state.audioDetailsPopup) {
      this.getCurateHeight();
      this.resizeLoop();
    }
    if ((prevStates.audioUrl !== this.state.audioUrl) && (isMobileDevice() || window.innerWidth <= 769)) {
      this.getCurateHeight();
    }
    if (prevStates.loadingProgress !== this.state.loadingProgress) {

      if(this.state.loadingProgress > 99) {
        this.state.scene.setGuidedNavMode(true);
        this.getCurateHeight();
        this.resizeCurateCanvas();
      }
      if(this.state.loadingProgress > 0 && this.state.loadingProgress < 100) {
        if(this.state.isDetailedMode || this.state.instructed) {
          this.setState({isDetailedMode: false, instructed: false});
        }
      }
    }
    if((prevStates.sidePanel !== this.state.sidePanel) && !isMobileDevice()) {
      this.resizeLoop();
    }
    if(prevStates.selectedArtwork?.data?.id !== this.state.selectedArtwork?.data?.id && this.state.audioDetailsPopup){
      this.getCurateHeight();
      this.sceneResizeListener();
    }
  }

  setArtworkScene = (artworkId, isUserIdUpdated) => {
    this.state.scene && this.state.scene.focusOnArtwork(artworkId);
  };

  handleSelectedArtwork = (val, photos, index) => {
    this.setState({ artworkAdditionalPhotos: photos, artworkModalSelectedIndex: index, isArtworkModal: val });
  };

  handleAuthTabOpen = () => {
      this.handleHeaderBtnClick(false, false, false, true, false)
  }

  getRoomDetails = () => {
      const {rooms, selectedRoomId} = this.state;
      const room = rooms?.filter( ({value}) => value === selectedRoomId);
      return room ? room[0] : {};
  }

  resizeLoop = () => {
    const interval = setInterval(() => {
        this.resizeCurateCanvas();
    }, 50)

    const timeout = setTimeout(() => {
        clearInterval(interval);
        clearTimeout(timeout);
    }, 1500)
  }

  handleHelp = () => {
    if (isMobileDevice() || window.innerWidth <= 990) {
      this.setState({ sidePanel: false });
    }
  };

  putArtworkInDetailsModeFromMiniDetails = (artworkId) => {
    if (artworkId) {
      this.handleArtworkCardClick(artworkId, null);

    }
  };
  
  stopArtworkViewMode = () => {
    if (this.state.scene) {
      this.state.scene.stopViewMode();
    }
  };

  setArtworkPhotos = (val) => {
    this.setState({artworkPhotos: val})
  }

  setIsMobileArtworkExpanded = (val) => {
    if (this.state.instructed) {

      this.setState({ isMobileArtworkExpanded: val });
    }
  };

  setConfirmationPopupVariant = (val = "") => {
      this.setState({ confirmationPopupVariant: val });
  }

  handleMenuClick = (val = null) => {
    this.setState({ sidePanel: val ? val : !this.state.sidePanel }, () => {
      this.resizeLoop();
    });
  };

  exhibitionLoadingScreenOnComplete = () => {
    const {isArtwork2dModal, defaultArtworkId} = this.state;
    if(!isArtwork2dModal){
      this.handleArtworkCardClick(defaultArtworkId);
      setTimeout(() => {
        // if(isMobileDevice()) {
          this.setState({
            isLoadingScreen: false,
            isRoomLoading: false,
          })
        // }
      }, 1500)
    }
  }

  handleIntroVideo = (val) => {
    this.setState({ isIntroVideoEnded: val })
  }

  toggleRoomsModal = (val, variant = 'room-switch') => {
    this.setState({ roomSwitchModalVariant: variant,  isRoomSwitchModal: val, sidePanel: false, audioDetailsPopup: false }, () => {
      this.artworkDetailsRef.current?.togglePlaying(false)
    });
  }

  getCurateArtworkAddress = () => {
    const { exhibitionDetails, selectedArtwork, selectedRoomId } = this.state;
    const exhibitionSlug = exhibitionDetails?.data?.slug;
    const exhibitionId = exhibitionDetails?.data?.id;
    const selectedArtworkId = selectedArtwork && selectedArtwork?.data?.id;
    return `https://vortic.art/exhibitions/${exhibitionSlug || exhibitionId}?sceneId=${selectedRoomId}&artworkId=${selectedArtworkId}`;
  };

  openDetailedMode = () => {
    const { detailedArtworks } = this.state;
    const artworkId = detailedArtworks[0].data.id;
    this.handleArtworkClick(artworkId);
  }

  render() {
    const {
      isAboutExhibition,
      loadingProgress,
      exhibitionDetails,
      isArtworkList,
      loadingPageDetails,
      isFullScreen,
      curate3DHeight,
      isRoomLoading,
      selectedRoom,
      isLoader,
      audioPlaying,
      audioUrl,
      audioCurrentTime,
      audioTotalTime,
      isAnime,
      isActive,
      exhibitionId,
      viewportWidth,
      selectedArtwork,
      showRoomsBox,
      showFooter,
      isArtwork2dModal,
      artworkPhotos,
      isAudioPopupFull,
      audioDetailsPopup,
      selectedRoomId,
      detailedArtworks,
      isCollapsed,
      isArtworkModal,
      artworkModalSelectedIndex,
      activeTooltipTitle,
      sidePanel,
      activeHeader,
      isMobileArtworkExpanded,
      isDetailedMode,
      artworkLabel,
      isLockedInDetailsMode,
      currentMobileArtworkState,
      selectedSoftArtwork,
      softArtworkId,
      confirmationPopupVariant,
      isRoomSwitchModal,
      isIntroVideoEnded,
      isArtworkDefaultStateActive,
      artworkMinimizedActive,
      rooms,
      roomSwitchModalVariant,
      isLockedRoom,
      isCurateAudio,
      artworkAdditionalPhotos
    } = this.state;
    const {
      handleCurateClick,
      setFullScreen,
      onArtworkClick,
      getRoomDetails,
      handleHelp,
      prevArrowClick,
      nextArrowClick,
      setArtworkPhotos,
      handleSelectedArtwork,
      setIsMobileArtworkExpanded,
      closeDetails,
      handleArtworkClick,
      setCurrentMobileArtworkState,
      putArtworkInDetailsModeFromMiniDetails,
      setConfirmationPopupVariant,
      handleMenuClick,
      exhibitionLoadingScreenOnComplete,
      handleIntroVideo,
      toggleRoomsModal,
      handleExit,
      openDetailedMode,
      handleAudioDetailsPopup
    } = this;

    const viewDesc = isAboutExhibition;
    const viewMap = isArtworkList;
    const isOldExhibition = this.state.exhibitionDetails?.data?.isOld;
    const exhibitionSlug = exhibitionDetails?.data?.slug;
    const selectedArtworkId = selectedArtwork && selectedArtwork?.data?.id;

    const dimensionsInCM = `${parseFloat(selectedArtwork && selectedArtwork?.data?.height).toFixed(1)} x ${parseFloat(selectedArtwork && selectedArtwork?.data?.width).toFixed(1)} x ${parseFloat(selectedArtwork && selectedArtwork?.data?.depth).toFixed(1)} cm`;
    const dimensionsInIN = `${toInches(selectedArtwork && selectedArtwork?.data?.height)} x ${toInches(selectedArtwork && selectedArtwork?.data?.width)} x ${toInches(selectedArtwork && selectedArtwork?.data.depth)} in`;
    const galleryName = loadingPageDetails && loadingPageDetails.organisation && loadingPageDetails.organisation.data && loadingPageDetails.organisation.data.name;

    const poweredByNone = this.props.hideVorticBranding;

    return (
      <div className={"curate-view-wrapper" + (isFullScreen ? ' active' : '') + (audioDetailsPopup ? '' : '') + ((isMobileDevice()) && isCollapsed ? ' curate-view-wrapper--loading' : '')}>
        {!isRoomLoading && isMobileDevice() && isCollapsed &&
          <div className='mobile-loader-wrapper'>
              {loadingProgress < 100 && <Loader className={loadingProgress < 100 ? '' : ' active'} secondary progress={loadingProgress} galleryName={galleryName} />}
              <div className={'button-wrapper' + (loadingProgress < 100 ? '' : ' active')}>
                <Button
                    className="start-tour cube"
                    value="Enter 3D Viewing Room"
                    iconType={"cube"}
                    onClick={() =>
                      this.setState({ setFullScreen: true }, () => {
                      this.setState({isCollapsed: false}, () => {this.handleCollapedClass(); this.enterFullScreenIframe(); this.handleFullScreenView()})
                    })}
                    type="secondary"
                />
              </div>
          </div>
        }
        
        {
          !!confirmationPopupVariant &&
          <ConfirmationPopup
            variant={confirmationPopupVariant}
            handleCancel={() => setConfirmationPopupVariant()}
            handleConfirm={() => console.log('handle confirmation!')}
          />
        }

        <CurateComponent
          exhibitionSlug={exhibitionSlug}
          poweredByNone={poweredByNone}
          selectedArtworkId={selectedArtworkId}
          artworkDetailsRef={this.artworkDetailsRef}
          closeDetails={closeDetails}
          putArtworkInDetailsModeFromMiniDetails={putArtworkInDetailsModeFromMiniDetails}
          isLoader={isLoader}
          showFooter={showFooter}
          isAboutExhibition={isAboutExhibition}
          handleCurateClick={handleCurateClick}
          selectedArtwork={selectedArtwork}
          exhibitionDetails={exhibitionDetails}
          isArtworkList={isArtworkList}
          setViewDesc={(val) => this.setState({ isAboutExhibition: val })}
          setViewMap={(val) => this.setState({ isArtworkList: val })}
          curate3DStyle={{ height: curate3DHeight }}
          loadingProgress={loadingProgress}
          isRoomLoading={isRoomLoading}
          rooms={this.state.rooms}
          handleRoomSelection={this.handleRoomSelection}
          selectedRoom={selectedRoom}
          roomName={selectedRoom && selectedRoom['label']}
          loadingPageDetails={loadingPageDetails}
          exhibitionId={exhibitionId}
          isSidebar={isAboutExhibition || isArtworkList}
          viewportWidth={viewportWidth}
          selectedRoomId={selectedRoomId}
          onArtworkClick={onArtworkClick}
          detailedArtworks={detailedArtworks}
          isCollapsed={isCollapsed}
          handleBackToSource={this.handleBackToSource}
          activeTooltipTitle={activeTooltipTitle}
          showRoomsBox={showRoomsBox}
          handleSidePanelClose={() => this.setState({ sidePanel: false }, () => {
            this.resizeLoop();
          })}
          isOldExhibition={isOldExhibition}
          roomId={selectedRoomId}
          handleHelp={handleHelp}
          sidePanel={sidePanel}
          activeHeader={activeHeader}
          isMobileArtworkExpanded={isMobileArtworkExpanded}
          handleMenuClick={handleMenuClick}
          isDetailedMode={isDetailedMode}
          closeArtworkDetails={() => {this.closeDetails(false); this.artworkDetailsRef.current?.togglePlaying(false)}}
          selectedSoftArtwork={selectedSoftArtwork}
          softArtworkId={softArtworkId}
          prevArrowClick={prevArrowClick}
          nextArrowClick={nextArrowClick}
          artworkLabel={artworkLabel}
          isLockedInDetailsMode={isLockedInDetailsMode}
          setArtworkPhotos={setArtworkPhotos}
          artworkPhotos={artworkPhotos}
          handleSelectedArtwork={handleSelectedArtwork}
          handleMobileArtworkLeft={() => this.state.scene.guidedNavPrev()}
          handleMobileArtworRight={() => this.state.scene.guidedNavNext()}
          mobileArtworkExhibitionId={
            exhibitionDetails?.data?.uuid ||
            exhibitionDetails?.data?.slug
          }
          setIsMobileArtworkExpanded={setIsMobileArtworkExpanded}
          handleArtworkClick={handleArtworkClick}
          currentState={currentMobileArtworkState}
          setCurrentState={setCurrentMobileArtworkState}
          dimensionsInCM={dimensionsInCM}
          dimensionsInIN={dimensionsInIN}
          audioDetailsPopup={audioDetailsPopup}
          isDateVisible={exhibitionDetails?.data?.isDateVisible}
          isArtistVisible={exhibitionDetails?.data?.isArtistVisible}
          isArtwork2dModal={isArtwork2dModal}
          isVideoEnabled={exhibitionDetails?.data?.isVideoEnabled}
          imageUrl={constructImageUrl(exhibitionDetails?.data?.image, "1080")}
          poster={constructImageUrl(exhibitionDetails?.data?.thumbnail, "480")}
          posterUrl={this.state.introVideoPoster}
          videoUrl={this.state.introVideoUrl}
          progress={Math.floor(loadingProgress)}
          galleryName={exhibitionDetails?.data.organisation?.data?.name}
          exhibitionName={exhibitionDetails?.data?.name}
          description={(exhibitionDetails?.data?.description.trim() !== "") ? exhibitionDetails?.data?.description : ""}
          setActiveHeader={() => this.setState({activeHeader: true})}
          skipAnimation={() => this.state?.scene?.skipIntroGuidedAnimation()}
          isLoadingScreen={this.state.isLoadingScreen}
          exhibitionLoadingScreenOnComplete={exhibitionLoadingScreenOnComplete}
          isIntroVideoEnded={isIntroVideoEnded}
          handleIntroVideo={handleIntroVideo}
          isRoomSwitchModal={isRoomSwitchModal}
          toggleRoomsModal={toggleRoomsModal}
          isArtworkDefaultStateActive={isArtworkDefaultStateActive}
          artworkMinimizedActive={artworkMinimizedActive}
          handleActiveMinimizedProp={(val) => this.setState({ artworkMinimizedActive: val })}
          handleToggleArtworkDetailedView={(val) => this.setState({ audioDetailsPopup: val })}
          resizeCurateCanvas={() => this.resizeCurateCanvas()}
          closeOnGuidingModeMobile={() => this.closeDetailsOnMobile()}
          setCurateAudio={(val) => {
            this.handleAudioChange(val)
          }}
          handleArtworkDescription={(val, height) => {
            this.setState({
                isArtworkDescription: val,
                artworkDescHeight: height
            });
              this.handleDescription();
          }}      
          audioPlaying={audioPlaying}    
          audioUrl={audioUrl}  
          audioCurrentTime={audioCurrentTime}
          audioTotalTime={audioTotalTime}
          isAnime={isAnime}
          setAudioUrl={(val) => {
            if(!!val) {
              this.setState({audioUrl: val})
            }
          }}
          setCurrentTime={(val) => this.setState({audioCurrentTime: val})}
          setTotalTime={(val) => this.setState({audioTotalTime: val})}
          setIsAnime={(val) => this.setState({isAnime: val})}
          setPlaying={(val) => this.setState({audioPlaying: val})}
          setArtwork2dModal={this.setArtwork2dModal}
          isActive={isActive}
          setActive={(val) => this.setState({isActive: val})}
          getCurateHeight={this.getCurateHeight}
          hideVorticBranding={this.props.hideVorticBranding}
          viewMap={viewMap}
          viewDesc={viewDesc}
          isAudioPopupFull={isAudioPopupFull}
          closeAudioDetails={() => this.setState({ audioDetailsPopup: false })}
          isLockedRoom={isLockedRoom}
          isCurateAudio={isCurateAudio}
          openDetails={openDetailedMode}
          handleAudioDetailsPopup={handleAudioDetailsPopup}
          handleArtworkDescriptionForDesktop={(val, height) => {
            this.setState({
              isArtworkDescription: val,
              artworkDescHeight: height,
            });
            this.handleDescription();
          }}
          isArt={exhibitionDetails?.data?.isArt}
          chainLink={this.getCurateArtworkAddress()}
          setIntroAudioPlaying={() => this.setState({isIntoAudioPlaying: !this.state.isIntoAudioPlaying})}
          setIsIntoAudioFound={(value) => this.setState({isIntoAudioFound: value})}
          isIntoAudioFound={this.state.isIntoAudioFound}
          exhibitionLoadingScreenRef={this.exhibitionLoadingScreenRef}
          onAudioButtonClick={() => this.exhibitionLoadingScreenRef.current.toggleAudio()}
          isIntoAudioPlaying={this.state.isIntoAudioPlaying}
        />
         
        {audioUrl && audioUrl !== "" && (
          <Audio
            // @ts-ignore
            setTotalTime={(val) => this.setState({ audioTotalTime: val })}
            setCurrentTime={(val) => (val) => this.setState({ audioCurrentTime: val })}
            url={audioUrl}
            play={audioPlaying}
          />
        )}

        <div id="garbageVideo" className={"garbage-audio-video"}>
          <video crossOrigin="anonymous" src={""} id={"hiddenVideoElement"}
            style={{ opacity: 0, zIndex: -3, width: '0px', height: '0px' }}>
          </video>
        </div>
        
        <div id="ambientAudio" className={"garbage-audio-video"}>

        </div>

        { !poweredByNone && showFooter && <CurateFooter isRoomLoading={isRoomLoading} loadingProgress={loadingProgress} timeOutOnFullScreen={() => {this.setState({ isCollapsed: false }, () => this.handleCollapedClass())}} isMobileDevice={isMobileDevice()} setFullScreen={setFullScreen} isFullScreen={isFullScreen}
          isCollapsed={isCollapsed}
        /> }

        {isArtworkModal && <ArtworkCarousel
          artworkPhotos={artworkAdditionalPhotos}
          dimensionsInCM={dimensionsInCM}
          dimensionsInIN={dimensionsInIN}
          artworkMedium={selectedArtwork && selectedArtwork.data.medium}
          handleClose={() => this.setArtworkModal(false)}
          shareUrl={getShareURL("artwork", selectedArtworkId)}
          isSidebar={false}
          onFavourite={() => {}}
          chainLink={this.getCurateArtworkAddress()}
          selectedIndex={artworkModalSelectedIndex}
        />}

        {
          this.state.isRoomSwitchModal &&
          <RoomSwitchModal
            selectedRoomId={selectedRoomId}
            onClose={() => toggleRoomsModal(false)}
            rooms={rooms}
            handleExitExhibition={handleExit}
            handleRoomSelection={(val) => this.handleRoomSelection(val)}
            roomSwitchModalVariant={roomSwitchModalVariant}
          />
        }

      </div>
    )
  }
}

export default Curate;
