import React, { useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import { constructImageUrl, getArtworkStateStatus, getIdFromSlug, isMobileDevice, getShareURL } from "../../utils";
import CurateHeader from "../CurateHeader";
import CurateMobileArtwork from "../CurateMobileArtwork";
import FadeDown from "../FadeDown";
import IconBtn from "../IconBtn";
import SidePanel from "../SidePanel";
import RoomLoader from "../RoomLoader";
import ExhibitionCurateLoading from "../../components/ExhibitionCurateLoading";
import ArtworksAudioDetails from "../../components/ArtworksAudioDetails";

const Curate = ({
  selectedArtwork,
  isAboutExhibition,
  showFooter,
  handleCurateClick,
  exhibitionDetails,
  isArtworkList,
  curate3DStyle,
  galleryName,
  isRoomLoading,
  loadingProgress,
  roomName,
  rooms,
  selectedRoom,
  isGuiding,
  audioDetailsPopup,
  loadingPageDetails,
  exhibitionId,
  isSidebar,
  viewportWidth,
  detailedArtworks,
  isCollapsed,
  showRoomsBox,
  poweredByNone,
  handleSidePanelClose,
  isOldExhibition,
  roomId,
  handleHelp,
  sidePanel,
  activeHeader,
  isDetailedMode,
  closeArtworkDetails,
  isMobileArtworkExpanded,
  prevArrowClick,
  nextArrowClick,
  artworkLabel,
  isLockedInDetailsMode,
  dimensionsInCM,
  dimensionsInIN,
  artworkPhotos,
  handleSelectedArtwork,
  handleMobileArtworkLeft,
  handleMobileArtworRight,
  mobileArtworkExhibitionId,
  setIsMobileArtworkExpanded,
  handleArtworkClick,
  currentState,
  setCurrentState,
  artworkDetailsRef,
  selectedArtworkId,
  closeDetails,
  putArtworkInDetailsModeFromMiniDetails,
  softArtworkId,
  selectedSoftArtwork,
  isDateVisible,
  isArtistVisible,
  isArtwork2dModal,
  skipAnimation,
  setActiveHeader,
  posterUrl,
  videoUrl,
  progress,
  isLoadingScreen,
  exhibitionLoadingScreenOnComplete,
  handleMenuClick,
  isIntroVideoEnded,
  handleIntroVideo,
  isRoomSwitchModal,
  toggleRoomsModal,
  isArtworkDefaultStateActive,
  artworkMinimizedActive,
  handleActiveMinimizedProp,
  handleToggleArtworkDetailedView,
  resizeCurateCanvas,
  closeOnGuidingModeMobile,
  setCurateAudio,
  handleArtworkDescription,
  audioPlaying,
  audioUrl,
  setAudioUrl,
  setPlaying,
  audioCurrentTime,
  audioTotalTime,
  isAnime,
  setCurrentTime,
  setTotalTime,
  setIsAnime,
  setArtwork2dModal,
  setArtworkPhotos,
  isActive,
  setActive,
  getCurateHeight,
  hideVorticBranding,
  viewMap,
  viewDesc,
  isAudioPopupFull,
  closeAudioDetails,
  selectedRoomId,
  isLockedRoom,
  isCurateAudio,
  openDetails,
  handleAudioDetailsPopup,
  handleArtworkDescriptionForDesktop,
  chainLink,
  isArt = true,
  isIntoAudioFound,
  setIsIntoAudioFound,
  setIntroAudioPlaying,
  onAudioButtonClick,
  isIntoAudioPlaying,
  exhibitionLoadingScreenRef
}) => {

  const history = useHistory();
  return (
    <div
      className={`curate-view ${!poweredByNone ? "with-footer" : ""} ${isMobileDevice() ? ` curate-view--mobile ${poweredByNone ? 'poweredByNone' : ''}` : ""
        } ${isGuiding && showFooter ? "curate-view--with-footer" : ""} ${isMobileDevice() && !!selectedArtwork ? "curate-view--mobile-view" : ""
        } ${isCollapsed ? "curate-view--collapsed" : ""} ${audioDetailsPopup ? "curate-view--artwork-details-open" : ""
        } ${isGuiding &&
          window.innerWidth <= 990 &&
          isMobileDevice() &&
          !!selectedArtwork
          ? "guided-artwork-details-active"
          : ""
        }`}
    >
      <div
        className={`curate-grid ${sidePanel ? "curate-grid--active" : ""}`}
        style={curate3DStyle}
      >
        <div
          onClick={handleCurateClick}
          id="curate-3d-wrapper"
          className={`${isDetailedMode || audioDetailsPopup ? "curate-3d-wrapper--detailed-mode" : ""} 
          ${isMobileArtworkExpanded ? 'curate-3d-wrapper--mobile-detailed-mode' : ''}
          ${isLockedInDetailsMode ? "locked-mode" : ""}`}
        >
          {!isCollapsed && activeHeader && (
            // @ts-ignore
            <CurateHeader
              isListView={false}
              isLockedRoom={isLockedRoom}
              isMobileArtworkExpanded={isMobileArtworkExpanded}
              rooms={rooms}
              showRoomsBox={showRoomsBox}
              isRoomLoading={isRoomLoading && loadingProgress < 100}
              handleExit={() => toggleRoomsModal(true, 'exit-room')}
              inProgress={loadingProgress < 99}
              onMenuClick={handleMenuClick}
              isDetailedMode={isDetailedMode || audioDetailsPopup}
              closeArtworkDetails={() => closeArtworkDetails(false)}
              sidePanel={sidePanel}
              isMenuVisible={true}
              isIntroVideoEnded={isIntroVideoEnded}
              onRoomsClick={toggleRoomsModal}
              isRoomSwitchModal={isRoomSwitchModal}
              openDetails={openDetails}
              onAudioButtonClick={onAudioButtonClick}
              isIntoAudioPlaying={isIntoAudioPlaying}
              isIntoAudioFound={isIntoAudioFound}
            />
          )}

          {(isLoadingScreen || isRoomLoading) && (
            <ExhibitionCurateLoading
              isVideoEnabled={exhibitionDetails?.data?.isVideoEnabled}
              imageUrl={constructImageUrl(exhibitionDetails?.data?.image, "1080")}
              // poster={constructImageUrl(exhibitionDetails?.data?.thumbnail, "480")}
              posterUrl={posterUrl}
              videoUrl={videoUrl}
              progress={progress}
              galleryName={exhibitionDetails?.data.organisation?.data?.name}
              timeLine={`${exhibitionDetails?.data?.startedAt || ""} ${(exhibitionDetails?.data?.endedAt && " - " + (loadingPageDetails && loadingPageDetails.endedAt)) || ""}`}
              exhibitionName={exhibitionDetails?.data?.name}
              description={(exhibitionDetails?.data?.description.trim() !== "") ? exhibitionDetails?.data?.description : ""}
              setActiveHeader={setActiveHeader}
              skipAnimation={skipAnimation}
              roomName={rooms?.length > 1 && roomName}
              loadingOnComplete={exhibitionLoadingScreenOnComplete}
              handleMoreClick={() => handleMenuClick(true)}
              isSidePanel={sidePanel}
              isIntroVideoEnded={isIntroVideoEnded}
              setIsIntroVideoEnded={handleIntroVideo}
              descriptionHtml={
                exhibitionDetails?.data?.descriptionHtml
              }
              isRoomSwitchModal={isRoomSwitchModal}
              poweredByNone={poweredByNone}
              setIntroAudioPlaying={setIntroAudioPlaying}
              setIsIntoAudioFound={setIsIntoAudioFound}
              isIntoAudioFound={isIntoAudioFound}
              // @ts-ignore
              ref={exhibitionLoadingScreenRef}
            />
          )}

          {artworkLabel && (!isMobileDevice() || window.innerWidth > 990) && (!isDetailedMode && !isLockedInDetailsMode) &&
            !audioDetailsPopup &&
            (
              <p
                className={`paragraph curate-artworkLabel ${softArtworkId ? 'active' : ''}`}
              >
                {selectedSoftArtwork?.artist?.data?.name || ""},{" "}
                <i>{selectedSoftArtwork?.title || ""}</i>{" "}
                <span>{selectedSoftArtwork?.yearCreated}</span>
              </p>
            )}


          {loadingProgress > 99 &&
            <FadeDown delay="100ms">
              <CurateMobileArtwork
                // @ts-ignore
                poweredByNone={poweredByNone}
                // @ts-ignore
                isSingleArtworkRoom={detailedArtworks?.length === 1}
                isArt={isArt}
                sidePanel={sidePanel}
                listview={false}
                ref={artworkDetailsRef}
                medium={selectedArtwork && selectedArtwork?.data?.medium}
                artworkCatalogueNumber={selectedArtwork && selectedArtwork?.data.artworkCatalogueNumber}
                artistName={selectedArtwork && selectedArtwork?.data?.artist && selectedArtwork.data.artist?.data?.name}
                artworkName={selectedArtwork && selectedArtwork?.data?.title}
                subtitle={selectedArtwork && selectedArtwork?.data?.medium}
                yearCreated={selectedArtwork && selectedArtwork?.data?.yearCreated}
                description={selectedArtwork && selectedArtwork?.data?.description}
                galleryName={selectedArtwork && selectedArtwork?.data?.organisation?.data?.name}
                price={selectedArtwork && selectedArtwork?.data?.price}
                currency={selectedArtwork && selectedArtwork?.data?.currency}
                dimensionsInCM={dimensionsInCM}
                dimensionsInIN={dimensionsInIN}
                setArtworkModal={handleSelectedArtwork}
                handleLeftClick={handleMobileArtworkLeft}
                handleRightClick={handleMobileArtworRight}
                closeDetails={() => closeDetails(false)}
                isFavourite={false}
                exhibitiondId={exhibitionId}
                isLoggedIn={false}
                prevArtwork={handleMobileArtworkLeft}
                nextArtwork={handleMobileArtworRight}
                handleEnquire={() => { }}
                exhibitionId={mobileArtworkExhibitionId}
                isMobileArtwork={isArtworkDefaultStateActive && !audioDetailsPopup && !isRoomSwitchModal}
                // TODO: This might cause the trouble with re-renderings .... 
                // artworkId={(isMobileDevice() || window.innerWidth) <= 990 && selectedArtwork && selectedArtwork?.data?.id}
                artworkId={selectedArtwork && selectedArtwork?.data?.id}
                handleLogin={() => { }}
                excludedTax={selectedArtwork && selectedArtwork?.data?.excludedTax}
                isForSale={getArtworkStateStatus(
                  selectedArtwork?.data?.salesStatus,
                  selectedArtwork?.data?.isForSale
                )}
                isActive={isMobileArtworkExpanded}
                setIsActive={setIsMobileArtworkExpanded}
                isLockedInDetailsMode={isLockedInDetailsMode}
                currentState={currentState}
                setCurrentState={setCurrentState}
                putArtworkInDetailsMode={putArtworkInDetailsModeFromMiniDetails}
                toggleModalAuth={() => { }}
                artworkMinimizedActive={artworkMinimizedActive}
                activeMinimizedProp={artworkMinimizedActive}
                handleActiveMinimizedProp={handleActiveMinimizedProp}
                handleToggleArtworkDetailedView={handleToggleArtworkDetailedView}
                audioDetailsPopup={audioDetailsPopup}
                isCurateAudio={isCurateAudio}
                resizeCurateCanvas={resizeCurateCanvas}
                onCloseDesktopDetails={() => handleAudioDetailsPopup(false)}
                viewportWidth={viewportWidth}
                handleArtworkDescriptionForDesktop={handleArtworkDescriptionForDesktop}
                getCurateHeight={getCurateHeight}
                priceUnavailable={
                  (selectedArtwork &&
                    selectedArtwork?.data?.salesStatus ===
                      "price on application") ||
                  selectedArtwork?.data?.salesStatus === "sold" ||
                  selectedArtwork?.data?.salesStatus === "reserved"
                }
                chainLink={chainLink}
              />
            </FadeDown>
          }
        </div>

        <div className={`side-panel-curate-wrapper ${sidePanel ? 'side-panel-curate-wrapper--active' : ''} ${poweredByNone ? 'poweredByNone' : ''}`}>
          <SidePanel
            sidePanel={sidePanel}
            exhibitionSlug={exhibitionDetails?.data?.slug}
            id={exhibitionId}
            galleryName={galleryName}
            aboutTitle={exhibitionDetails?.data?.name}
            // @ts-ignore
            imgUrl={constructImageUrl(exhibitionDetails?.data?.image, 1080)}
            state={exhibitionDetails}
            exhibitionuuId={exhibitionDetails?.data?.uuid}
            collateralDetails={exhibitionDetails?.data?.collateral}
            timeline={`${exhibitionDetails?.data?.startedAt || ""} - ${exhibitionDetails?.data?.endedAt
              }`}
            artworks={detailedArtworks}
            handleArtworkClick={handleArtworkClick}
            onClose={handleSidePanelClose}
            handleViewListClick={() => {
              window.open(`https://vortic.art/exhibitions/${exhibitionDetails?.data?.uuid || exhibitionDetails?.data?.slug}?listview=true`)
            }}
            isOldExhibition={isOldExhibition}
            roomId={roomId}
            handleHelpClick={handleHelp}
            isDateVisible={isDateVisible}
            isArtistVisible={isArtistVisible}
            isCurateLoading={loadingProgress < 100}
            isArt={isArt}
          />
        </div>
      </div>
      {isRoomLoading && loadingProgress < 100 && (
        <RoomLoader
          border={isSidebar}
          isArtwork2dModal={isArtwork2dModal}
          progress={loadingProgress}
          title={loadingPageDetails && loadingPageDetails.name}
          roomName={selectedRoom && selectedRoom["label"]}
        />
      )}
    </div>
  );
};

export default Curate;
