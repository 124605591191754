import React, { useState, useEffect, useRef, useImperativeHandle, Fragment } from "react";
import classNames from "classnames";
import { Label, Paragraph } from "../Typography2";
import AudioIcon from "../../assets/icons/audio.svg";
import ExpandDetailsIcon from "../../assets/icons/expand-details.svg";
import ArrowLeft from "../../assets/icons/arrow-left-bold.svg";
import ArrowRight from "../../assets/icons/arrow-right-bold.svg";
import CloseIcon from "../../assets/icons/close-bold.svg";
import "./style.scss";
import { ICurateMobileArtwork } from "../CurateMobileArtwork/types";
import CurateMobileArtworkDetails from "../CurateMobileArtworkDetails";
import { constructImageUrl, isMobileDevice } from "../../utils";
import { fetchArtworkDetails, fetchArtworkMedia } from "../../services/api";
import { motion, useDragControls } from "framer-motion/dist/framer-motion";
import AudioPlayer from '../AudioPlayer';
import Audio from '../Audio';
import FadeDown from "../FadeDown";
import ArtworksAudioDetails from "../../components/ArtworksAudioDetails";
import IconBtn from '../IconBtn';

const VIEW_HEIGHT = window.innerHeight;
const INITIAL_DRAG_CONSTRAINTS = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
};

const CurateMobileArtwork: React.FC<ICurateMobileArtwork> = React.forwardRef(
  ({
    onClick,
    handleLeftClick,
    handleRightClick,
    onClose,
    artistName,
    artworkName,
    yearCreated,
    medium,
    reference,
    price,
    description,
    artworkId,
    setArtworkModal,
    exhibitionId,
    dimensionsInIN,
    dimensionsInCM,
    excludedTax,
    currency,
    isNotMuseum,
    isForSale,
    isActive,
    setIsActive,
    isLockedInDetailsMode,
    // setTooltipBottom,
    currentState,
    setCurrentState,
    isMobileArtwork,
    putArtworkInDetailsMode,
    isMuseumType,
    activeMinimizedProp,
    handleActiveMinimizedProp,
    handleToggleArtworkDetailedView,
    audioDetailsPopup,
    isCurateAudio,
    sidePanel,
    listview,
    galleryName,
    exhibitiondId,
    artworkCatalogueNumber,
    resizeCurateCanvas,
    onCloseDesktopDetails,
    viewportWidth,
    handleLoginForDesktop,
    handleArtworkDescriptionForDesktop,
    getCurateHeight,
    priceUnavailable,
    isArt = true,
    chainLink,
    isSingleArtworkRoom
}, ref) => {

  const [MAIN_HEIGHT, setMainHeight] = useState(0);
  const artworkDetailsRef = useRef<any>(null);
  const artworkDetailsCompRef = useRef<any>(null);
  // Frammer states
  const constraintsRef = useRef<any>(null);
  const dragControls = useDragControls();
  const [dragDirection, setDragDirection] = useState(null);
  const [key, setKey] = useState(0);
  const [activeted, setActivated] = useState(false);
  const [minimzeHeight, setMinimizeHeight] = useState<any>(null);
  const [maximizeHeight, setMaximizeHeight] = useState<any>(null);
  const [defaultHeight, setDefaulHeight] = useState(null);
  const [isDraggable, setIsDraggable] = useState(true);
  const [isFullViewMode, setFullViewMode] = useState(false);
  const [descriptionHeight, setDescriptionHeight] = useState(null);
  const [isScrollActive, setIsScrollActive] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [isPlaying, setPlaying] = useState(false);
  const [isAnime, setIsAnime] = useState(false);
  const [currentTime, setCurrentTime] = useState('0');
  const [totalDuration, setTotalDuration] = useState('0');
  const [dragConstraints, setDragConstraints] = useState(INITIAL_DRAG_CONSTRAINTS);
  const [positionY, setPositionY] = useState(window.innerHeight);
  const [artworkPhotos, setArtworkPhotos] = useState([]);
  const [horizontalScroll, setHorizontalScroll] = useState(false);
  const [originalMaximumHeight, setOriginalMaximumHeight] = useState(0);
  const [isAboveViewPortTop, setIsAboveViewPortTop] = useState(false);
  const isMobileView = isMobileDevice() || window.innerWidth <= 990;
  const [desktopDetailedArrowsOffsetY, setDesktopDetailedArrowsOffsetY] = useState(0);
  const AudioRef = useRef<any>(null);
  const HorizontalScrollTextRef = useRef<any>(null);
  const HorizontalScrollTextWrapperRef = useRef<any>(null);
  const desktopExpandedDetailsRef = useRef<any>(null);
  const controllerRef = useRef<any>(new AbortController());

  useEffect(() => {
    const footerHeight = document.querySelector('.curate-footer')?.clientHeight || 0;
    setMainHeight(VIEW_HEIGHT - footerHeight)
  }, [])

  const handleDragEnd = (event) => {
    if(dragDirection === "down") {
      const element:any = document.querySelector('.frammer-custom-item');
      const rect = element.getBoundingClientRect();
      const gapFromTop = rect.top - window.scrollY;
      if(!isAboveViewPortTop) {
        setPositionY(MAIN_HEIGHT - minimzeHeight);
      }
      if(gapFromTop >= 0) {
        setIsAboveViewPortTop(false)
      }
      // const bottom:any = document.querySelector('.curate').clientHeight - dragConstraints.bottom;
      // setTooltipBottom(bottom);
    } else {
      setPositionY(MAIN_HEIGHT - maximizeHeight);
      const element:any = document.querySelector('.frammer-custom-item');
      const rect = element.getBoundingClientRect();
      const gapFromTop = rect.top - window.scrollY;
      if(gapFromTop < -5) {
        setIsAboveViewPortTop(true)
      }
      // const top = document.querySelector('.curate').clientHeight - dragConstraints.top;
      // setTooltipBottom(top + 2);
    }
    setKey(key+1)
  };

  useEffect(() => {
    if(isMobileView){
      if(positionY === 0) {
        setFullViewMode(true)
      } else {
        setFullViewMode(false)
      }
    }
  }, [positionY])

  const handleDrag = (event, info) => {
    if(!activeted) {
      setActivated(true)
    }
    if (info.offset.y > 0) {
      // @ts-ignore
      setDragDirection("down");
    } else if (info.offset.y < 0) {
      // @ts-ignore
      setDragDirection("up");
    }
  };

  const handleMouseDown = (e) => {
    dragControls.start(e, { snapToCursor: false })
    setActivated(true);
  }

  useEffect(() => {
    if(isMobileView && (minimzeHeight || originalMaximumHeight)) {
      setDragConstraints({
        top: MAIN_HEIGHT - originalMaximumHeight,
        left: 0,
        right: 0,
        bottom: MAIN_HEIGHT - (isAboveViewPortTop ? MAIN_HEIGHT : minimzeHeight)
      })
    }
  }, [minimzeHeight, originalMaximumHeight, isAboveViewPortTop])

  useEffect(() => {
    if(isMobileView && artworkDetailsRef.current && artworkId) {
      if(artworkDetailsRef.current.clientHeight + 110 <= window.innerHeight){
        setMaximizeHeight(artworkDetailsRef.current.clientHeight + 120);
      } else {
        // @ts-ignore
        setMaximizeHeight(window.innerHeight)
      }
      setOriginalMaximumHeight(artworkDetailsRef.current.clientHeight + 120);
    }
  }, [artworkDetailsRef, artworkDetailsRef.current, artworkId, artworkPhotos])

  useEffect(() => {
    if(isMobileView){
      if(isActive && defaultHeight) {
        setPositionY(MAIN_HEIGHT - defaultHeight);
      } else {
        setPositionY(MAIN_HEIGHT);
      }
    }
  }, [isActive, defaultHeight, artworkId])

  // * End

  useEffect(() => {
    if(isMobileView && !artworkId && !isLockedInDetailsMode) {
      handleClose();
    }
  }, [artworkId, isLockedInDetailsMode])

  const loadAudioDetails = async () => {
    // Cancel any ongoing requests
    controllerRef.current.abort();
    controllerRef.current = new AbortController();
  
    try {
      let resp = await fetchArtworkMedia(exhibitionId, artworkId, { signal: controllerRef.current.signal });
      if(resp?.data[0]?.data?.channel?.data?.mediaType === "audio" && !!resp?.data[0]?.data?.channel?.data?.audio) {
        setAudioUrl(resp.data[0].data.channel.data?.audio?.data?.signedUrl);
      } else if (resp.data && resp.data[0] && resp.data[0].data && resp.data[0].data.channel && resp.data[0].data.channel.data && resp.data[0].data.channel.data.media && resp.data[0].data.channel.data.media.data) {
          setAudioUrl(resp.data[0].data.channel.data.media.data.signedUrl);
      } else {
        setAudioUrl('');
      }
    } catch (error: any) {
      if (error?.name === 'AbortError') {
        // Request was aborted, do nothing
        return;
      }
      console.error("Error loading audio details:", error);
      setAudioUrl('');
    }
  };

  useEffect(() => {
    if(artworkId && !!exhibitionId){
      setAudioUrl('');
      loadAudioDetails();
    } else {
      setAudioUrl('');
    }
    
    return () => {
      // Cleanup function to cancel any ongoing requests when component unmounts
      controllerRef.current?.abort();
    };
  }, [artworkId, exhibitionId]); 

    useEffect(() => {
      const viewHeightHalf = window.innerHeight / 2;
      const expandedDetailsHeightHalf = desktopExpandedDetailsRef.current?.clientHeight / 2;
      setDesktopDetailedArrowsOffsetY(viewHeightHalf - (expandedDetailsHeightHalf + 24)); // 24 is the radius of the arrow button
    }, [desktopExpandedDetailsRef.current, desktopExpandedDetailsRef.current?.clientHeight])

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleClose = () => {
    if(!isLockedInDetailsMode) {
      handleCloseforUnlocked();
    } else {
      setCurrentState("down");
    }
  };

  const handleMinimize = () => {
    if(!isLockedInDetailsMode){
      setCurrentState("minimize");
    } else {
      setCurrentState("down");
    }
  }

  const handleCloseforUnlocked = () => {
    setIsActive(false);

    setTimeout(() => {
      setCurrentState("down");
    }, 500);

    if(artworkDetailsCompRef.current) {
      // @ts-ignore
      artworkDetailsCompRef.current?.restart();
    }

    // onClose();
  }
  // Additional Images fetching

  const loadArtwork = async () => {
    try {
      if (artworkId) {
        const resp = await fetchArtworkDetails(artworkId);
        const tempPhotos = [];
        resp?.data?.photos?.data?.map((item, index) => {
          // @ts-ignore
          tempPhotos.push({
            small: constructImageUrl(item, "120"),
            large: constructImageUrl(item, "1920"),
            selected: index === 0,
          });
        });
        setArtworkPhotos(tempPhotos);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Swiper Funtionality

  const handleSwipe = (val, isContent = false) => {
    if(val === "down") {
      handleMinimize();
      return;
    }
    if(isContent) {
      if(isActive && currentState === "up") {
        setCurrentState(val);
      }
      else {
        if(val === "up") {
          setCurrentState(val);
        } else {
          const elm = document.querySelector(".mobile-artwork-content--dragger");
          // @ts-ignore
          if(elm.scrollTop === 0) {
            setCurrentState(val);
          }
        }
      }
    } else {
      if(val === "up") {
        setCurrentState(val);
      }
    }
  };

  const onLeftArrowClick = () => {
    handleLeftClick();
  }

  const onRightArrowClick = () => {
    handleRightClick();
  }

  const togglePlaying = (val) => {
    AudioRef?.current?.togglePlay(val);
    setPlaying(val);
  }

  useEffect(() => {
    setArtworkPhotos([]);
    if (artworkId) {
        loadArtwork();
    }
  }, [artworkId])

  useEffect(() => {
    const wrapperWidth =  HorizontalScrollTextWrapperRef.current.clientWidth;
    const textWidth =  HorizontalScrollTextRef.current.clientWidth;
    if(textWidth > wrapperWidth) {
      setHorizontalScroll(true);
      const widthDiff = textWidth - wrapperWidth;
      setVariableWidth(widthDiff+20);
    } else {
      setHorizontalScroll(false);
    }
  }, [artworkId])

  const setVariableWidth = (val) => {
    const doc = document.documentElement;
    doc.style.setProperty('--horizontalScrollText', `${-val}px`);
  }

  useImperativeHandle(
    ref,
    () => ({
        reset: () => {
          handleSwipe("down");
        },
        close: () => {
          handleCloseforUnlocked();
        },
        open: () => {
          handleSwipe("up");
        },
        togglePlaying: (val) => {
          togglePlaying(val);
        }
    }),
  );

  const handleClickOnMiniDetails = () => {
      if(isMobileDevice() || window.innerWidth <= 990){
        handleOpen();
        putArtworkInDetailsMode(artworkId);
      } else {
        handleToggleArtworkDetailedView(true);
      }
  }

  const handleCloseArtworkDetails = () => {
    handleClose()
  }

  const handleNextBtnClick = (e) => {
    handleRightClick();
    if(!activeMinimizedProp){
      handleActiveMinimizedProp(true);
    }
  }

  const handleMinimizedPrev = (e) => {
    handleLeftClick();
  }

  const handleMinimizedNext = (e) => {
    handleRightClick();
  }

  return (
    <>
    {(window.innerWidth >= 990 || !isMobileDevice()) && !isSingleArtworkRoom && (
        <IconBtn
          type={
            isMobileDevice() && window.innerWidth <= 550
              ? "mobilePrevIcon"
              : "chevronLeft"
          }
          style={{ top: `${desktopDetailedArrowsOffsetY}px` }}
          className={`tour-control-button active detailed-mode-arrow make-left ${audioDetailsPopup ? 'detailed-mode-arrow-fade-in' : ''}`}
          onClick={onLeftArrowClick}
        />
    )}

    {(window.innerWidth >= 990 || !isMobileDevice()) && !isSingleArtworkRoom && (
        <IconBtn
          type={
            isMobileDevice() && window.innerWidth <= 550
              ? "mobilePrevIcon"
              : "chevronRight"
          }
          style={{ top: `${desktopDetailedArrowsOffsetY}px` }}
          className={`tour-control-button active detailed-mode-arrow next ${audioDetailsPopup ? 'detailed-mode-arrow-fade-in' : ''}`}
          onClick={onRightArrowClick}
        />)
    }
    <div
      className={`curate-mobile-artwork-wrapper ${isActive ? "opened" : ""}`}
    >
      <div className={`curate-mobile-artwork ${isMobileArtwork ? 'visible' : ''} ${isActive ? "active" : ""} ${activeMinimizedProp ? 'active-minimized' : ''} ${audioDetailsPopup ? 'curate-mobile-artwork--details-opened' : ''} `}>
        <div className="curate-mobile-artwork-content">
          <button className="round-btn btn-left" onClick={handleMinimizedPrev}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={ArrowLeft} alt="arrow" />
            </div>
          </button>
          <div className="curate-mobile-artwork-content__left" onClick={handleClickOnMiniDetails}>
            <div ref={HorizontalScrollTextWrapperRef}>
              <Label value={artistName || ""} className="artwork-main-title" />
              <p className="label artwork-main-title artwork-name" >
                  <span className={horizontalScroll ? "bounce" : ""} ref={HorizontalScrollTextRef}>{artworkName}, {yearCreated}</span>
              </p>
            </div>
          </div>
          {/* <div className="curate-mobile-artwork-content__controls"> */}
          <div className={`curate-mobile-artwork-content__controls ${isSingleArtworkRoom ? 'curate-mobile-artwork-content__controls--single-artwork-room' : ''}`}>
            {!!audioUrl && <FadeDown variant="fade-in">
              <AudioPlayer
                isPlaying={isPlaying}
                audioUrl={audioUrl}
                currentTime={currentTime}
                totalDuration={totalDuration}
                setIsPlaying={togglePlaying}
                isMinimizedVariant={true}
              />
              </FadeDown>}
              <button className="round-btn expand-details-btn" onClick={handleClickOnMiniDetails}>
                <div className="svg-wrapper">
                  <img className="btn-icon" src={ExpandDetailsIcon} alt="arrow" />
                </div>
              </button>
              <button className={`round-btn btn-next ${activeMinimizedProp ? 'btn-next--active' : ''}`} onClick={handleNextBtnClick}>
                <Paragraph value='Next' />
                <div className="svg-wrapper">
                  <img className="btn-icon" src={ArrowRight} alt="arrow" />
                </div>
              </button>
            </div>
        </div>
        <div className={`controls`}>
          <button className="round-btn" onClick={onLeftArrowClick}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={ArrowLeft} alt="arrow" />
            </div>
          </button>
          {!isLockedInDetailsMode && <button className="round-btn round-btn-white" onClick={handleCloseArtworkDetails}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={CloseIcon} alt="close" />
            </div>
          </button>}
          <button className="round-btn" onClick={onRightArrowClick}>
            <div className="svg-wrapper">
              <img className="btn-icon" src={ArrowRight} alt="arrow" />
            </div>
          </button>
        </div>
      </div>
      {!!audioUrl && (
        <Audio
          ref={AudioRef}
          // @ts-ignore
          setTotalTime={(val) => setTotalDuration(val)}
          setCurrentTime={(val) => setCurrentTime(val)}
          setPlaying={togglePlaying}
          url={audioUrl}
          play={isPlaying}
        />
      )}
      {
        (isMobileDevice() || window.innerWidth <= 990) ?
        <motion.div
        className="frammer-custom-container"
        ref={constraintsRef}
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
      >
        <motion.div
          className="frammer-custom-item"
          drag={isDraggable ? "y" : null}
          onDrag={handleDrag}
          style={{ touchAction: "none" }}
          dragConstraints={dragConstraints}
          onDragEnd={handleDragEnd}
          animate={{ y: positionY }}
          transition={{ duration: 0.30 }} // Add transition property for animation
          // dragElastic={0.1}
          dragElastic={0}
          dragMomentum={false}
        >
          {/* @ts-ignore */}
          <CurateMobileArtworkDetails
            ref={artworkDetailsCompRef}
            setDraggable={setIsDraggable}
            isDraggable={isDraggable}
            isScrollActive={false}
            isLockedInDetailsMode={isLockedInDetailsMode}
            // @ts-ignore
            setMinimizeHeight={(val) => setMinimizeHeight(val)}
            // @ts-ignore
            setDefaulHeight={(val) => setDefaulHeight(val)}
            // @ts-ignore
            // setMaximizeHeight={(val) => setMaximizeHeight(val)}
            artworkDetailsRef={artworkDetailsRef}
            artistName={artistName}
            artworkName={artworkName}
            yearCreated={yearCreated}
            medium={medium}
            reference={reference}
            price={price}
            description={description}
            artworkPhotos={artworkPhotos}
            setArtworkModal={setArtworkModal}
            isPlaying={isPlaying}
            audioUrl={audioUrl}
            currentTime={currentTime}
            totalDuration={totalDuration}
            isAnime={isAnime}
            setPlaying={setPlaying}
            setIsAnime={setIsAnime}
            artworkId={artworkId}
            dimensionsInIN={dimensionsInIN}
            dimensionsInCM={dimensionsInCM}
            excludedTax={excludedTax}
            chainLink={chainLink}
            isArtworkListedForSale={isForSale && isNotMuseum}
            // @ts-ignore
            isMuseumType={isMuseumType}
            currency={currency}
            isActive={isActive}
            // @ts-ignore
            setDescriptionHeight={setDescriptionHeight}
            // @ts-ignore
            descriptionHeight={descriptionHeight}
            togglePlaying={togglePlaying}
          />
        </motion.div>
      </motion.div> 
      : 
      <Fragment>
        {
          <div className={classNames(
              "bottom-details",
              "draggable-box",
              { "bottom-details--sidepanel-active": sidePanel },
              // { "bottom-details--2dModel": isArtwork2dModal },
              // { "mobile-room-box": showRoomsBox },
              // { "full-width": audioDetailsPopup && !viewMap && !viewDesc && !viewEvent && !enquire && !isSidebar },
              { "full-width": audioDetailsPopup && !sidePanel },
              // { "half": isSidebar },
              { "half": sidePanel },
              { "active": audioDetailsPopup },
              // { "full": isAudioPopupFull }
            )}
            id="draggable-div"
            ref={desktopExpandedDetailsRef}
          >
            <ArtworksAudioDetails
              listview={listview}
              name={galleryName}
              isMuseumType={isMuseumType}
              closeDetails={handleCloseArtworkDetails}
              artworkId={artworkId}
              exhibitiondId={exhibitiondId}
              play={false}
              isNotMuseum={isNotMuseum}
              isForSale={isForSale}
              priceUnavailable={priceUnavailable}
              artworkMedium={medium}
              // @ts-ignore
              artworkCatalogueNumber={artworkCatalogueNumber}
              artistName={artistName}
              artworkName={artworkName}
              yearCreated={yearCreated}
              description={description}
              price={price}
              currency={currency}
              dimensionsInCM={dimensionsInCM}
              dimensionsInIN={dimensionsInIN}
              resizeCurateCanvas={resizeCurateCanvas}
              onClose={onCloseDesktopDetails}
              audioDetailsPopup={audioDetailsPopup}
              viewportWidth={viewportWidth}
              handleLogin={handleLoginForDesktop}
              setCurateAudio={(val) => {
                // TODO: Need to look into this ;) 
                // this.handleAudioChange(val);
              }}
              handleArtworkDescription={handleArtworkDescriptionForDesktop}
              isSidebar={sidePanel}
              // TODO: Need to look into this ;) 
              audioPlaying={false}
              // TODO: Need to look into this ;) 
              audioUrl={audioUrl}
              // TODO: Need to look into this ;) 
              audioCurrentTime={'0'}
              audioTotalTime={'0'}
              isAnime={isAnime}
              setIsAnime={() => {}}
              artworkPhotos={artworkPhotos}
              isActive={isActive}
              setActive={(val) => {}}
              setArtworkModal={setArtworkModal}
              getCurateHeight={getCurateHeight}
              excludedTax={excludedTax}
              // Audio props
              isPlaying={isPlaying}
              totalDuration={totalDuration}
              currentTime={currentTime}
              togglePlaying={togglePlaying}
              setCurrentTime={setCurrentTime}
              isArt={isArt}
              chainLink={chainLink}
            />
          </div>
        }
        </Fragment>
      }
    </div>
    </>
  );
});

export default CurateMobileArtwork;
