import React, { useState } from "react";
import ArticleBox from '../../components/Article';
import { fetchExhibitionArticles, fetchExhibitionArtists, fetchExhibitionCurator, fetchOrganisationsCurators } from "../../services/api";
import { constructImageUrl } from "../../utils";
import Card from "../Card";
import FeatureCardList from "../FeatureCardList";

const URL = 'https://vortic.art';

export default function useReadMoreData(uuid) {
    const [articles, setArticles] = useState([]);
    const [artists, setArtists] = useState([]);
    const [exhibitionCurators, setExhibitionCurators] = useState<any>(null);
    const [organisationCurator, setOrganisationCurators] = useState<any>(null);
    

    const handleGalleryClick = (galleryId, isOrganisation = false) => {
        const link = `${URL}/${isOrganisation ? 'organisations' : 'galleries'}/${galleryId}`
        // @ts-ignore
        window.open(link, "_blank").focus();
    };

    const handleArtistClick = (artistId) => {
        // @ts-ignore
        window.open(`${URL}/artists/${artistId}`, "_blank").focus();
    };

    const handleCuratorClick = (curatorId) => {
        // @ts-ignore
        window.open(`${URL}/curators/${curatorId}`, "_blank").focus();
    };

    const getGallery = (organisation, isOrganisation = false, isListview = false) => {
        const Img = constructImageUrl(organisation?.data?.image, '720');
        return (
            <>
                <Card
                    imgSrc={organisation?.data?.image && Img}
                    title={organisation?.data?.name}
                    description={organisation?.data?.overview}
                    location={organisation?.data?.locations}
                    onClick={() => handleGalleryClick(organisation?.data?.slug || organisation?.data?.id, isOrganisation)}
                    curator={isOrganisation}
                    listviewGallery={isListview}
                />
            </>
        );
    }

    const getOrganisationCurators = () => {
        return (
            <div className="curators-wrapper">
                {
                    // @ts-ignore
                    organisationCurator?.length > 0 && organisationCurator.map((item, index) => {
                        const { data } = item;
                        if (data?.title || data?.description || data.image) {
                            return (
                                <Card
                                    key={index}
                                    imgSrc={constructImageUrl(data.image, '720')}
                                    title={data.title}
                                    description={data.description}
                                    onClick={() => handleCuratorClick(data.uuid || data.slug || data.id)}
                                    curator
                                />
                            )
                        }
                    })
                }
            </div>
        );
    }

    const getExhibitionCurator = (isReadMore = false) => {
        return (
            <div className="curators-wrapper">
                {
                    // @ts-ignore
                    exhibitionCurators?.length > 0 && exhibitionCurators.map((exhibitionCurator) => {
                        return (
                                <Card
                                    imgSrc={constructImageUrl(exhibitionCurator?.data?.curator?.data?.image, '720')}
                                    title={exhibitionCurator?.data?.curator?.data?.title}
                                    description={exhibitionCurator?.data?.curator?.data?.description}
                                    onClick={() => handleCuratorClick(exhibitionCurator?.data?.curator?.data?.uuid || exhibitionCurator?.data?.curator?.data?.slug || exhibitionCurator?.data?.curator?.data?.slug)}
                                    curator={true}
                                    curatorReadMore={isReadMore}
                                />
                        )
                    })
                }
            </div>
        );
    }

    const getCollertals = () => {
        return (
            <>
                {articles?.length > 0 && <div className="articles-wrapper" >
                    <div className="articles">
                        {
                            articles?.map((article, index) => {
                                // @ts-ignore
                                const { title, author, writtenDate, id } = article.data;
                                return (
                                    <React.Fragment key={index}>
                                        <ArticleBox
                                            articleTitle={title}
                                            id={id}
                                            icon={false}
                                            authorName={author} date={writtenDate} />
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>}
            </>
        );
    }

    const getArtists = () => {
        return <>
            {artists.length > 0 && artists.map((artist, index) => {
                // @ts-ignore
                const Img = constructImageUrl(artist?.data?.image, '720');
                return (
                    <React.Fragment key={index}>
                        <Card
                            // @ts-ignore
                            imgSrc={artist.data.image && Img} title={artist.data.name} description={artist.data.description} onClick={() => handleArtistClick(artist.data.slug || artist.data.id)}
                        />
                    </React.Fragment>
                )
            })} </>
    }

    const loadOrganizationCurators = async (id) => {
        if (id) {
            return new Promise(async (resolve, reject) => {
                const curatorResp = await fetchOrganisationsCurators(id);
                if (curatorResp) {
                    setOrganisationCurators(curatorResp);
                    resolve(true)
                } else {
                    resolve(true)
                }
            })
        }
    }

    const loadCurator = async () => {
        // if (exhibitionId) {
        if (uuid) {
            return new Promise(async (resolve, reject) => {
                const curatorResp = await fetchExhibitionCurator(uuid);
                if (curatorResp?.length > 0) {
                    setExhibitionCurators(curatorResp);
                    resolve(true)
                } else {
                    resolve(true)
                }
            })
        }
    }

    const loadExhibitionArticles = async () => {
        if (uuid) {
            return new Promise(async (resolve, reject) => {
                const articleResp = await fetchExhibitionArticles(uuid);
                if (articleResp.data) {
                    const articlesResponse = articleResp.data;
                    articlesResponse.sort(function (a, b) {
                        return a.data.y - b.data.y
                    });
                    setArticles(articlesResponse);
                    resolve(true)
                } else {
                    resolve(true)
                }
            })
        }
    };
    const loadExhibitionArtists = async () => {
        if (uuid) {
            const artistsResp = await fetchExhibitionArtists(uuid);

            if (artistsResp.data) {
                setArtists(artistsResp.data);
            }
        }
    };

    const getCuratorsFeature = () => {
        return (
            exhibitionCurators?.length > 0 &&
            <FeatureCardList 
                title={exhibitionCurators.length > 1 ? "Curators" : "Curator"}
                cards={exhibitionCurators}
                type="curators"
            />
        )
    }

    const getArtistsFeature = () => {
        return (
            artists.length > 0 &&
            <FeatureCardList 
                title={artists.length > 1 ? "Artists" : "Artist"}
                cards={artists}
                type="artists"
            />
        )
    }
    
    const getGalleryFeature = (organisation, isOrganisation) => {
        return (
            <FeatureCardList 
                title="Presented by..."
                type={isOrganisation ? 'organisations' : 'galleries'}
                variant="secondary"
                card={{
                    image: organisation?.data?.image,
                    name: organisation?.data?.name,
                    description: organisation?.data?.overview,
                    location: organisation?.data?.locations,
                    slug: organisation?.data?.slug,
                    id: organisation?.data?.id
                }}
            />
        )
    }

    return {
        loadExhibitionArtists,
        loadExhibitionArticles,
        handleGalleryClick,
        getCollertals,
        getArtists,
        getGallery,
        getOrganisationCurators,
        loadOrganizationCurators,
        getExhibitionCurator,
        loadCurator,
        artists,
        articles,
        exhibitionCurators,
        organisationCurator,
        getArtistsFeature,
        getGalleryFeature,
        getCuratorsFeature
    }
}