import React from 'react';
import SpinnerIcon from '../../assets/images/spinner.png';
import CheckIcon from '../../assets/icons/green-check.svg';
import "./IslandLoadingNav.scss";

const IslandLoadingNav = ({ progress, loading, isGalleryLoadingVariant = false, roomName = null }) => {
    return (
        <div className={`island-loading-nav ${!loading ? 'loaded' : ''}`}>
            <div>
                <p className='island-nav__title'>
                    Loading{roomName ?<span className='island-nav__title--room-name'>: {roomName}</span> : ''} {isGalleryLoadingVariant ? 'Galleries' : ''} <span>{progress}%</span>
                </p>
                <p className='island-nav__description'>
                    High resolution images...
                </p>
            </div>
            <div className='island-nav__spinner-wrapper'>
                <img className='island-nav__check-icon' src={CheckIcon} alt='check' />
                <div className='island-nav__spinner'>
                    <img src={SpinnerIcon} alt="spinner" />
                </div>
            </div>
        </div>
    );
}

export default IslandLoadingNav;