import axios from "axios";
import logger from '../utils/logger';
import { getIdFromSlug } from '../utils'
const URL = 'https://api.vortic.art/';
// const URL = 'http://localhost:3001/';


export const fetchCurateDetails = async (exhibitionId, sceneId = undefined, allArtworks=false, isPreview = false, eliminateDimensions = true) => {
  logger.debug('fetchCurateDetails = ', exhibitionId, sceneId)
  try {
      const result = await axios
          .get(`${URL}user/exhibitions/${exhibitionId}/curate?eliminateDimensions=${eliminateDimensions ? 'true' : 'false'}${allArtworks ? '&allArtworks=true' : ''}${sceneId ? '&exhibitionSceneId=' + sceneId+'' : ''}${isPreview ? '&previewOnly=true' : ''}&eliminateSignedUrl=true`)
          .catch((err) => {
              logger.error(err);
              if (err && err.response && err.response.status === 400) {
                  return Promise.reject(
                      new Error("Request failed with status code 400")
                  );
              }
              return Promise.reject(new Error(JSON.stringify(err.response.data)));
          });
      if (result) {
          return result.data;
      }
  } catch (e) {
      logger.error(e);
      return Promise.reject(new Error(e.message));
  }
}

export const fetchCurateLinkedArtworks = async (exhibitionId, sceneId = undefined, allArtworks=false, isPreview = false) => {
  try {
      console.log('loading artworks')
      const result = await axios
          .get(`${URL}user/exhibitions/${exhibitionId}/linkedArtworks?${allArtworks ? '&allArtworks=true' : ''}${sceneId ? '&exhibitionSceneId=' + sceneId+'' : ''}${isPreview ? '&previewOnly=true' : ''}`)
          .catch((err) => {
              logger.error(err);
              if (err && err.response && err.response.status === 400) {
                  return Promise.reject(
                      new Error("Request failed with status code 400")
                  );
              }
              return Promise.reject(new Error(JSON.stringify(err.response.data)));
          });
      if (result) {
          return result.data;
      }
  } catch (e) {
      logger.error(e);
      return Promise.reject(new Error(e.message));
  }
}

export const fetchArtworkMedia = async (exhibitionId, artworkId, options = {}) => {
  try {
    const result = await axios
      .get(`${URL}user/${exhibitionId}/artworks/${artworkId}`, {
        ...options  // Spread the options, including the signal
      })
      .catch((err) => {
        // logger.error(err);
        if (err && err.response && err.response.status === 400) {
          return Promise.reject(
            new Error("Request failed with status code 400")
          );
        }
        return Promise.reject(new Error(JSON.stringify(err.response.data)));
      });
    if (result) {
      return result.data;
    }
  } catch (e) {
    // logger.error(e);
    return Promise.reject(new Error(e?.message));
  }
}


export const fetchExhibitionsDetails = async (id) => {
  try {
    const result = await axios
      .get(`${URL}user/exhibitions/${encodeURIComponent(id)}`)
      .catch((err) => {
        console.log('err = ', err);
        if (err && err.response && err.response.status === 400) {
          return Promise.reject(
            new Error("Request failed with status code 400")
          );
        }
        return Promise.reject(new Error(JSON.stringify(err.response.data)));
      });
    if (result) {
      return result.data;
    }
  } catch (e) {
    return Promise.reject(new Error(e.message));
  }
};

export const fetchV2Scenes = async (exhibitionId) => {
  try {
    const result = await axios
      .get(`${URL}user/exhibitions/${exhibitionId}/scenes?cardsView=true`)
      .catch((err) => {
        if (err && err.response && err.response.status === 400) {
          return Promise.reject(
            new Error("Request failed with status code 400")
          );
        }
        return Promise.reject(new Error(JSON.stringify(err.response.data)));
      });
    if (result) {
      return result.data;
    }
  } catch (e) {
    return Promise.reject(new Error(e.message));
  }
}


export const fetchExhibitionArticles = async (exhibitionId) => {
  logger.debug('API fetchExhibitionArticles exhibitionId => ', exhibitionId);
  try {
    const result = await axios
      .get(`${URL}user/exhibitions/${exhibitionId}/articles`)
      .catch((err) => {
        logger.error('err = ', err);
        if (err && err.response && err.response.status === 400) {
          return Promise.reject(
            new Error("Request failed with status code 400")
          );
        }
        return Promise.reject(new Error(JSON.stringify(err.response.data)));
      });
    logger.debug('fetchExhibitionArticles = ', result)
    if (result) {
      return result.data;
    }
  } catch (e) {
    logger.error('fetchExhibitionArticles Error = ', e.message)
    // return Promise.reject(new Error(e.message));
  }
};

export const fetchExhibitionArtists = async (id) => {
  logger.debug('fetchExhibitionArtists = ', id);
  try {
    const result = await axios
      .get(`${URL}user/exhibitions/${id}/artists?cardsView=true`)
      .catch((err) => {
        logger.error('err = ', err);
        if (err && err.response && err.response.status === 400) {
          return Promise.reject(
            new Error("Request failed with status code 400")
          );
        }
        return Promise.reject(new Error(JSON.stringify(err.response.data)));
      });
    if (result) {
      return result.data;
    }
  } catch (e) {
    logger.error(e);
    // return Promise.reject(new Error(e.message));
  }
};

export const enquireArtwork = async (data) => {
    try {
        const result = await axios
            .post(`${URL}/user/contact`, data)
            .catch((err) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err)));
            });
        return result && result.data;
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchArtworkAgent = async (artworkId) => {
    try {
        const result = await axios
            .get(`${URL}/user/artworks/${artworkId}/agent`)
            .catch((err) => {
                logger.error('err = ', err);
                if (err && err.response && err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            });
        // logger.debug('fetchArtworkAgent = ', result)
        if (result) {
            return result.data;
        }
    } catch (e) {
        logger.error(e);
        return Promise.reject(new Error(e.message));
    }
};

export const fetchArtworkDetails = async (id) => {
  try {
      const result = await axios
          .get(`${URL}user/artworks/${encodeURIComponent(id)}`)
          .catch((err) => {
              logger.error('err = ', err);
              if (err && err.response && err.response.status === 400) {
                  return Promise.reject(
                      new Error("Request failed with status code 400")
                  );
              }
              return Promise.reject(new Error(JSON.stringify(err.response.data)));
          });
      if (result) {
          return result.data;
      }
  } catch (e) {
      logger.error(e);
      return Promise.reject(new Error(e.message));
  }
};


export const fetchExhibitionCurator = async (id) => {
  try {
      const result = await axios
          .get(`${URL}user/exhibitions/${id}/curator`)
          .catch((err) => {
              return Promise.reject(null);
          });
      if (result) {
          return result.data;
      }
  } catch (e) {
      return Promise.resolve(null);
  }
};

export const fetchOrganisationsCurators = async (organisationId) => {
  try {
      const result = await axios
          .get(`${URL}user/galleries/${getIdFromSlug(organisationId)}/curators`)
          .catch((err) => {
              logger.error('err = ', err);
              if (err && err.response && err.response.status === 400) {
                  return Promise.reject(
                      new Error("Request failed with status code 400")
                  );
              }
              return Promise.reject(new Error(JSON.stringify(err.response.data)));
          });
      if (result) {
          return result.data;
      }
  } catch (e) {
      return Promise.reject(new Error(e.message));
  }
};