import React, { useState, useEffect, useRef } from 'react';
import Flickity from 'react-flickity-component'
import "./RoomSlider.scss";
import RoomSwitchCard from '../RoomSwitchCard';
import IconBtn from '../IconBtn'

const flickityOptions = {
  initialIndex: 0,
  prevNextButtons: false,
}

// @ts-ignore
const RoomsSlider = React.memo(({ rooms, selectedRoomId, handleRoomSelection, handleCancel }) => {

  const [selectedRoomIndex, setSelectedRoomIndex] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState('0');
  const [disabledBtn, setDisabledBtn] = useState('');
  const sliderRef = useRef(null);

  const handlePrevButtonClick = () => {
    // @ts-ignore
    sliderRef.current.previous();
  }

  const handleNextButtonClick = () => {
    // @ts-ignore
    sliderRef.current.next();
  }

  useEffect(() => {
    getSelectedRoomIndex();
    console.log('rooms, selectedRoomId => ', rooms, selectedRoomId);
  }, [rooms, selectedRoomId]);

  const getSelectedRoomIndex = () => {
    if (rooms.length > 1 && !!selectedRoomId) {
      const index = rooms.findIndex(room => room.value === selectedRoomId);
      setSelectedRoomIndex(index);
    }
  };

  return (
    <div className='rooms-slider-wrapper'>
      <IconBtn
        type="chevronLeft"
        className={`room-slider-btn tour-control-button active detailed-mode-arrow prev ${disabledBtn === 'prev' ? 'disabled' : ''}`}
        onClick={handlePrevButtonClick}
      />
      <Flickity
        className={'rooms-slider'} // default ''
        elementType={'div'} // default 'div'
        options={{ ...flickityOptions, initialIndex: selectedRoomIndex }} 
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
        // @ts-ignore
        flickityRef={(c) => (sliderRef.current = c)}
      >
        {
          rooms?.length > 0 && rooms.map((room, index) => {
            return (
              <div className="room-slider-cell" key={index}>
                <RoomSwitchCard
                  index={index + 1}
                  label={room.label}
                  isActive={room.value === selectedRoomId}
                  onClose={() => console.log('Close!')}
                  handleRoomSelection={handleRoomSelection}
                  handleCancel={handleCancel}
                  roomId={room.value}
                />
              </div>
            )
          })
        }
      </Flickity>
      <IconBtn
        type="chevronRight"
        className={`room-slider-btn tour-control-button active detailed-mode-arrow next ${disabledBtn === 'next' ? 'disabled' : ''}`}
        onClick={handleNextButtonClick}
      />
    </div>
  )
})

export default RoomsSlider;