import React from 'react';
import { IParagraphProps, ITimeLineProps, ILabelProps, IAnchorProps, ITitleProps, IHeadTitleProps, IHeadingProps } from './types';
import { Link } from 'react-router-dom';

export const Title:React.FC<ITitleProps> = ({value, className, children}) => {
    return (
        <h1 className={`title ${className || ''}`}>
            {value || children}
        </h1>
    )
};

export const Title2:React.FC<ITitleProps> = ({value, className}) => {
    return (
        <h1 className={`title sub-title` + (className ? (' ' + className) : '')}>
            {value}
        </h1>
    )
};

export const Heading:React.FC<IHeadingProps> = ({children = null, value = null, className = "", ...rest}) => {
    return (
        <h1 className={`heading ${className}`} {...rest}>
            {value ? value : children}
        </h1>
    )
};

export const SubHeading:React.FC<IHeadingProps> = ({children = null, value = null, className = "", ...rest}) => {
    return (
        <h1 className={`sub-heading ${className}`} {...rest}>
            {value ? value : children}
        </h1>
    )
};

export const Paragraph:React.FC<IParagraphProps> = ({children = null, id = "" , value = null, className, onClick = ()=>{}, title}) => {
    return (
        <p id={id} className={`paragraph ${className || ''}`} onClick={() => onClick()} title={title}>
            {value || children}
        </p>
    )
};

export const Timeline: React.SFC<ITimeLineProps> = ({value, className}) => {
    return (
        <p className={'timeline' + (className ? (' ' + className) : '')}>
            {value}
        </p>
    )
};

export const Label: React.SFC<ILabelProps> = ({value, className}) => {
    return (
        <p className={'label' + (className ? (' ' + className) : '')}>
            {value}
        </p>
    )
};

export const SmallLabel = ({value}) => {
    return (
        <p className='sm-label'>
            {value}
        </p>
    )
};

export const Anchor: React.FC<IAnchorProps> = ({value, children, to, inline, className, bold, target = "", isLocalLink = false}) => {
    return (
        <>
            {
                isLocalLink ?
                // @ts-ignore
                <Link to={to} className={'anchor' + `${inline ? ' inline' : ''}` + `${bold ? ' bold' : ''}` + `${className ?  (' ' + className) : ''}`} target={target}>
                    {value || children}
                </Link>
                :
                <a href={to} className={'anchor' + `${inline ? ' inline' : ''}` + `${bold ? ' bold' : ''}` + `${className ?  (' ' + className) : ''}`} target={target}>
                    {value || children}
                </a>   
            }
        </>
    )
};

export const HeadTitle: React.SFC<IHeadTitleProps> = ({value, className}) => {
    return (
        <>
            <div className={"head-title" + (className ? (' ' + className) : '')}>
                {value}
            </div>
        </>
    )
};
