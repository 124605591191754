import React from "react";
import { IButtonProps } from "./types";
import { Link } from "react-router-dom";
import { LeftIcons, RightIcons } from "./Icons";

const Button: React.FC<IButtonProps> = ({
  className,
  disable,
  onClick,
  value,
  type,
  size,
  to,
  iconType,
  buttonRef,
  spanClassName = "",
  bright,
  children
}) => {
  const getClassName = (size, iconType, type, className) => {
    let name = "button";
    if (size) {
      name += " button__" + size;
    }
    if (iconType) {
      name += " button__" + iconType;
    }
    if (type) {
      name += ` ${type}`;
    }
    if (className) {
      name += ` ${className}`;
    }
    if (iconType === "next") {
      name += " button__icon-animate";
    }
    if(bright) {
      name += " button__white";
    }
    return name;
  };

  const button = () => (
    <button
      disabled={disable}
      ref={buttonRef}
      className={getClassName(size, iconType, type, className)}
      onClick={onClick && onClick}
    >
      {
        !!children ? 
          children 
        : 
        <>
          {iconType && <LeftIcons type={iconType} />}
          <span className={spanClassName}>{value}</span>
          {iconType && <RightIcons type={iconType} />}
        </>
      }
      
    </button>
  );

  return <>{to ? <Link to={to}>{button()}</Link> : <>{button()}</>}</>;
};

export default Button;
