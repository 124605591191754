import React, { Fragment, useEffect, useRef, useState, useImperativeHandle } from 'react';
import "./ExhibitionCurateLoading.scss";
import { Heading, Label, Paragraph, Timeline } from '../../components/Typography2';
import IslandLoadingNav from '../IslandLoadingNav';
import ImageLoader from '../ImageLoader';
import IconBtn from '../IconBtn';
import NextIcon from '../../assets/icons/next.svg';
import FadeDown from '../FadeDown';
import { isMobileDevice } from '../../utils';

interface IExhibitionLoading {
    isVideoEnabled?: boolean;
    progress: number;
    videoUrl?: string;
    posterUrl?: string;
    imageUrl: string;
    timeLine: string;
    exhibitionName: string;
    galleryName: string;
    description: string;   
    roomName?: string;   
    isSidePanel?: boolean;
    isIntroVideoEnded?: boolean, 
    setIsIntroVideoEnded?: (val: boolean) => void;
    setIsIntoAudioFound: (val: boolean) => void;
    setIntroAudioPlaying: () => void;
    setActiveHeader: (boolean) => void;   
    skipAnimation: () => void;   
    loadingOnComplete: () => void;
    handleMoreClick: () => void;
    descriptionHtml?: any;
    isRoomSwitchModal?: boolean;
    poweredByNone?: boolean;
    isIntoAudioFound?: boolean;
}

const MAX_CHARACTERS = 255;

const ExhibitionLoading: React.FC<IExhibitionLoading> = React.forwardRef(
    (
        {
            isVideoEnabled,
            progress,
            videoUrl,
            posterUrl,
            imageUrl,
            timeLine,
            exhibitionName,
            galleryName,
            description,
            setActiveHeader,
            skipAnimation,
            roomName,
            loadingOnComplete,
            handleMoreClick,
            isSidePanel,
            isIntroVideoEnded, 
            setIsIntroVideoEnded,
            descriptionHtml,
            poweredByNone,
            isRoomSwitchModal,
            setIntroAudioPlaying,
            setIsIntoAudioFound,
            isIntoAudioFound
        }, 
        ref
    ) => {

    const divRef = useRef(null);
    const paragraphRef = useRef(null);
    const videoRef = useRef(null);
    const timeOutArtworkSelection = useRef(null);
    const [isBackgroundReady, setIsBackgroundReady] = useState(false);
    const [constraintY, setConstraintY] = useState(0);
    const [startY, setStartY] = useState(0);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [calculatedLoaderHeight, setCalculatedLoaderHeight] = useState(0);
    const [isTouchEnd, setIsTouchEnd] = useState(false);
    const [isManualStart, setIsManualStart] = useState(false);
    const [isVideoStarted, setIsVideoStarted] = useState(false);
    const [isMediaLoaded, setIsMediaLoaded] = useState(false);
    const [rendered, setRendered] = useState(false);
    // const [hasAudio, setHasAudio] = useState(false);
    const [isFirstAudioToggle, setIsFirstAudioToggle] = useState(true);
    const myElementRef = useRef<any>(null);

    useEffect(() => {
        // Check if the content has been rendered
        if (myElementRef.current && myElementRef.current.innerHTML !== "") {
          setRendered(true);
        }
    }, [myElementRef.current]);

    useEffect(() => {
        if (rendered) {
            handleMutation();
        }
    }, [rendered]);

    const handleMutation = () => {
        appendTruncateDots();
        appendButton();
    };
    
    const appendButton = () => {
        const button = document.createElement('button');
        button.className = 'loading-more-btn';
        button.textContent = 'More';

        myElementRef.current.appendChild(button);
    };

    const appendTruncateDots = () => {
        const dotSpan = document.createElement('span');
        dotSpan.className = '';
        dotSpan.textContent = ' ... ';

        myElementRef.current.appendChild(dotSpan);
    };
    

    useEffect(() => {
        if(isIntroVideoEnded && progress === 100){
            skipAnimation();
            // @ts-ignore
            timeOutArtworkSelection.current = setTimeout(() => {
                loadingOnComplete();
            }, 2000)
        }
    }, [isIntroVideoEnded, progress])

    useEffect(() => {
        setIsManualStart(false);
        return () => {
            if(timeOutArtworkSelection.current){
                clearTimeout(timeOutArtworkSelection.current);
            }
        }
    }, [])

    const handleTouchStart = (event) => {
        setStartY(event.touches[0].clientY);
        setIsTouchEnd(false);

        // Remove transition during touch start
        if (divRef.current) {
            // @ts-ignore
            divRef.current.style.transition = 'none';
        }
    };

    const percentage = (partialValue, totalValue) => {
        return (100 * partialValue) / totalValue;
    }

    useEffect(() => {
        if (!paragraphRef.current || window.innerWidth > 767) return;
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            const height = paragraphRef.current.clientHeight - 30;
            setConstraintY(height)
            setStartY(height);
            setCurrentPosition(height)
            // @ts-ignore
            divRef.current.style.transform = `translateY(${height}px)`;
            // @ts-ignore
            divRef.current.classList.remove('down', 'up');
        });
        resizeObserver.observe(paragraphRef.current);
        return () => resizeObserver.disconnect();
    }, [divRef, divRef.current, description, descriptionHtml])

    const handleTouchMove = (event) => {
        const deltaY = event.touches[0].clientY - startY;
        const newPosition = Math.min(Math.max(currentPosition + deltaY, 0), constraintY);

        setCurrentPosition(newPosition);
        setStartY(event.touches[0].clientY);

        if (divRef.current) {
            // @ts-ignore
            divRef.current.style.transform = `translateY(${newPosition}px)`;
            // @ts-ignore
            divRef.current.classList.remove('down', 'up');
            if (deltaY > 0) {
                // @ts-ignore
                divRef.current.classList.add('down');
            } else {
                // @ts-ignore
                divRef.current.classList.add('up');
            }
        }
    };

    const handleTouchEnd = () => {
        // Determine the final position based on the direction
        // @ts-ignore
        const finalPosition = divRef.current.classList.contains('down') ? constraintY : 0;

        // Set the final position and perform any additional logic
        if (divRef.current) {
            // @ts-ignore
            divRef.current.style.transition = 'transform 0.3s ease';
            // @ts-ignore
            divRef.current.style.transform = `translateY(${finalPosition}px)`;
            setCurrentPosition(finalPosition);
            setIsTouchEnd(true);
        }
    };

    useEffect(() => {
        const totalPercentage = percentage(currentPosition, constraintY);
        const loaderPillHeight = 64;
        const newCalculatedLoaderHeight = (totalPercentage / 100) * loaderPillHeight;
        setCalculatedLoaderHeight(newCalculatedLoaderHeight);
    }, [currentPosition])

    const handlePlay = () => {
        if(videoRef.current){
            // @ts-ignore
            videoRef.current.play();
        }
    }

    const handleMediaOnLoad = (mediaType: 'image' | 'video') => {
        setIsBackgroundReady(true)
        setActiveHeader(true);
        handlePlay();
        setIsMediaLoaded(true);
    }

    const handleVideoEnded = (val: boolean) => {
        // @ts-ignore
        setIsIntroVideoEnded(val);
    };

    const handleSkipIntro = () => {
        setIsManualStart(true);
        skipAnimation();
        handleVideoEnded(true);
        loadingOnComplete();
    }

    const handleVideoOnPlay = (e) => {
        setIsVideoStarted(true);
        handleVideoEnded(false);
    }

    const onVideoLoaded = (e) => {
        const video = e.target;
        if ((typeof video.mozHasAudio !== "undefined" && video.mozHasAudio) ||
            (typeof video.webkitAudioDecodedByteCount !== "undefined" && video.webkitAudioDecodedByteCount > 0) ||
            Boolean(video.audioTracks?.length)) {
            setIsIntoAudioFound(true);
        } else {
            if(isIntoAudioFound) {
                setIsIntoAudioFound(false);
            }
        }   
    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
          return  `<div>${text.substring(0, maxLength)}</div>`
        }
    };

    const truncatedHtml = () => {
        const truncatedText = truncateText(descriptionHtml, MAX_CHARACTERS);
        return { __html: truncatedText };
    };

    const isArtworkDescription = !!descriptionHtml || (description && description.trim() !== "");

    const handleAnchorClick = (event) => {
        if (!event.target.closest('a')) {
            handleMoreClick();
        }
    };

    const handleOnSpeakerClick = () => {
        if(isIntoAudioFound && videoRef?.current) {
            if(isFirstAudioToggle) {
                // @ts-ignore
                videoRef.current.currentTime = 0;
            }
            // @ts-ignore
            videoRef.current.muted = !videoRef.current.muted;
            setIntroAudioPlaying();
            setIsFirstAudioToggle(false);
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            toggleAudio: () => {
                handleOnSpeakerClick();
            },
        }),
    );

    const isMobileView = isMobileDevice() || window.innerWidth <= 768;

    return (
        <div className={`exhibition-curate-loading ${(isIntroVideoEnded && progress === 100) ? 'exhibition-curate--loaded' : ''} ${isManualStart ? 'exhibition-curate--loaded-manual' : ''} ${!poweredByNone ? "exhibition-curate-loading--with-footer" : ""}`} onClick={handlePlay}>
            <div className={`exhibition-curate-loading__background-cover ${isVideoStarted ? 'background-cover--video-on' : ''}`}>
                {(!!videoUrl) && (
                    <video ref={videoRef} playsInline autoPlay muted controls={false} poster={posterUrl} src={videoUrl} onEnded={() => handleVideoEnded(true)} onPlay={handleVideoOnPlay} className={isMediaLoaded ? 'media--fade-in' : 'media--fade-out'} onLoadedData={onVideoLoaded}>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                )}
                {!!imageUrl && <img src={imageUrl} alt="exhibition-cover" onLoad={() => handleMediaOnLoad('image')}  className={isMediaLoaded ? 'media--fade-in' : 'media--fade-out'} />}
                <div className={`exhibition-curate-loading__background-gradient ${isSidePanel ? 'background-gradient--hide' : ''}`}></div>
            </div>
            <div className={`exhibition-curate-loading__body ${isBackgroundReady ? 'exhibition-curate-loading__body--active' : ''} ${isRoomSwitchModal ? 'exhibition-curate-loading__body--fade-out' : ''}`} ref={divRef}
                style={{ touchAction: 'pan-y', transition: isTouchEnd ? 'transform 0.15s ease-in-out' : 'none' }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}>
                <div className={`exhibition-curate-loading__body-details ${(isSidePanel) ? 'exhibition-curate-loading__body-details--fade-out' : ''} ${!isArtworkDescription ? 'body-details--no-description' : ''}`}>
                    {
                        !isMobileView ? 
                        <div className='exhibition-curate-loading__body-details--desktop'>
                            {/* @ts-ignore */}
                            <Heading value={exhibitionName} />
                            <div className='exhibition-curate-loading__body-flex-box'>
                                <div>
                                    <Label className='details-title' value="Presented by" />
                                    <Label className='details-gallery-name' value={galleryName} />
                                </div>
                                {/* <div className='details-divider' />
                                <div>
                                    <Label className='details-title' value="Dates" />
                                    <Timeline value={timeLine} />
                                </div> */}
                            </div>
                        </div> : 
                        <Fragment>
                            {/* <Timeline value={timeLine} /> */}
                            {/* @ts-ignore */}
                            <Heading value={exhibitionName} />
                            <Label value={galleryName} />
                            {isArtworkDescription && <div ref={paragraphRef} style={{ transform: `translateY(${calculatedLoaderHeight}px)`, transition: isTouchEnd ? 'transform 0.15s ease-in-out' : 'none' }}>
                                {(description && description.trim() !== "") ? <p className='paragraph loading-screen-description' onClick={handleAnchorClick}>
                                    {description}&nbsp;
                                    <button className='loading-more-btn'>
                                        More
                                    </button>
                                </p> : 
                                (!!descriptionHtml && <div className='exhibition-loading-long-desc loading-screen-description' style={{display: 'inline-block'}}>
                                    <p className='paragraph' onClick={handleAnchorClick} style={{display: 'inline-block'}}>
                                        {/* @ts-ignore */}
                                        <span className='quill-elm-renderer' style={{display: 'inline-block'}} dangerouslySetInnerHTML={truncatedHtml()} ref={myElementRef} />
                                    </p>
                                </div>)
                                }
                            </div>}
                        </Fragment>
                    }
                </div>
                <div className={`exhibition-curate-loading__body-loader ${!isMobileView ? 'exhibition-curate-loading__body-loader--desktop' : ''}`} style={{ position: 'relative', transform: `translateY(${-(currentPosition + (calculatedLoaderHeight / 2))}px)`, transition: isTouchEnd ? 'transform 0.15s ease-in-out' : 'none' }} >
                    <div className={`island-nav island-nav--loading ${progress === 100 ? 'island-nav--loading__room-loaded' : ''}`}>
                        <div className='bg-layer-light' />
                        <div className='bg-layer-dark' />
                        {/* @ts-ignore */}
                        <IslandLoadingNav progress={progress} roomName={roomName} loading={progress < 100} />
                        <button className='exhibition-loading__skip-intro' onClick={handleSkipIntro}>
                            <Label value="Skip Intro" />
                            <img src={NextIcon} alt='arrow-next' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ExhibitionLoading;
