import React, { useState, useEffect } from 'react'
import './style.scss';
import "../../styles/global.scss";
import { Title, Title2 } from '../Typography2';
import Spinner from './Spinner';
import Button from '../Button';
import CurateVideoInstructions from "../CurateVideoInstructions";
import {
  constructImageUrl,

} from '../../utils'
import PoweredByVortic from '../../assets/images/powered-by-vortic-bright.svg';

const Loader = ({ progress, secondary, className, state, isDesktopActive, handleStartTour, handleLoadedModal, galleryName, poweredByNone, style = {}, isLockedInDetailsMode, enterExhibition, isRoomLoading }) => {

  const [ispowered, setIsPowered] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if(poweredByNone) {
        setIsPowered(false)
      } else {
        setIsPowered(true)
      }
  }, [poweredByNone]);

  useEffect(() => {
    setTimeout(() => {
      if(isLockedInDetailsMode && progress === 100) {
        // enterExhibition();
      }
      else if(progress === 100) {
        setIsLoaded(true);
      }
    }, 1000)
  }, [isLockedInDetailsMode, progress])

  useEffect(() => {
    if(isRoomLoading && progress > 99) {
      enterExhibition();
    }
  }, [isRoomLoading, progress])

  return (
    <div className={'loader' + (secondary ? ' loader--secondary' : '') + (className ? ` ${className}` : '')}>
      <div className='loader__bg' >

        <section className={'loader__content' + (isLoaded ? ' loader__content__loaded' : '') + (isDesktopActive ? ' desktop-view' : '')} style={{ backgroundImage: `url(${constructImageUrl(state?.data?.image, '1080')})`, backgroundSize: "cover", backgroundPosition: 'center', ...style }}>
          {!isDesktopActive && <Title2 value={secondary ? "Loading 3D Viewing Room..." : galleryName ? (`${galleryName}'s 3D Viewing Room`) : <>&nbsp;</>} />}
          <div> {
            !isDesktopActive ?
              <Spinner prog={progress} secondary={secondary} />
              :
              <div className='loaded-state'>
                <Title
                  className="title--loaded"
                  value="Take an interactive tour of this exhibition?"
                />
                <div className="ready-btns">
                  <Button
                    className="start-tour"
                    value="Yes, start tour"
                    onClick={() => handleStartTour()}
                    type="secondary"
                  />
                  <Button
                    value="No, I want to explore on my own"
                    onClick={() => handleLoadedModal(false)}
                    className="cancel-tour-btn"
                    type="secondary"
                  />
                </div>
              </div>
          }</div>
          {!isDesktopActive && ispowered && <div className='loader__logo'>
            <img src={PoweredByVortic} alt="Powered-By-Vortic" />
          </div>}
        </section>

        <div className={`exhibition-instructions ${isLoaded ? 'exhibition-instructions-loaded' : ''}`}><CurateVideoInstructions className="loading-screen-instructions" onClick={() => enterExhibition()} variant='exhibition' /></div>

        {/* {
          <ImageLoader section={sect}
            style={{ backgroundImage: `url(${constructImageUrl(state?.data?.image, '1080')})`, backgroundSize: "cover", backgroundPosition: 'center' }}
            state={state?.data}
            imgUrls={[
              constructImageUrl(state?.data?.image, '1080')
            ]}
          />
        } */}
      </div>
    </div>
  )
}

export default Loader;
