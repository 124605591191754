import React from 'react';
import LogoURL from '../../assets/images/logo@80x80.png';
import BrightLogo from '../../assets/icons/vortic-brigth-logo.svg';

const Spinner = ({ bright, className }) => {
    return (
        <div className={`spinner-container ${bright ? 'bright' : ''} ${className || ''}`}>
            <img src={!bright ? LogoURL : BrightLogo} className="anime-loader" />
        </div>
    )
}
export default Spinner;
