import React, {Fragment, useEffect, useState} from 'react'
import "./variables.css";
import './assets/fonts/fonts.css';
import './styles/global.scss';
import Curate from './containers/Curate';
import Loader from './components/Loader';
import './components/Typography/style.scss';
import './components/Button/style.scss';
import BannerLoader from './components/BannerLoader';
import {BrowserRouter as Router, useParams } from 'react-router-dom'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    // TODO: Have to update the id for gtmId
    gtmId: 'GTM-TTLGVRF'
}

const App = () => {

  useEffect(() => {
    if ( window.location !== window.parent.location ) {
      // alert('I am in iframe');
    } else {
      // alert('I am not in iframe');
    }
  }, [])

  const getRedirectURL = () => {
    let url = window.location.hash;
    if(url.includes('&bannerOnly=true')) {
      url = url.replace('&bannerOnly=true', '')
    }
    else if(url.includes('&bannerOnly')) {
      url = url.replace('&bannerOnly', '')
    }
    if(url.includes('&collapsed=true')) {
      url = url.replace('&collapsed=true', '')
    }
    else if(url.includes('&collapsed')) {
      url = url.replace('&collapsed', '')
    }
    if(url.includes('&bannerText')){
      // Remove the &bannerText and the value too
      const bannerTextIndex = url.indexOf('&bannerText');
      const ampersandIndex = url.indexOf('&', bannerTextIndex + 1);
      if (ampersandIndex !== -1) {
        url = url.slice(0, bannerTextIndex) + url.slice(ampersandIndex);
      } else {
        url = url.slice(0, bannerTextIndex);
      }
    }
    return url;
  }

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--viewHeight', `${window.innerHeight}px`);
  }

  useEffect(() => {
    if(process.env.NODE_ENV === 'production') {
      TagManager.initialize(tagManagerArgs);
    }
      appHeight();
      window.addEventListener('resize', appHeight)
      document.querySelector('body').classList.remove('bg-back-body');
      // Fixed the "Changes that you made may not be saved." Reload site Dialog
      window.onbeforeunload = null;
      return () => {
        window.removeEventListener('resize', appHeight);
      }

  }, []);

  return (
    <Router>
      <Wrapper getRedirectURL={getRedirectURL} />
    </Router>
  )
}

const Wrapper = ({getRedirectURL}) => {
  const [isBanner, setBanner] = useState(false);
  const [bannerText, setBannerText] = useState('');

  const params = useParams()
  
  const isParam = (param, returnValue = false) => {
    const tempHash = window.location.hash;
    const hash = tempHash.replace('#', '');
    var array = hash.split("&");

    var values, form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    return !returnValue ? form_data[param] === 'true' : form_data[param];
  }
  // useEffect(() => {
  //   isBanner()
  // }, [params])

  useEffect(() => {
    const data = isParam('bannerText', true);
    if(data) {
      const decodedText = decodeURIComponent(data);
      setBannerText(decodedText);
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', function(event) {
      // console.log("Message received from the child: " + event.data); // Message received from child
      if(event.data === "bannerOnly") {
        setBanner(true);
      }
    });
  }, [])

  useEffect(() => {
    const data = isParam('bannerText', true);
    if(data) {
      const decodedText = decodeURIComponent(data);
      setBannerText(decodedText);
    }
  }, [])

  return (
    <Fragment>
      {
        isParam('bannerOnly') ?
          <BannerLoader redirectUrl={getRedirectURL()} isCollapsed={isParam('collapsed')} hideVorticBranding={isParam('hideVorticBranding')} bannerText={bannerText} /> :
          <Curate isReturnButton={isBanner} hideVorticBranding={isParam('hideVorticBranding')} />
      }
    </Fragment>
  )
}

export default App;
