import './style.scss';
// import {setRedirectPath} from "../../containers/Global/redux/actions";
// import {setCurrentConversation} from "../../containers/Conversation/redux/actions";
// import {connect} from "react-redux";
import ArtworksAudioDetails from "./ArtworksAudioDetails";

// const mapStatesToProps = ({ global, profile }) => {
//     return {
//         isLoggedIn: true,
//         profile: {}
//     };
// };

// const mapDispatchToProps = dispatch => ({
//     // setCurrentChannel: (conversation) => {dispatch(setCurrentConversation(conversation))},
// });

// export default connect(mapStatesToProps, mapDispatchToProps)(ArtworksAudioDetails)
export default ArtworksAudioDetails;
