import React, { MutableRefObject, useRef, useState } from "react";
import {Paragraph, Heading } from '../Typography2';
import ScrollAnimation from "../ScrollAnimation/ScrollAnimation";
import ImageLoader from "../ImageLoader";
import RenderHtml from "./RenderHtml";
import AboutVideo from "../AboutVideo";
import { constructImageUrl, isMobileDevice, IsSafari } from "../../utils";
 
const RenderContent = ({type, data, noVideoFullScreen = false}) => {
    // @ts-ignore
    const videoRef = useRef()  as MutableRefObject<HTMLVideoElement>;;
    
    const [isPlaying, setIsPlaying] = useState(false);

    function toggleControls(val) {
        const video = videoRef.current;
        video.controls = val;
      }

  const openFullscreen = () => {
    if (videoRef.current.paused) {
      // eslint-disable-next-line no-unused-expressions
      videoRef.current.play();
      setIsPlaying(true);
      toggleControls(true);
      if(!noVideoFullScreen){
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
            // @ts-ignore
          } else if (videoRef.current.webkitRequestFullscreen) {
            /* Safari */
            // @ts-ignore
            videoRef.current.webkitRequestFullscreen();
            // @ts-ignore
          } else if (videoRef.current.msRequestFullscreen) {
            /* IE11 */
            // @ts-ignore
            videoRef.current.msRequestFullscreen();
          }
      }
    } else {
        videoRef.current.pause();
        setIsPlaying(false);
        toggleControls(false);
    }
  };

    if (type === "text") {
        const {text, html} = data;

        if(text && text.data && text.data.text) {
            return (
                <div className="main-text-wrapper">
                    <RenderHtml data={text && text.data && text.data.text && JSON.parse(text.data.text)} />
                </div>
            )    
        }else if(html) {
            return (
                <RenderHtml data={html} />
            )
        }
        return <></>;

    } else if (type === "image") {
        const {image, caption} = data;
        return (
            <div  className="collateral_img_preview-wrapper">
                {
                    image && image.data && (
                        <>
                                <div className="collateral__details__img-preview">
                                    <ImageLoader
                                        // @ts-ignore
                                        imgUrls={[constructImageUrl(image, "1024")]}/>
                                </div>

                            {caption &&
                              <Paragraph className="collateral__caption" value={caption}/>
                            }
                        </>
                    )
                }
            </div>
        )
    } else if (type === "video") {
        const {channel} = data;
        return (
            <>
                {   
                    channel && channel.data && channel.data.thumbnail && channel.data.thumbnail.data && (
                        <div className="collateral__video-wrapper">
                            <>
                                <div className="collateral__details__preview">
                                    <AboutVideo
                                        videoRef={videoRef}
                                        onClick={(e) => { e.stopPropagation(); openFullscreen() }}
                                        poster={constructImageUrl(channel?.data?.thumbnail, (isMobileDevice() ? "480" : "1024"))}
                                        // @ts-ignore
                                        image={constructImageUrl(channel?.data?.thumbnail, (isMobileDevice() ? "480" : "1024"))}
                                        classNames={noVideoFullScreen ? `no-fullscreen ${isPlaying ? "no-fullscreen--playing" : ""}` : 'thumbnail-view'}
                                        isPlaying={isPlaying}
                                        src={
                                            IsSafari() || isMobileDevice()
                                            ? channel?.data?.video?.data?.signedMobileMedia
                                            : channel?.data?.video?.data?.signedDesktopMedia
                                        }
                                    />
                                    
                                </div>
                            </>

                            {channel.data.title &&
                            <>
                              <Paragraph className="collateral__caption" value={channel.data.title}/>
                            </>
                            }

                            {channel.data.description &&
                            <>
                              <Paragraph value={channel.data.description}/>
                            </>
                            }
                        </div>
                    )
                }
            </>
        )
    } else {
        return <></>
    }
}

function CollateralLayouts({layout, noVideoFullScreen = false}) {
    const getColClassName = (item) => {
        if (!!item) {
            let start = item.x + 1;
            let end = start + item.columnType;

            switch (start + '/' + end) {
                case '1/2':
                    return 'col-one-by-two';

                case '1/3':
                    return 'col-one-by-three';

                case '1/4':
                    return 'col-one-by-four';

                case '2/3':
                    return 'col-two-by-three';

                case '2/4':
                    return 'col-two-by-four';

                case '3/4':
                    return 'col-three-by-four';

                default:
                    return '';
            }
        } else return '';
    };

    return (
        <>
            <div className="layout-wrapper">
                {
                    layout && layout.length > 0 && layout.map((item, index) => (
                        <div key={index} className={`${getColClassName(item.data)}`}
                                style={{gridRow: `${item.data.y + 1} / auto`}}>
                            <div>
                                {item && item.data && item.data.title && <Heading className="collateral__main-heading"
                                            value={item && item.data && item.data.title}/>}
                                <RenderContent type={item.data.type} data={item && item.data} noVideoFullScreen={noVideoFullScreen} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default CollateralLayouts;
