var rudder;

try {
    if (!!window?.localStorage) {
        const RSK = "2CtT804Umrx5e2SFOvdu1amuTPJ"
        const DATA_PLANE_URL = "https://vorticigahd.dataplane.rudderstack.com"
        const rd = require('rudder-sdk-js')
        rd.load(RSK, DATA_PLANE_URL);
        rudder = rd;
    }
  }
catch(err) {
    console.log(err);
}

export default rudder;
