import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  constructImageUrl,
  getShareURL,
  isMobileDevice,
  IsSafari,
} from "../../utils";
import { Label, Timeline } from "../Typography2";
import Button from "../Button";
import ShareBtn from "../ShareBtn";
import RenderHtml from "../CollateralLayouts/RenderHtml";
import DetailsHeader from "../DetailsHeader";
import AboutCard from "../FeatureCardList";
import CollateralLayouts from "../CollateralLayouts";
import PlayButtonCircleLight from "../../assets/icons/play-light.svg";
import useReadMoreData from "../hooks/useReadMoreData";
import "./style.scss"
import logger from "../../utils/logger";

const About = ({
  toggleLogin,
  isListView,
  isLoggedIn,
  setToggleLogin,
  secondary,
  isAbout,
  state,
  imgUrl,
  galleryName,
  title,
  isFavourite,
  exhibitionId,
  exhibitionSlug,
  toggleFavourite,
  timeLine,
  uuid,
  handleExploreExhibition,
  collateralDetails,
  isPreview,
  id,
  slug,
  handleViewListClick,
  isDateVisible,
  isArtistVisible,
  noActionBtns,
  isCurateLoading
}) => {
  const [showMore, setShowMore] = useState(false);
  const [splitDetails, setSplitDetails] = useState(false);
  const videoRef = useRef<any>(null);
  const aboutGalleryNameRef = useRef<any>(null);
  const aboutTimelineRef = useRef<any>(null);
  const exhibitionDetailsRef = useRef<any>(null);
  const mainWrapperRef = useRef<any>(null);
  let history = useHistory();

  const {
    loadExhibitionArtists,
    loadCurator,
    getExhibitionCurator,
    getCollertals,
    getArtists,
    getGallery,
    artists,
    getArtistsFeature,
    getGalleryFeature,
    getCuratorsFeature,
    exhibitionCurators,
  } = useReadMoreData(exhibitionSlug || uuid);

  const handleCurator = async () => {
    await loadCurator();
  }

useEffect(()=>{
    if(toggleLogin)
    {
         var divElement = document.querySelector("aside.curate-aside");
         divElement?.classList?.add('toggle-login')
    }
    else
    {
     var divElement = document.querySelector("aside.curate-aside");
     divElement?.classList?.remove('toggle-login')
    }
 },[toggleLogin])

  useEffect(() => {
    handleCurator();
}, [])
  
  useEffect(() => {
    loadExhibitionArtists();
  }, [exhibitionSlug, uuid]);

  useEffect(() => {
      if(aboutGalleryNameRef.current && aboutTimelineRef.current) {
        const mainWidth = mainWrapperRef.current.clientWidth;
        const galleryNameWidth = aboutGalleryNameRef.current.clientWidth;
        const aboutTimelineWidth = aboutTimelineRef.current.clientWidth;
        const totalCalcWidth = galleryNameWidth + aboutTimelineWidth + 37; // 37 is the divider width

        if(totalCalcWidth > mainWidth){
          setSplitDetails(true);
        }
      }
  }, [aboutTimelineRef.current, aboutGalleryNameRef.current])


  const openFullscreen = () => {
    if (videoRef.current.paused) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      videoRef.current.controls;
      videoRef.current.play();
      // setTimeout(() => {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        /* Safari */
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        /* IE11 */
        videoRef.current.msRequestFullscreen();
      }
      // }, 0)
    } else {
      videoRef.current.pause();
    }
  };

  return (
    <div className="exhibition-about-wrapper" ref={mainWrapperRef}>
      <div
        className={` exhibition-loading-details ${
        !isListView ? "about-wrapper" : ""
        } ${isListView ? "hide-scroll no-scroll" : ""} ${
          toggleLogin ? "login-panel " : ""
        }`}
      >
        {!isListView && (
          <div>
            {secondary && 
            !isAbout &&
            (state?.data?.video?.data && state?.data?.isVideoEnabled ? (
              <DisabledVideoDummy
                classNames={"bottom-spacing thumbnail-view"}
                videoRef={videoRef}
                onClick={openFullscreen}
                poster={constructImageUrl(state?.data?.thumbnail, "480")}
                src={
                  IsSafari() || isMobileDevice()
                    ? state?.data?.video?.data?.signedMobileMedia
                    : state?.data?.video?.data?.signedDesktopMedia
                }
              />
            ) : (
              !!imgUrl && (
                <div className="image">
                  <img src={imgUrl} alt="exhibition" />
                </div>
              )
            ))}
            <div className="about-media-wrapper">
                {state?.data?.video?.data && state?.data?.isVideoEnabled ? (
                  <DisabledVideoDummy
                    classNames="thumbnail-view"
                    videoRef={videoRef}
                    onClick={openFullscreen}
                    poster={
                      state?.data?.thumbnail
                        ? constructImageUrl(state?.data?.thumbnail, "480")
                        : state?.data?.video?.data?.signedVideoThumbnail
                    }
                    src={
                      IsSafari() || isMobileDevice()
                        ? state?.data?.video?.data?.signedMobileMedia
                        : state?.data?.video?.data?.signedDesktopMedia
                    }
                  />
                ) : (
                  !!imgUrl && (
                    <div className="image">
                      <img src={imgUrl} alt="exhibition" />
                    </div>
                  )
                )}
            </div>
            {!!title && (
              <p className="heading about-main-title">{title}</p>
            )}
            {/* <Heading className="about-main-title" value={`${title}`}/> */}
            
            <div className={`about-gallery-date-wrapper ${splitDetails ? 'split-details' : ''}`}>
              <div ref={aboutGalleryNameRef} >
                <Label className="about-subtitle" value="Presented by" />
                <Label value={galleryName} className="about-gallery-date" />
              </div>

              {!isCurateLoading && isDateVisible && 
                (
                  <>
                    <div className="about-gallery-date-divider" />
                    <div ref={aboutTimelineRef} >
                      <Label className="about-subtitle" value="Dates" />
                      <Timeline className="about-gallery-date" value={timeLine} />
                    </div>
                  </>
                )
              }
            </div>
            <Button className="about-listview-button" type="secondary" iconType="redirectDarkThin" value="Open Exhibition List view" onClick={handleViewListClick} />
            {!noActionBtns && <div className="details__actions">
              <label className="share-action">
                <Button
                  value="Share exhibition"
                  className="btn__dark"
                  iconType="share"
                  type="secondary"
                  onClick={() => logger.log("Clicked!")}
                />
                <ShareBtn
                  shareUrl={getShareURL("exhibition", exhibitionSlug || uuid)}
                />
              </label>
              <Button
                value="Bookmark"
                className="btn__dark"
                iconType={
                  isLoggedIn && isFavourite ? "favouriteOn" : "favourite"
                }
                type="secondary"
                onClick={() => {
                  isLoggedIn ? toggleFavourite() : setToggleLogin(true);
                }}
              />
            </div>}
          </div>
        )}
        {isListView && (
          <div className="list-view-action-btns">
            <Button
              value="Explore Exhibition in 3D"
              className="btn__dark explore-3d-btn"
              type="secondary"
              onClick={() => {
                handleExploreExhibition();
                // @ts-ignore
                document.querySelector(".curate-flexbox").scroll(0, 0);
              }}
            />
            <div className="details__actions ">
              <label className="share-action">
                <Button
                  value="Share exhibition"
                  className="btn__dark"
                  iconType="share"
                  type="secondary"
                  onClick={() => logger.log("Clicked!")}
                />
                <ShareBtn
                  shareUrl={getShareURL("exhibition", exhibitionSlug || uuid)}
                />
              </label>
              <Button
                value="Bookmark"
                className="btn__dark"
                iconType={
                  isLoggedIn && isFavourite ? "favouriteOn" : "favourite"
                }
                type="secondary"
                onClick={() => {
                  isLoggedIn ? toggleFavourite() : history.push("/login");
                }}
              />
            </div>
          </div>
        )}
        <div>
          {!toggleLogin && (
            <>
              {" "}
              <div
                ref={exhibitionDetailsRef}
                id="description"
                className={`exhibition-description-with-loadmore paragraph ${
                  !showMore && isListView ? "read-less" : ""
                }`}
              >
                {!!state?.data?.description && state?.data?.description.trim() !== "" && (
                  <>
                    <RenderHtml data={`${state.data.description}\xa0\n`} />
                  </>
                )}
                {state?.data?.descriptionHtml ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${state?.data?.descriptionHtml}\xa0\n`,
                    }}
                  ></div>
                ) : (
                  state?.data?.about?.data?.elements?.map((element, index) => {
                    if (element.data.elementType === "normal") {
                      return (
                        <React.Fragment
                          key={index}
                        >{`${element.data.text}\xa0\n\n`}</React.Fragment>
                      );
                    } else if (element.data.elementType === "bold") {
                      let splitted = element?.data?.text?.split("\n");
                      return (
                        splitted?.length > 0 &&
                        splitted.map((split, index) => {
                          if (!!split) {
                            return (
                              <div className="text-section" key={index}>
                                {`${split}\xa0\n\n`}
                              </div>
                            );
                          }
                        })
                      );
                    } else if (element.data.elementType === "image") {
                      return (
                        <div className="media-section">
                          <img
                            className="details__press-preview"
                            src={element?.data?.image?.data?.signedUrl}
                            alt="Press"
                          />
                        </div>
                      );
                    }
                  })
                )}
              </div>
            </>
          )}
        </div>

        {!isListView && isAbout && (
          <>
            {collateralDetails &&
              collateralDetails?.data?.collateralElement
                ?.length > 0 && (
                <div className="collateral-wrapper">
                  <CollateralLayouts
                    noVideoFullScreen
                    layout={
                      collateralDetails?.data
                        ?.collateralElement
                    }
                  />
                </div>
              )}

            <div className="mobile-view">
              {getCollertals()}
              {
                <div>
                  <div className="side-panel-divider gutter-top" />
                  {getGalleryFeature(state?.data?.organisation, state?.data?.organisation?.data?.type === "vortic")}
                </div>
              }
              {
                <div>
                  {/* @ts-ignore */}
                  {exhibitionCurators?.length > 0 && <div className="side-panel-divider" />}
                  {getCuratorsFeature()}
                </div>
              }
              {
                isArtistVisible &&
                <div>
                  {artists.length > 0 && <div className="side-panel-divider" />}
                  {getArtistsFeature()}
                </div>
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

function DisabledVideoDummy({
  videoRef,
  src,
  onClick,
  poster,
  classNames = "",
}) {
  useEffect(() => {
    const prefixes = ["", "moz", "webkit", "ms"];

    prefixes.forEach(function (prefix) {
      document.addEventListener(prefix + "fullscreenchange", function (e) {
        const fullscreenelement =
          document.fullscreenElement /* Standard syntax */ ||
          document[
            "webkitFullscreenElement"
          ] /* Chrome, Safari and Opera syntax */ ||
          document["mozFullScreenElement"] /* Firefox syntax */ ||
          document["msFullscreenElement"]; /* IE/Edge syntax */
        if (!fullscreenelement) {
          videoRef.current.pause();
        }
      });
    });

    return () => {};
  }, []);

  return (
    <div className={`disabled-video-dummy ${classNames} `} onClick={onClick}>
      <video
        controls={false}
        autoPlay={false}
        ref={videoRef}
        poster={poster}
        src={src}
      />
      <img className="poster" src={poster} alt="poster" />
      <div className="play-button">
        <img src={PlayButtonCircleLight} alt={"PlayButtonCircleLight"} />
      </div>
    </div>
  );
}

export default About;
