import React, { useState, useEffect } from 'react'
import PoweredByVortic from '../../assets/images/powered-by-vortic-bright.svg';
// import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import {ReactComponent as ArrowRight} from '../../assets/icons/banner-arrow-right.svg';
import {ReactComponent as VirtualEnterenceArrow} from '../../assets/icons/virtual-enterance.svg';
import {fetchExhibitionsDetails} from '../../services/api';
import './style.scss';
import { constructImageUrl, useWindowSize } from '../../utils';
import rudderStackEvents from "../../services/rudderstack-events";

const BannerLoader = ({redirectUrl, isCollapsed, hideVorticBranding, bannerText}) => {
  const [details, setDetails] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const size = useWindowSize();

  const getExhibitionIdFromHash = (hash) => {
    var array = hash.split("&");

    var values, form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    return form_data['e'];
  }

  const getExhibitionData = async () => {
    const hash = window.location.hash;
    const tempHash = hash.replace('#', '');
    const exhibitionDetails = await fetchExhibitionsDetails(getExhibitionIdFromHash(tempHash));
    if (exhibitionDetails.data) {
      setDetails(exhibitionDetails.data);
      var parentURL = (window.location != window.parent.location) ? document.referrer : document.location.href;
      rudderStackEvents.mount({exhibitionId: exhibitionDetails.data.id, galleryId: exhibitionDetails.data.organisation.data.id})
      rudderStackEvents.onExhibitionImpression(parentURL)
    }
  };

  const handleVideoUrl = () => {
    if(window.innerWidth > 640) {
      if(details?.video) {
        setVideoUrl(details?.video?.data.signedDesktopMedia)
      }
      else {
        setImageUrl(constructImageUrl(details?.image || '', '1920'))
      }
    } else {
      if(details?.video) {
        setVideoUrl(details?.video?.data.signedMobileMedia)
      } else {
        setImageUrl(constructImageUrl(details?.image || '', '1080'))
      }
    }
  }

  useEffect(() => {
    getExhibitionData();
  }, [])

  useEffect(() => {
    handleVideoUrl();
    if(details) {
      handleVideoUrl();
    }
  }, [size.width, details])

  const handleClick = () => {
    window.parent.postMessage("bannerOnly", "*");
    window.open(redirectUrl, '_blank');
  }

  return (
    <section onClick={handleClick} className={'banner-loader' + (isCollapsed ? ' banner-loader--collapsed': '')}>
        <div className='banner-loader-bg'>
          {
            details?.video ?
            <video autoPlay muted loop src={videoUrl} poster={constructImageUrl(details?.video?.data.signedVideoThumbnail || '', '1920')} />
            :
            details?.image && !!imageUrl && <img src={imageUrl} alt='exhibition' />
          }
          <div className='collapsed-layer'></div>
          <div className='collapsed-layer reverse'></div>
        </div>
        <p className='exhibition-name'>{details?.name}</p>
        <div className='box box--responsive'>
          <p>{bannerText || 'Walk through this exclusive collaboration with Vortic.'}</p>
          <button>
            <span className='title'>{isCollapsed ? 'ENTER VIRTUAL EXHIBITION' : 'Enter 3D Viewing Room'}</span>
            {!isCollapsed ? <ArrowRight /> : <VirtualEnterenceArrow />}
          </button>
        </div>
        <div className='powered-by-vortic'>
            {!hideVorticBranding && <img src={PoweredByVortic} alt="Powered By Vortic" />}
        </div>
        <div className='box'>
          <p>{bannerText || 'Walk through this exclusive collaboration with Vortic.'}</p>
          <button>
            <span className='title'>{isCollapsed ? 'ENTER VIRTUAL EXHIBITION' : 'Enter 3D Viewing Room'}</span>
            {!isCollapsed ? <ArrowRight /> : <VirtualEnterenceArrow />}
          </button>
        </div>
    </section>
  )
}

export default BannerLoader;
