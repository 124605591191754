import React, { useEffect, useRef, useState } from "react";
import About from "../About";
import ArtworksList from "../ArtworksList";
import SidePanelFooter from "../SidePanelFooter";
import SidePanelHeader from "../SidePanelHeader";
import { Paragraph } from "../Typography2";
import "./style.scss";
import FadeDown from "../FadeDown";

export default function SidePanel({
  aboutTitle,
  state,
  imgUrl,
  galleryName,
  timeline,
  exhibitionuuId,
  collateralDetails,
  id,
  artworks,
  handleArtworkClick,
  onClose,
  handleViewListClick,
  isOldExhibition,
  roomId,
  handleHelpClick,
  exhibitionSlug,
  sidePanel,
  isDateVisible,
  isArtistVisible,
  isCurateLoading,
  isArt = true
}) {
  const [viewDesc, setViewDesc] = useState(true);
  const [viewMap, setViewMap] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const contentScrollWrapperRef = useRef(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(sidePanel);
  const [loadingArtworks, setIsLoadingArtworks] = useState(true);

  useEffect(() => {
    let timeout;
    if (!sidePanel) {
      timeout = setTimeout(() => {
        setIsSidePanelOpen(false);
      }, 300); // we are adding delay to make sure the isSidePanelOpen gets false right after the sidepanel's close animation completes. 
    } else {
      setIsSidePanelOpen(true);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [sidePanel]);

  const viewExhibitionDesc = () => {
    setViewMap(false);
    setViewDesc(true);
  };

  const viewExhibitionMap = () => {
    setViewDesc(false);
    setViewMap(true);
  };

  const handlePanelScroll = (e: any) => {
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    if(isCurateLoading && viewMap) {
      setIsLoadingArtworks(true)
    } else {
      setIsLoadingArtworks(false)
    }
  }, [isCurateLoading, viewMap, viewDesc])

  useEffect(() => {
    if(isCurateLoading){
      if(sidePanel){
        setViewDesc(true);
        setViewMap(false);
      }
    }
  }, [sidePanel, isCurateLoading])

  useEffect(() => {
    resetScrollTop();
  }, [viewDesc, viewMap]);

  const resetScrollTop = () => {
    if (contentScrollWrapperRef.current) {
      // @ts-ignore
      contentScrollWrapperRef.current.scrollTop = 0;
    }
  };

  return (
    <aside className={`side-panel-wrapper ${isScrolled ? "is-scrolled" : ""} ${loadingArtworks ? 'side-panel-wrapper--curate-in-progress' : ''} ${viewMap ? 'side-panel-wrapper--artworks-tabs-active' : ''}`}>
      <>
        <SidePanelHeader
          viewDesc={viewDesc}
          viewMap={viewMap}
          viewExhibitionMap={viewExhibitionMap}
          viewExhibitionDesc={viewExhibitionDesc}
          onClose={onClose}
          isArt={isArt}
        />
        {
          isSidePanelOpen &&
          <main
          className="side-panel-main"
          onScroll={handlePanelScroll}
          ref={contentScrollWrapperRef}
        >
          {viewDesc && (
            <FadeDown variant="fade-in">
              {/* @ts-ignore */}
              <About
                slug={exhibitionSlug}
                id={id}
                galleryName={galleryName}
                title={aboutTitle}
                imgUrl={imgUrl}
                state={state}
                isListView={false}
                uuid={exhibitionuuId || id}
                collateralDetails={collateralDetails}
                timeLine={timeline}
                handleViewListClick={handleViewListClick}
                isDateVisible={isDateVisible}
                isCurateLoading={isCurateLoading}
                isArtistVisible={isArtistVisible}
                noActionBtns
                isAbout
              />
            </FadeDown>
          )}
          {!loadingArtworks ? 
            <>
              {viewMap && (
                <ArtworksList
                  artworks={artworks}
                  handleArtworkClick={handleArtworkClick}
                  roomId={roomId}
                  isOldExhibition={isOldExhibition}
                />
              )}
            </> 
          :
          <Paragraph className="artworks-loading-placeholder" value={`Loading ${isArt ? 'Artworks' : 'Objects'} ...`} />
          }
          </main>
        }
        <SidePanelFooter
          handleHelp={handleHelpClick}
          uuid={exhibitionuuId || id}
          exhibitionSlug={exhibitionSlug}
        />
      </>
    </aside>
  );
}

