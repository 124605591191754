import React from 'react'
import { Title2 } from '../Typography2';
import Button from "../Button";
import "./style.scss";

const ExitRoomModal = ({ handleExitExhibition, onCancel, isRoomSwitch }) => {

    const handleClose = () => {
        try {
            window.close();
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div className='exit-room-modal'>
            {!isRoomSwitch && <Title2 value="Are you sure you want to leave the exhibition?" />}
            <div className='exit-room-modal__controls'>
                <Button
                    value="Exit Exhibition"
                    size="large"
                    className={`secondary btn-bright`}
                    onClick={handleClose}
                />
                <Button
                    value="Cancel"
                    size="large"
                    className={`secondary btn-transparent`}
                    onClick={onCancel}
                />
            </div>
        </div>
    )
}

export default ExitRoomModal;
