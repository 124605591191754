import React, { useEffect, useState } from 'react';
import { Heading, Paragraph, Label } from '../Typography2';
import Button from '../Button';
import Share from '../../assets/icons/share-dark-icon.svg';
import ShareBtn from '../ShareBtn';
import { isMobileDevice, isHTML } from '../../utils';
import rudderStackEvents from "../../services/rudderstack-events";
import IconBtn from '../IconBtn';
import AudioPlayer from '../AudioPlayer';

const CurateArtworkDetails = ({ audioDetailsPopup, yearCreated, dimensionsInCM, dimensionsInIN, onClose, description, chainLink, shareUrl, isSidebar, artworkMedium, artworkId, setArtworkModal, artworkPhotos, getCurateHeight, viewportWidth, isGuiding, listview, audioUrl, isPlaying, currentTime, totalDuration, setIsPlaying, artistName, artworkName, isArt }) => {

    const [showDescription, setShowDescription] = useState(false);
    const [dimensionsUnit, setDimensionsUnit] = useState('cm');

    const handleShowDescriptionInitial = () => {
        // if(showDescription){
        setShowDescription(false);
        // }
    }

    useEffect(() => {
        handleShowDescriptionInitial();
    }, [])

    useEffect(() => {
        handleShowDescriptionInitial();
    }, [viewportWidth])

    useEffect(() => {
        if (!listview) {
            getCurateHeight();
        }
    }, [showDescription, listview])

    useEffect(() => {
        if (audioDetailsPopup) {
            setShowDescription(true)
        }
        handleShowDescriptionInitial();
    }, [audioDetailsPopup]);

    const handleDimensionsToggle = () => {
        if (dimensionsUnit === 'cm') {
            setDimensionsUnit('in')
        }
        else {
            setDimensionsUnit('cm')
        }
    }

    const getCurrencyString = (currency) => {
        switch (currency) {
            case '$':
                return 'USD';
            case '€':
                return 'EUR'
            case '£':
                return 'GBP'
            default:
                return 'GBP';
        }
    }

    const toggleArtworkDescription = () => {
        rudderStackEvents.onArtworkDetail(artworkId)
        setShowDescription(!showDescription)
    }

    const renderDescription = (description) => {
        if (isHTML(description)) {
            return <p className="paragraph" dangerouslySetInnerHTML={{ __html: description }} />
        }
        else {
            return <Paragraph value={description} />
        }
    }


    return (
        <div className={`curate-artwork-wrapper active`}>
            <div className="curate-artwork-header">
                <div className='curate-artwork-header__left'>
                    {!!audioUrl && (
                        <AudioPlayer
                            isPlaying={isPlaying}
                            audioUrl={audioUrl}
                            currentTime={currentTime}
                            totalDuration={totalDuration}
                            setIsPlaying={setIsPlaying}
                            // setCurrentTime={setCurrentTime}
                        />
                    )}
                    <div>
                        <Paragraph className='curate-artwork-header__left__title curate-artwork-header__left__artist-name'>{artistName}</Paragraph>
                        <Paragraph className='curate-artwork-header__left__title curate-artwork-header__left__artwork-name'>{`${artworkName}, ${yearCreated}`}</Paragraph>
                    </div>
                        {/* <div className='curate-close-btn-wrapper' onClick={onClose}>
                            <img src={CrossIcon} alt="cross" />
                        </div> */}
                </div>
                <div className={`curate-artwork-header__right ${showDescription && viewportWidth <= 990 ? 'curate-artwork-header__right--responsive' : ''}`}>

                    {artworkPhotos?.length > 0 && <ul className='artworks-list'>
                        {
                            artworkPhotos.map((photo, index) => (
                                <li onClick={() => setArtworkModal(true, artworkPhotos, index)} key={index}><img src={photo.small} key={index} alt="artwork" /></li>
                            ))
                        }
                    </ul>}
                    {/* {!isMuseumType && 
                    <div className='artwork-expenses'>
                        {isArtworkListedForSale && price && Number(price) !== 0 && !priceUnavailable &&  <Button onClick={()=>{}} type="secondary" className="price-btn" spanClassName={(!isMobileDevice() && window.innerWidth> 990) && 'f-medium f-size-12px l-height-15-6px'}>
                            Price:&nbsp;<span>{formatePrice(price, currency)}</span> {!!excludedTax && <Paragraph className='dark-gray' value="(ex tax)" />}
                            </Button>
                        }
                        <Button className="enquire-btn" spanClassName={(!isMobileDevice() && window.innerWidth> 990) && 'f-size-12px l-height-15-6px'} onClick={handleEnquire} type="secondary" value={isEnquired ? 'Inquiry Sent' : 'Enquire'}></Button>
                    </div>} */}
                    <IconBtn onClick={onClose} type="brightClose" />
                </div>
            </div>

            <div className={`curate-artwork-middle`}>
                {!!description && description.trim() !== "" && <Button onClick={() => { description && description?.trim() !== '' && toggleArtworkDescription() }} className={`button button-dark description-btn description-btn--desktop ${description && description?.trim() ? '' : 'disabled'}`} iconType={!description || description?.trim() === '' ? 'info-circle-bold' : showDescription ? (isGuiding ? (isMobileDevice() ? "brightClose" : "closeDarkBold") : 'closeDarkBold') : "info-circle-bold"} type="secondary" value={showDescription ? (!description || description?.trim() === '' ? `${isArt ? 'Artwork' : 'Object'} Description` : "Close Description") : `${isArt ? 'Artwork' : 'Object'} Description`} />}
                <Button onClick={toggleArtworkDescription} className="button button-dark description-btn--responsive description-btn footer-3d-carousel-btn" iconType={showDescription ? "carret-off" : "carret-on"} type="secondary" value={showDescription ? "Close Description" : "Artwork Description"} />
                <Paragraph className={`dimen-para ${!!description && description.trim() !== "" ? "" : "dimentions--no-padding"}`}>
                    <>
                        {`${dimensionsUnit === 'in' ? dimensionsInIN : dimensionsInCM} / `}
                        {<button className={`gray unit-btn ${dimensionsUnit === "in" ? 'underline-cm' : 'underline-in'}`} onClick={handleDimensionsToggle}>{dimensionsUnit === 'in' ? 'cm' : 'in'}</button>}
                    </>
                </Paragraph>
                {!!(artworkMedium?.trim()) && <Paragraph className="medium-para">
                    <>{` ${artworkMedium}`}</>
                </Paragraph>}
                <div className={`artwork-actions ${isSidebar ? 'artwork-actions--sidebar-open' : ''}`}>
                    <div className='action-btn share'>
                        <img src={Share} alt="share" />
                        <Label value={`Share ${isArt ? 'Artwork' : 'Object'}`} />
                        <ShareBtn up shareUrl={shareUrl} chainLink={chainLink} />
                    </div>
                    {/* <button className='action-btn' onClick={onFavourite}>
                        <img src={isFavourite ? FavouriteOn : Favourite} alt="favourite" />
                        <Label value={`Bookmark ${isArt ? 'Artwork' : 'Object'}`} />
                    </button> */}
                </div>
            </div>
            {showDescription && description && description?.trim() &&
                <div className='curate-artwork-footer'>
                    <div className={`curate-artwork-footer-paragraph ${isGuiding ? ' is-guiding' : ''}`}>
                        {description?.trim() !== "" && renderDescription(description)}
                    </div>
                </div>
            }
        </div>
    )
}

export default CurateArtworkDetails;
